let navigateFunction = null;

// Set navigate function from a component (here setting from app.js)
export const setNavigateTo = (navigate) => {
  navigateFunction = navigate;
};

// Function to navigate from anywhere in the app (Default Export)
const navigateTo = (path) => {
  if (navigateFunction) {
    navigateFunction(path);
  } else {
    console.error("Navigation function is not available yet.");
  }
};

export default navigateTo;

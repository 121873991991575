import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { ReactComponent as Logo } from "../../vendor/logo.svg";
import { ResetPassword } from "../../api";
import {
  Alert,
  Button,
  TextField,
  Typography,
  Paper,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Toaster from "../../pages/users/components/Toaster";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const ConfirmPassword = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const ident = urlParams.get("ident");
  const activate = urlParams.get("activate");
  const navigate = useNavigate();
  const [toast, setToast] = useState({ open: false, message: "" });
  const [countdown, setCountdown] = useState(3);

  const handleCloseSnack = () => {
    setToast({ ...toast, open: false });
  };

  useEffect(() => {
    if (toast.open) {
      const interval = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);

      setTimeout(() => {
        navigate("/auth/sign-in");
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [toast.open, navigate]);

  return (
    <>
      {/* Toaster Notification */}
      <Toaster
        openSnack={toast.open}
        handleCloseSnack={handleCloseSnack}
        msg={toast.message}
      />

      <React.Fragment>
        <Brand />
        <Wrapper>
          <Helmet title="Confirm Password" />
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Reset Password
          </Typography>
          <Formik
            initialValues={{ password: "", confirmPassword: "" }}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              try {
                setSubmitting(true); // Start loader
                await ResetPassword({
                  password: values.password,
                  password_confirmation: values.confirmPassword,
                  ident,
                  activate,
                });

                // Show success message
                setToast({
                  open: true,
                  message: "Your password has been set successfully",
                });
              } catch (error) {
                setErrors({ submit: error.message });
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ handleSubmit, handleChange, values, errors, isSubmitting }) => (
              <form onSubmit={handleSubmit} style={{ marginTop: "30px" }}>
                {errors.submit && (
                  <Alert severity="error" style={{ marginBottom: "10px" }}>
                    {errors.submit}
                  </Alert>
                )}
                <TextField
                  type="password"
                  name="password"
                  label="Enter Password"
                  fullWidth
                  onChange={handleChange}
                  value={values.password}
                  my={3}
                />
                <TextField
                  type="password"
                  name="confirmPassword"
                  label="Confirm Password"
                  fullWidth
                  onChange={handleChange}
                  value={values.confirmPassword}
                  my={3}
                  sx={{ marginTop: "20px" }}
                />

                {/* Button with Loader */}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: "20px", height: "45px" }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Reset password"
                  )}
                </Button>
              </form>
            )}
          </Formik>

          {toast.open && (
            <Typography
              align="center"
              color="textSecondary"
              sx={{ marginTop: "20px", fontWeight: "bold" }}
            >
              Redirecting to Sign In Page in {countdown}...
            </Typography>
          )}
        </Wrapper>
      </React.Fragment>
    </>
  );
};

export default ConfirmPassword;

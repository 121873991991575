import React, { useState, useEffect } from "react";
import { TourMapLatLong } from "../../../api";
import {
  Grid,
  IconButton,
  Paper as MuiPaper,
  Typography,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  Dialog,
  Tooltip,
  Card,
} from "@mui/material";
import styled from "styled-components/macro";

import { Cancel as CancelIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import GoogleMapReact from "google-map-react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../../redux/slices/mainSlice";

const Paper = styled(MuiPaper)(spacing);

// Image Wrapper
const ImageWrapper = styled.div`
  margin-top: 10px;
  height: 100vh;
  width: 100%;
`;

const TourMap = (props) => {
  const {
    viewmap,
    setViewMap,
    viewMapClose,
    tourNameMap,
    userNameMap,
    activityTimeMap,
    tourIdMap,
    tourLIdMap,
    userIdMap,
    startTimeMap,
  } = props;

  const StyledTooltip = ({ title, children, ...props }) => (
    <Tooltip
      {...props}
      title={title}
      placement="top-end"
      /* arrow */
      componentsProps={{
        tooltip: {
          sx: {
            lineHeight: "1.6",
            fontSize: "10px",
            marginRight: "-15px",
            maxWidth: "330px",
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );

  const AnyReactComponent = ({ text }) => <div>{text}</div>;
  const [mapLocations, setMapLocations] = useState([]);
  const dispatch = useDispatch();

  const getTourMapLatLong = () => {
    let payload = {
      tour_name: tourNameMap,
      tour_id: tourIdMap,
      user_id: userIdMap,
      start_time: startTimeMap,
      stat_id: tourLIdMap,
    };
    TourMapLatLong(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setMapLocations(res.data);
        } else {
          dispatch(getMsg("Something went wrong!"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTourMapLatLong();
  }, []);

  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        open={viewmap}
        onClose={viewMapClose}
        aria-labelledby="viewmap-title"
        aria-describedby="viewmap-description"
      >
        <DialogTitle
          id="viewmap-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" component="div" gutterBottom>
            Tour Map
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setViewMap(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12}>
              <ImageWrapper
                style={{
                  marginTop: "10px",
                  width: "100%",
                  height: "60vh",
                  position: "relative",
                }}
              >
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyBIy5rGAxQzHCWPfBj9hv2iVSupuxjyH3E", //used v1 google maps api key. Please change after testing.
                    language: "en",
                    region: "US",
                  }}
                  defaultCenter={{ lat: 51.506, lng: -0.184 }}
                  defaultZoom={1}
                  options={(map) => ({ mapTypeId: map.MapTypeId.SATELLITE })}
                >
                  {mapLocations.map((data, index) => {
                    return (
                      <AnyReactComponent
                        lat={data.latitute}
                        lng={data.longtute}
                        text={
                          <StyledTooltip
                            placement="top-end"
                            title={
                              <Card
                                variant="outlined"
                                sx={{ padding: "20px 30px" }}
                              >
                                <Typography>
                                  <strong>Button Name: </strong>
                                  {data.button_name}
                                </Typography>
                                <Typography>
                                  <strong>Activity Time: </strong>
                                  {data.scan_time}
                                </Typography>
                                <Typography>
                                  <strong>Remarks: </strong> {data.comment}
                                </Typography>
                              </Card>
                            }
                          >
                            <LocationOnIcon
                              sx={{ color: "red" }}
                              fontSize="large"
                            />
                          </StyledTooltip>
                        }
                        key={index}
                      />
                    );
                  })}
                </GoogleMapReact>
              </ImageWrapper>

              {/* <ImageWrapper>
                <Image
                  alt="demo-image"
                  src={`/static/img/unsplash/map-pin-color-popup.png`}
                />
              </ImageWrapper> */}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography component="p" variant="span" gutterBottom>
                <strong>Tour Name:</strong> {tourNameMap}
              </Typography>
              <Typography component="p" variant="span" gutterBottom>
                <strong>Activity Time:</strong> {activityTimeMap}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography component="p" variant="span" gutterBottom>
                <strong>User Name:</strong> {userNameMap}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px" }}>
          <Button variant="outlined" color="primary" onClick={viewMapClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TourMap;

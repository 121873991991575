import {
  Grid,
  Box,
  Button,
  Tooltip,
  Typography,
  MenuItem,
  Menu,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Map from "./inner-components/Map";
import {
  GetDeviceName,
  GetFloorImage,
  LiveDispatchList,
  LiveForceLogout,
  SendGsmMessage,
  SendMicrophoneNotification,
} from "../../api";
import UserActivityList from "./inner-components/UserActivityList";
import MapHeader from "./inner-components/MapHeader";
import { Menu as MenuIcon } from "@mui/icons-material";
import { OverlayView, InfoWindow } from "@react-google-maps/api";
import styled from "styled-components/macro";
import { useAbly } from "ably/react";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../redux/slices/mainSlice";
import { useDispatch } from "react-redux";
import SendGsmMsgDialog from "./inner-components/SendGsmMsgDialog";
import DeviceNameDialog from "./inner-components/DeviceNameDialog";
import TransparentDialog from "./inner-components/TransparentDialog";

const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    maxWidth: "140px",
    width: "100%",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      "& .MuiMenuItem-root": {
        display: "block",
        "& .MuiList-root": {
          paddingTop: "0px",
          marginTop: "15px",
          paddingBottom: "0px",
          "& .MuiListItem-root": {
            padding: "0px",
            background: "#7aa8ff0d",
            "& .MuiListItemButton-root": {
              padding: "3px 5px",
            },
          },
        },
      },

      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));

const LiveModule = () => {
  const ably = useAbly();
  const dispatch = useDispatch();
  const locationId =
    JSON.parse(localStorage.getItem("location") || "{}")?.LocationID || null;
  const [locationIds, setLocationIds] = useState([locationId]);
  const [loading, setLoading] = useState(false);
  const [liveDispatchData, setLiveDispatchData] = useState({});
  const [activityPayload, setActivityPayload] = useState({
    page: 0,
  });
  const [hasMore, setHasMore] = useState(true);
  const [siteLocation, setSiteLocation] = useState({});
  const [mapCenter, setMapCenter] = useState({
    lat: 37.784279,
    lng: -122.407234,
  });
  const [zoomLevel, setZoomLevel] = useState(18);
  const [fullScreenMode, setFullScreenMode] = useState(false);
  const [transparentList, setTransparentList] = useState(false);

  const resetCenter = () => {
    setMapCenter({ ...siteLocation });
    setZoomLevel((prev) => (prev === 18 ? 16 : 18));
    setTimeout(() => setZoomLevel(18), 0);
  };

  const showAllUsers = () => {
    setMapCenter({ ...siteLocation });
    setZoomLevel((prev) => (prev === 16 ? 18 : 16));
    setTimeout(() => setZoomLevel(16), 0);
  };

  const handleRefreshMap = async () => {
    setLiveDispatchData({});
    setActivityPayload({
      page: 0,
    });
  };

  const transformActivityData = (activity) => {
    return {
      LocationName: activity.LocationName || "",
      scanTime: activity.scanTime || activity.activityTime || "",
      lattitude: activity.latitude || "0.0000", // Transform latitude
      longitude: activity.longitude || "0.0000", // Transform longitude
      buttonName: activity.buttonName || "",
      userId: activity.userId || "",
      activity_type: activity.activity_type || "",
      user_name: activity.user_name || "",
      user_id: activity.user_id || "",
      comments: activity.comments || "",
      task_location: activity.task_location || "",
      tbl_id: activity.tbl_id || "",
      IsLoggedIn: activity.IsLoggedIn || "N", // Default to "N"
      status: activity.status || "I", // Default to "I"
      floor_plans_id: activity.floor_plans_id || "0", // Default to "0"
      profile_image:
        activity.profile_image || "/public/static/img/avatars/user.webp", // Fallback profile image
    };
  };

  const onMessage = (msg) => {
    const data = JSON.parse(msg?.data);

    setLiveDispatchData((prevData) => {
      // Extract the incoming activity data
      const newActivity = data?.activity;

      if (!newActivity) {
        console.warn("No activity data found in message.");
        return prevData;
      }

      // Transform the incoming activity data
      const transformedActivity = transformActivityData(newActivity);

      // Get the existing map_data_array
      const existingMapDataArray = prevData.map_data_array || [];

      let updatedMapDataArray;

      // Check if the activity type is "Site Checkout"
      if (newActivity.activity_type === "Site-Checkout") {
        // Filter out the user from map_data_array
        updatedMapDataArray = existingMapDataArray.filter(
          (item) => item.user_id !== transformedActivity.user_id
        );
      } else {
        // Check if the user already exists in the array
        const existingIndex = existingMapDataArray.findIndex(
          (item) => item.user_id === transformedActivity.user_id
        );

        if (existingIndex !== -1) {
          // If user exists, update the object
          updatedMapDataArray = [...existingMapDataArray];
          updatedMapDataArray[existingIndex] = {
            ...updatedMapDataArray[existingIndex],
            ...transformedActivity, // Update with transformed data
          };
        } else {
          // If user does not exist, insert the new object
          updatedMapDataArray = [transformedActivity, ...existingMapDataArray];
        }
      }

      // Return the updated state
      return {
        ...prevData,
        activeUser: data.active_users || prevData.activeUser,
        inactiveUser: data.inactive_users || prevData.inactiveUser,
        map_data: [newActivity, ...(prevData.map_data || [])],
        map_data_array: updatedMapDataArray, // Updated map_data_array
      };
    });
  };

  useEffect(() => {
    // Store references to all the channels
    const channels = locationIds.map((id) => {
      const channelName = `activity_channel${id}`;
      return ably.channels.get(channelName); // Get the channel instance
    });

    // Subscribe to all channels
    channels.forEach((channel) => {
      channel.subscribe(onMessage);
    });

    // Cleanup on unmount
    return () => {
      channels.forEach((channel) => {
        // Unsubscribe from the channel
        channel.unsubscribe(onMessage);

        // Detach the channel before releasing it
        channel.detach((err) => {
          if (err) {
            console.error(`Failed to detach channel ${channel.name}:`, err);
          } else {
            ably.channels.release(channel.name); // Release the channel
          }
        });
      });
    };
  }, [ably, locationIds]);

  const getActivityList = async (payload) => {
    if (payload.page === 0) {
      setLoading(true);
    }
    try {
      const res = await LiveDispatchList(payload);

      if (res.statusCode === 404) {
        // No more data available
        setHasMore(false);
        return;
      }

      // Merge the new data into the existing `liveDispatchData`
      setLiveDispatchData((prevData) => ({
        ...prevData,
        activeUser: res.activeUser || prevData.activeUser,
        inactiveUser: res.inactiveUser || prevData.inactiveUser,
        map_data: [...(prevData.map_data || []), ...(res.map_data || [])],
        map_data_array: prevData.map_data_array?.length
          ? prevData.map_data_array
          : [...(res.map_data_array || [])],
        property_lat_lon_array:
          res.property_lat_lon_array || prevData.property_lat_lon_array,
      }));

      setHasMore((res.map_data || []).length > 0);

      if (res.property_lat_lon_array) {
        setMapCenter({
          lat: res.property_lat_lon_array.property_default_lat,
          lng: res.property_lat_lon_array.property_default_long,
        });
        setSiteLocation({
          lat: res.property_lat_lon_array.property_default_lat,
          lng: res.property_lat_lon_array.property_default_long,
        });
      }
    } catch (error) {
      console.error("Error fetching activity list:", error);

      // Handle pagination end if error occurs
      if (payload.page > 0) {
        setHasMore(false);
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchMoreActivities = () => {
    setActivityPayload((prev) => ({
      ...prev,
      page: prev.page + 1,
    }));
  };

  const CustomMarker = ({ location, onClick }) => {
    const [menuPosition, setMenuPosition] = useState(null); // Store mouse position
    const [isLoading, setIsLoading] = useState(false);
    const [smsDialog, setSmsDialog] = useState(false);
    const [deviceNameDialog, setDeviceNameDialog] = useState(false);
    const [deviceNames, setDeviceNames] = useState([]);

    const sendMessage = async (message) => {
      try {
        const payload = {
          userId: location?.userId,
          message: message,
        };
        const res = await SendGsmMessage(payload);
        dispatch(getMsg("Notification has been queued for sending sms!"));
        dispatch(getSnackAlert("success"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      } catch (error) {
        const errorMessage = error?.data?.message || "Something went wrong!";
        dispatch(getMsg(errorMessage));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
        console.log("Error while sending notification for sms: ", error);
      }
    };

    const handleSendMessage = () => {
      setSmsDialog(true);
    };

    const sendNotification = async (payload) => {
      setIsLoading(true); // Start loading
      try {
        const res = await SendMicrophoneNotification(payload);
        dispatch(getMsg("Notification has been queued for sending!"));
        dispatch(getSnackAlert("success"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      } catch (error) {
        const errorMessage = error?.data?.message || "Something went wrong!";
        dispatch(getMsg(errorMessage));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
        console.log("Error while sending notification: ", error);
      } finally {
        setIsLoading(false);
      }
    };

    const activateMicrophone = () => {
      const payload = {
        userId: location.userId,
        message:
          "Microphone turned on remotely. Your conversation is being monitored",
      };
      sendNotification(payload);
    };

    const activateCamera = () => {
      const payload = {
        userId: location.userId,
        message: "Camera activated remotely. Video being monitored",
      };
      sendNotification(payload);
    };

    const activateRinger = () => {
      const payload = {
        userId: location.userId,
        message: "Ringer activated to assist in locating the device",
      };
      sendNotification(payload);
    };

    const forceLogout = async () => {
      setIsLoading(true); // Start loading
      try {
        const payload = { userId: location?.userId };
        const res = await LiveForceLogout(payload);
        if (res.statusCode === 200) {
          getActivityList({ page: 0 });
        }
        dispatch(getMsg("User has been loged out successfully!"));
        dispatch(getSnackAlert("success"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      } catch (error) {
        const errorMessage = error?.data?.message || "Something went wrong!";
        dispatch(getMsg(errorMessage));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
        console.log("Error while sending notification: ", error);
      } finally {
        setIsLoading(false);
      }
    };

    const checkDeviceNames = async () => {
      setIsLoading(true); // Start loading
      try {
        const payload = { userId: location?.userId };
        const res = await GetDeviceName(payload);
        console.log("Device names: ", res);
        setDeviceNames(res?.data);
        setDeviceNameDialog(true);
      } catch (error) {
        const errorMessage = error?.data?.message || "Something went wrong!";
        dispatch(getMsg(errorMessage));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
        console.log("Error while getting device names: ", error);
      } finally {
        setIsLoading(false);
      }
    };

    const goIndoors = async () => {
      setIsLoading(true); // Start loading
      try {
        const payload = { floor_id: location?.floor_plans_id };
        const res = await GetFloorImage(payload);
        console.log("Floor Plan: ", res);
      } catch (error) {
        const errorMessage = error?.data?.message || "Something went wrong!";
        dispatch(getMsg(errorMessage));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
        console.log("Error while getting floor plans: ", error);
      } finally {
        setIsLoading(false);
      }
    };

    const handleOptionClick = (action) => {
      action(); // Call the respective function
    };

    const options = [
      { name: "Send Message", func: handleSendMessage },
      { name: "Activate user microphone to listen", func: activateMicrophone },
      { name: "Activate user camera to watch", func: activateCamera },
      { name: "Activate ringer in device", func: activateRinger },
      { name: "Force logout", func: forceLogout },
      { name: "Check Device Name(s)", func: checkDeviceNames },
      { name: "Go Indoors", func: goIndoors },
    ];

    const getPixelPositionOffset = (width, height) => ({
      x: -(width / 2),
      y: -(height / 2),
    });

    const setMarkerPosition = () => {
      if (
        location &&
        !isNaN(parseFloat(location?.lattitude)) && // Safely parse latitute
        !isNaN(parseFloat(location?.longitude)) // Safely parse longitude
      ) {
        return {
          lat: parseFloat(location?.lattitude), // Convert latitute string to number
          lng: parseFloat(location?.longitude), // Convert longitude string to number
        };
      } else {
        return siteLocation; // Fallback to siteLocation if invalid
      }
    };

    return (
      <>
        <OverlayView
          position={setMarkerPosition()}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <>
            <div
              style={{
                position: "relative",
                width: "60px",
                height: "60px",
                cursor: "pointer",
              }}
              onClick={onClick}
              onContextMenu={(e) => {
                e.preventDefault();
                setMenuPosition({ mouseX: e.clientX, mouseY: e.clientY });
              }}
              title={`${location.user_name}`}
            >
              {/* Circular Marker Background */}
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#ffffff",
                  borderRadius: "50%",
                  overflow: "hidden",
                }}
              >
                {location.profile_image ? (
                  <img
                    src={location.profile_image}
                    alt="Profile"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    onError={(event) =>
                      (event.target.src = "/static/img/avatars/user.webp")
                    }
                  />
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#cccccc",
                    }}
                  ></div>
                )}
              </div>

              {/* Status Dot */}
              <div
                style={{
                  position: "absolute",
                  bottom: "3px",
                  right: "3px",
                  width: "14px",
                  height: "14px",
                  backgroundColor:
                    location.status === "A" ? "#00FF00" : "#FF0000",
                  borderRadius: "50%",
                  border: "2px solid #ffffff",
                }}
              ></div>
            </div>

            <StyledMenu
              id="context-menu"
              anchorReference="anchorPosition"
              anchorPosition={
                menuPosition
                  ? { top: menuPosition.mouseY, left: menuPosition.mouseX }
                  : undefined
              }
              open={Boolean(menuPosition)}
              onClose={() => setMenuPosition(null)}
              sx={{
                "& .MuiPaper-root": {
                  minWidth: "260px",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                },
              }}
            >
              <MenuItem disabled>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    padding: "8px 12px",
                    whiteSpace: "normal",
                  }}
                >
                  {location.user_name || "Unknown User"}
                </Typography>
              </MenuItem>

              {/* Menu Options */}
              {options.map((option, index) => (
                <MenuItem
                  key={index}
                  onClick={() => handleOptionClick(option.func)} // Call respective function on click
                  sx={{
                    padding: "6px 12px",
                    fontSize: "15px",
                    fontWeight: "500",
                    border: "1px solid #e0e0e0",
                    backgroundColor: "#f9f9f9",
                    "&:hover": {
                      backgroundColor: "#e0f7fa",
                    },
                  }}
                >
                  <Typography variant="body2">{option.name}</Typography>
                </MenuItem>
              ))}
            </StyledMenu>
            {smsDialog && (
              <SendGsmMsgDialog
                open={smsDialog}
                onClose={() => {
                  setSmsDialog(false);
                }}
                sendMessage={sendMessage}
              />
            )}
            {deviceNameDialog && (
              <DeviceNameDialog
                open={deviceNameDialog}
                onClose={() => {
                  setDeviceNameDialog(false);
                }}
                deviceNames={deviceNames}
              />
            )}
          </>
        </OverlayView>
        {/* Overlay Loader */}
        {isLoading && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.4)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 9999,
            }}
          >
            <CircularProgress size={50} />
          </Box>
        )}
      </>
    );
  };

  const CustomInfoWindow = ({ location, onClose }) => {
    const setWidnowPostion = () => {
      if (
        location &&
        !isNaN(parseFloat(location?.lattitude)) && // Safely parse latitute
        !isNaN(parseFloat(location?.longitude)) // Safely parse longitude
      ) {
        console.log("in if");
        return {
          lat: parseFloat(location?.lattitude), // Convert latitute string to number
          lng: parseFloat(location?.longitude), // Convert longitude string to number
        };
      } else {
        console.log("in else");
        return siteLocation; // Fallback to siteLocation if invalid
      }
    };
    return (
      <InfoWindow
        position={setWidnowPostion()}
        onCloseClick={() => {
          onClose();
        }}
      >
        <div
          style={{
            minWidth: "320px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/* Info Content */}
          <div style={{ paddingRight: "12px" }}>
            <p>
              <strong>User:</strong> {location?.user_name}
            </p>
            <p>
              <strong>{location?.activity_type}:</strong> {location?.buttonName}
            </p>
            <p>
              <strong>Location:</strong> {location?.task_location}
            </p>
            <p>
              <strong>Remarks:</strong> {location?.comments}
            </p>
          </div>
        </div>
      </InfoWindow>
    );
  };

  useEffect(() => {
    getActivityList(activityPayload);
  }, [activityPayload]);

  return (
    <>
      <Box
        sx={{
          height: "80vh",
          width: "100%",
        }}
      >
        <Grid container spacing={5} sx={{ height: "100%" }}>
          {!fullScreenMode && (
            <Grid
              item
              xs={4}
              sx={{
                height: "100%",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <UserActivityList
                liveDispatchData={liveDispatchData}
                loading={loading}
                setActivityPayload={setActivityPayload}
                fetchMoreActivities={fetchMoreActivities}
                hasMore={hasMore}
                setMapCenter={setMapCenter}
                setLocationIds={setLocationIds}
              />
            </Grid>
          )}

          <Grid
            item
            xs={fullScreenMode ? 12 : 8}
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 1,
              position: "relative",
            }}
          >
            <Box
              sx={{
                height: "45px",
                flexShrink: 0,
              }}
            >
              <MapHeader
                resetCenter={resetCenter}
                showAllUsers={showAllUsers}
                refreshMap={handleRefreshMap}
              />
            </Box>

            <Box
              sx={{
                flex: "1",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <Map
                locations={liveDispatchData?.map_data_array}
                CustomMarker={CustomMarker}
                CustomInfoWindow={CustomInfoWindow}
                mapCenter={mapCenter}
                zoomLevel={zoomLevel}
              />
              {transparentList && (
                <Box
                  sx={{
                    position: "absolute",
                    left: "12px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    height: "80%",
                    width: "300px",
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    zIndex: 10,
                    overflowY: "auto",
                    boxShadow: "2px 0px 10px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <TransparentDialog
                    open={transparentList}
                    onClose={() => setTransparentList(false)}
                    loading={loading}
                    activitiesList={liveDispatchData?.map_data || []}
                  />
                </Box>
              )}
            </Box>

            {/* Menu Icon Button Positioned Vertically Centered on the Left */}
            <Box
              sx={{
                position: "absolute",
                left: "-21px",
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 1,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{
                  position: "relative",
                  height: "19px",
                  transform: "rotate(90deg)",
                  transformOrigin: "center",
                }}
                onClick={() => {
                  setFullScreenMode(!fullScreenMode);
                  setTransparentList(!fullScreenMode);
                }}
              >
                <MenuIcon color="white" />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default LiveModule;

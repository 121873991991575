import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { ImageSearch, CategoryList, StorageLocationList } from "../../api";
import CustomDateRange from "../../components/form-components/CustomDateRange";
import ItemQuickView from "./inner-component/ItemQuickView";

import {
  Grid,
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Box,
  Button,
  Tooltip,
  Card,
  CardHeader as MuiCardHeader,
  CardContent,
  TextField,
  Select,
  MenuItem,
  Paper,
  CircularProgress,
  FormLabel,
  IconButton,
  TablePagination,
} from "@mui/material";
import {
  Cancel as CancelIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import WithPermissionFallback from "../../utils/withPermissionFallback";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
//Classes Styling
const useStyles = makeStyles((theme) => ({
  borderedBox: {
    border: "1px solid",
    padding: "15px",
    borderColor:
      theme.palette.mode === "light" ? "#e0e0e0" : theme.palette.grey[700],
  },
  DescriptionText: {
    overflow: "hidden",
    lineClamp: 3,
    WebkitLineClamp: 3,
    textOverflow: "ellipsis",
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
  },
  hoveredOverlay: {
    position: "relative",
    height: "100%",
    "& .MuiBox-root:hover .MuiPaper-root": {
      display: "block",
      zIndex: 1,
    },
    "& .MuiBox-root": {
      height: "100%",
    },
    "& .MuiPaper-root": {
      height: "100%",
    },
    "& p": {
      fontSize: "12px",
    },
  },
  overlayCard: {
    display: "none",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    boxShadow: "0px 0px 10px 3px #0000001a",
    borderRadius: "3px",
    height: "100%;",
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const StyledTooltip = ({ title, children, ...props }) => (
  <Tooltip
    {...props}
    title={title}
    /* arrow */
    componentsProps={{
      tooltip: {
        sx: {
          maxWidth: "235px",
          padding: 0,
          backgroundColor: "#fff",
        },
      },
    }}
  >
    {children}
  </Tooltip>
);
const Image = styled.img`
  max-width: 100%;
  height: 100%;
  display: block;
  border-radius: 5px;
  position: relative;
  z-index: 0;
  object-fit: cover;
`;
const ImageWrapper = styled.div`
  height: 100%;
  &:before {
  }
`;
const CardHeader = styled(MuiCardHeader)`
  padding-bottom: 0;
`;

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function LostFoundContentArea(props) {
  const [imageList, setImageList] = useState([]);
  const [itemCatgList, setItemCatgList] = useState([]);
  const [storageLocationList, setStorageLocationList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [fadebox, setFadedBox] = useState(false);

  const classes = useStyles();
  let initialValues = {};

  const [startDateToFilter, setStartDateToFilter] = useState("");
  const [endDateToFilter, setEndDateToFilter] = useState("");
  const [description, setDescription] = useState("");
  // Category Select
  const [category, setCategory] = useState("");
  const selectCategory = (event) => {
    setCategory(event.target.value);
  };
  // Storage Location Select
  const [storageLocation, setStorageLocation] = useState("");
  const selectStorageLocation = (event) => {
    setStorageLocation(event.target.value);
  };
  // Disposition Status Select
  const [dispositionStatus, setDispositionStatus] = useState("");
  const selectDispositionStatus = (event) => {
    setDispositionStatus(event.target.value);
  };

  const [categoryShow, setCategoryShow] = useState(false);
  const [storageLocationShow, setStorageLocationShow] = useState(false);
  const [DSShow, setDSShow] = useState(false);
  const [descriptionShow, setDescriptionShow] = useState(false);
  const [foundDateShow, setFoundDateShow] = useState(false);
  const [callList, setCallList] = useState(false);

  // for listing
  const [rowsPerPage, setRowsPerPage] = useState(9);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecordinDb, setTotalRecordinDb] = useState(0);

  let dispositionArray = ["Lost", "Stored", "Claimed", "Disposed", "Pending"];

  useEffect(() => {
    getCategoryList();
    getStorageLocationList();
  }, []);

  useEffect(() => {
    getImageList();
  }, [rowsPerPage, currentPage, callList]);

  const getImageList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      category: category,
      storage_location: storageLocation,
      disposition_status: dispositionStatus,
      description: description,
      found_date_filter_type: "is_between",
      found_from_date: startDateToFilter
        ? moment(startDateToFilter).format("YYYY-MM-DD")
        : "",
      found_to_date: endDateToFilter
        ? moment(endDateToFilter).format("YYYY-MM-DD")
        : "",
    };
    ImageSearch(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setImageList(res.data);
          setTotalRecordinDb(res.meta.total);
        } else {
          setImageList([]);
          setTotalRecordinDb(0);
        }
        setLoading(false);
        handelToShowFields();
      })
      .catch((err) => {});
  };

  const getStorageLocationList = () => {
    StorageLocationList({})
      .then((res) => {
        if (res.statusCode == 200) {
          setStorageLocationList(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCategoryList = () => {
    CategoryList({})
      .then((res) => {
        if (res.statusCode == 200) {
          setItemCatgList(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handelToShowFields = () => {
    setCategoryShow(category ? true : false);
    setStorageLocationShow(storageLocation ? true : false);
    setDSShow(dispositionStatus ? true : false);
    setDescriptionShow(description ? true : false);
    setFoundDateShow(startDateToFilter ? true : false);
  };

  const clearFilter = async () => {
    setCategory("");
    setStorageLocation("");
    setDispositionStatus("");
    setDescription("");
    setStartDateToFilter("");
    setEndDateToFilter("");
    setCallList(!callList);
  };
  // Modal Item Quick View
  const [itemQuickView, setItemQuickView] = useState(false);
  const [itemDataToView, setItemDataToView] = useState(null);
  const itemQuickViewOpen = (data) => {
    setItemQuickView(true);
    setItemDataToView(data);
  };
  const itemQuickViewClose = () => {
    setItemQuickView(false);
    setItemDataToView(null);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  return (
    <div>
      <Box mt={3}>
        <Grid container spacing={6}>
          <Grid item xs={12} lg={8}>
            {loading ? (
              <Paper className={classes.fixedLoader}>
                <CircularProgress />
              </Paper>
            ) : (
              <Grid container spacing={6}>
                {imageList.length > 0 ? (
                  <React.Fragment>
                    {imageList.map((row, index) => {
                      return (
                        <Grid item xs={12} lg={4} xl={4} key={index}>
                          <Paper className={classes.hoveredOverlay}>
                            <Box>
                              <Card className={classes.overlayCard}>
                                <CardHeader
                                  title="Item Quick View"
                                  action={
                                    <Tooltip title="View Item">
                                      <IconButton
                                        color="primary"
                                        aria-label="settings"
                                        onClick={() => itemQuickViewOpen(row)}
                                      >
                                        <VisibilityIcon />
                                      </IconButton>
                                    </Tooltip>
                                  }
                                />
                                <CardContent>
                                  <Typography>
                                    <strong>Fround Date:</strong>{" "}
                                    {row.found_date}
                                  </Typography>
                                  <Typography>
                                    <strong>Fround By:</strong> {row.found_name}
                                  </Typography>
                                  <Typography>
                                    <strong>Storage:</strong>{" "}
                                    {row.storage_location}
                                  </Typography>
                                  <Typography
                                    className={classes.DescriptionText}
                                  >
                                    <strong>Description:</strong>{" "}
                                    {row.description}
                                  </Typography>
                                </CardContent>
                              </Card>
                              <ImageWrapper className={classes.ImageWrapper}>
                                <Image
                                  alt="SaaS Dashboard | SOVA"
                                  src={
                                    row.image
                                      ? row.image
                                      : `/static/img/lost-found/lost_and_found_pic.jpg`
                                  }
                                  onError={(event) =>
                                    (event.target.src =
                                      "/static/img/lost-found/lost_and_found_pic.jpg")
                                  }
                                />
                              </ImageWrapper>
                            </Box>
                          </Paper>
                        </Grid>
                      );
                    })}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Grid container spacing={6} align="center">
                      <Grid item xs={12} lg={12} mt={4}>
                        No records found
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} lg={4}>
            <Card>
              <CardHeader title="Current Filter" />
              <CardContent>
                <Box className={classes.borderedBox}>
                  <Button
                    color="primary"
                    align="right"
                    onClick={() => clearFilter()}
                  >
                    Clear all
                  </Button>
                  {categoryShow && (
                    <Box>
                      <FormLabel>
                        <strong>Category: </strong> {category}
                      </FormLabel>
                      <IconButton
                        onClick={() => {
                          setCategory("");
                          setCallList(!callList);
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </Box>
                  )}
                  {storageLocationShow && (
                    <Box>
                      <FormLabel>
                        <strong>Storage Location : </strong> {storageLocation}
                      </FormLabel>
                      <IconButton
                        onClick={() => {
                          setStorageLocation("");
                          setCallList(!callList);
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </Box>
                  )}
                  {DSShow && (
                    <Box>
                      <FormLabel>
                        <strong>Disposition Status : </strong>{" "}
                        {dispositionArray[dispositionStatus]}
                      </FormLabel>
                      <IconButton
                        onClick={() => {
                          setDispositionStatus("");
                          setCallList(!callList);
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </Box>
                  )}
                  {descriptionShow && (
                    <Box>
                      <FormLabel>
                        <strong>Description : </strong> {description}
                      </FormLabel>
                      <IconButton
                        onClick={() => {
                          setDescription("");
                          setCallList(!callList);
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </Box>
                  )}
                  {foundDateShow && (
                    <Box>
                      <FormLabel>
                        <strong>Found Date : </strong>{" "}
                        {startDateToFilter
                          ? moment(startDateToFilter).format("YYYY/MM/DD")
                          : ""}
                        {" - "}
                        {endDateToFilter
                          ? moment(endDateToFilter).format("YYYY/MM/DD")
                          : ""}
                      </FormLabel>
                      <IconButton
                        onClick={() => {
                          setStartDateToFilter("");
                          setEndDateToFilter("");
                          setCallList(!callList);
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </Box>
                  )}
                </Box>
                <Box mt={6}>
                  <Typography>Additional Filters</Typography>
                  <Formik initialValues={initialValues}>
                    <form>
                      {foundDateShow === false && (
                        <FormControl mt={3}>
                          <TextField
                            fullWidth
                            label="Found Date"
                            placeholder="Select date range"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onClick={() => setFadedBox((prev) => !prev)}
                            value={
                              startDateToFilter && endDateToFilter
                                ? moment(startDateToFilter).format(
                                    "YYYY-MM-DD"
                                  ) +
                                  " - " +
                                  moment(endDateToFilter).format("YYYY-MM-DD")
                                : ""
                            }
                          />
                          {fadebox && (
                            <CustomDateRange
                              handleShow={fadebox}
                              conformation={(status) =>
                                setFadedBox((prev) => !prev)
                              }
                              setDates={(start, end) => {
                                setStartDateToFilter(start);
                                setEndDateToFilter(end);
                              }}
                              selectedStart={startDateToFilter}
                              selectedEnd={endDateToFilter}
                            />
                          )}
                        </FormControl>
                      )}
                      {categoryShow === false && (
                        <FormControl mt={6}>
                          <InputLabel
                            shrink
                            id="category"
                            sx={{
                              background: "#fff",
                              paddingLeft: "6px",
                              paddingRight: "6px",
                              marginLeft: "-4px",
                            }}
                          >
                            Category
                          </InputLabel>
                          <Select
                            labelId="key-ring"
                            id="category"
                            value={category}
                            label="Category"
                            onChange={selectCategory}
                            fullWidth
                          >
                            {itemCatgList.map((row, index) => {
                              return (
                                <MenuItem key={index} value={row.value}>
                                  {row.key}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      )}
                      {descriptionShow === false && (
                        <FormControl mt={6}>
                          <TextField
                            id="description"
                            value={description}
                            label="Description"
                            autoComplete="false"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder=""
                            onChange={(e) => {
                              setDescription(e.target.value);
                            }}
                          />
                        </FormControl>
                      )}
                      {storageLocationShow === false && (
                        <FormControl mt={6}>
                          <InputLabel
                            shrink
                            id="storage-location"
                            sx={{
                              background: "#fff",
                              paddingLeft: "6px",
                              paddingRight: "6px",
                              marginLeft: "-4px",
                            }}
                          >
                            Storage location
                          </InputLabel>
                          <Select
                            labelId="key-ring"
                            id="storage-location"
                            value={storageLocation}
                            label="Storage location"
                            onChange={selectStorageLocation}
                            fullWidth
                          >
                            {storageLocationList.map((row, index) => {
                              return (
                                <MenuItem key={index} value={row.value}>
                                  {row.key}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      )}
                      {DSShow === false && (
                        <FormControl mt={6}>
                          <InputLabel
                            shrink
                            id="disposition-status"
                            sx={{
                              background: "#fff",
                              paddingLeft: "6px",
                              paddingRight: "6px",
                              marginLeft: "-4px",
                            }}
                          >
                            Disposition status
                          </InputLabel>
                          <Select
                            labelId="key-ring"
                            id="disposition-status"
                            value={dispositionStatus}
                            label="Disposition status"
                            onChange={selectDispositionStatus}
                            fullWidth
                          >
                            <MenuItem value={1}>Stored</MenuItem>
                            <MenuItem value={2}>Claimed</MenuItem>
                            <MenuItem value={3}>Disposed</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                      <FormControl mt={4}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => getImageList()}
                        >
                          Add filter
                        </Button>
                      </FormControl>
                    </form>
                  </Formik>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Paper>
              <TablePagination
                rowsPerPageOptions={[9, 18, 27, 36]}
                component="div"
                count={totalRecordinDb}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
      {itemQuickView && itemDataToView && (
        <ItemQuickView
          itemQuickView={itemQuickView}
          itemDataToView={itemDataToView}
          itemQuickViewClose={itemQuickViewClose}
        />
      )}
    </div>
  );
}

function LostFoundImageSearch() {
  return (
    <React.Fragment>
      <Helmet title="Lost and Found Image Search" />
      <WithPermissionFallback controller="LostAndFound" action="imageSearch">
        <Typography variant="h3" gutterBottom display="inline">
          Lost and Found Image Search
        </Typography>

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <LostFoundContentArea />
          </Grid>
        </Grid>
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default LostFoundImageSearch;

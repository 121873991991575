import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import { makeStyles } from "@mui/styles";
import { ViewTour, UpdateTour, GetTourBuilding } from "../../api";
import {
  decrement,
  increment,
  incrementByAmount,
  incrementAsync,
  incrementIfOdd,
  selectCount,
  hideLoader,
  showLoader,
} from "../../redux/slices/counter";
import {
  Avatar,
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  CircularProgress,
  Typography,
  Tooltip,
  FormLabel,
  RadioGroup,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Radio,
  Box,
  Fade,
  TextareaAutosize as MuiTextareaAutosize,
  FormHelperText,
  Slider,
  Input as MuiInput,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Cropper from "react-cropper";

import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Error as ErrorIcon,
  Edit as EditIcon,
  CancelRounded as CancelRoundedIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import {
  getChecked,
  getSnackOpen,
  getSnackClose,
  getMsg,
} from "../../redux/slices/tourSlice";
import WithPermissionFallback from "../../utils/withPermissionFallback";
const FormControlSpacing = styled(MuiFormControl)(spacing);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
const TextareaAutosize = styled(MuiTextareaAutosize)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 120px !important;
  background: transparent;
  padding: 20px 12px 15px;
  font-size: 13px;
  opacity: 0.8;
  font-weight: 200;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: normal;
`;
// Classes Styling
const useStyles = makeStyles((theme) => ({
  textAreaControl: {
    position: "relative",
    "& .MuiFormLabel-root": {
      position: "absolute",
      top: "-24px",
      padding: "0 5px",
      zIndex: "1",
      left: "-5px",
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
    "& textarea": {
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[100],
    },
    "& #tour-desc:focus-visible": {
      outlineColor: "#2f63be",
      outline: "2px solid #2f63be",
      border: 0,
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const TextAreaControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    "& textarea:focus-visible": {
      outlineColor: "#2f63be",
      border: "0px",
    },
  })
);
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
const Input = styled(MuiInput)`
  width: 42px;
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 20px 0 15px ${(props) => props.theme.spacing(2)};
`;
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const StyledTooltip = ({ title, children, ...props }) => (
  <Tooltip
    {...props}
    title={title}
    placement="top-end"
    /* arrow */
    componentsProps={{
      tooltip: {
        sx: {
          lineHeight: "1.6",
          fontSize: "10px",
          marginRight: "-15px",
          maxWidth: "330px",
        },
      },
    }}
  >
    {children}
  </Tooltip>
);
const StyledInputSlider = styled((props) => <Paper {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      "& .MuiInput-root::before": {
        borderBottom: "0px",
      },
      "& .MuiInput-root": {
        "& .MuiInput-input": {
          paddingBottom: "0px",
        },
      },
    },
  })
);
const StyledSelectTooltip = ({ title, children, ...props }) => (
  <Tooltip
    {...props}
    title={title}
    placement="top-end"
    /* arrow */
    componentsProps={{
      tooltip: {
        sx: {
          lineHeight: "1.6",
          fontSize: "10px",
          marginRight: "-12px",
          maxWidth: "330px",
        },
      },
    }}
  >
    {children}
  </Tooltip>
);
const StyledFormControl = styled((props) => <FormGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      display: "block",
      maxHeight: "250px",
      marginTop: "5px",
      overflowY: "auto",
      "& .MuiFormControlLabel-root": {
        display: "block",
      },
    },
  })
);

const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
function ColumnLeft({ dialogHead }) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  let navigate = useNavigate();
  const [values, setValues] = React.useState({
    showPassword: false,
  });

  // State for image cropper modal
  const [imageCropperDialog, setImageCropperDialog] = useState(false);
  const imageCropperOpen = () => {
    setImageCropperDialog(true);
  };
  const imageCropperClose = () => {
    setImageCropperDialog(false);
  };
  const [imageData, setImageData] = useState(null);
  const childToParent = (childData) => {
    setImageData(childData);
  };

  const imageCropperClear = () => {
    setImageData(null);
    setImage(null);
  };

  //Activity Slider
  const [activityvalue, setValue] = React.useState(0);

  const handleActivityPoints = (event, newValue) => {
    setValue(newValue);
    console.log(newValue);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value === "" ? "" : Number(event.target.value));
  };

  const { id } = useParams(); //tour id from url
  //Single view Methode
  const [tourView, setTourView] = useState({});
  const singleTour = () => {
    ViewTour(id)
      .then((res) => {
        if (res.statusCode === 200) {
          setTourView(res.data);
          setLoading(false);
        } else {
          setTourView({});
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  //tour building api method
  const [building, setBuilding] = useState([]);
  const tourBuilding = () => {
    GetTourBuilding()
      .then((res) => {
        if (res.statusCode === 200) {
          setBuilding(res.data);
        } else {
          setBuilding([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //useEffect
  useEffect(() => {
    singleTour();
    tourBuilding();
    setLoading(true);
  }, []);
  const {
    tour_name,
    tour_description,
    activity_point,
    min_count,
    tag,
    building_id,
  } = tourView;

  const initialValues = {
    tour_name: tour_name ? tour_name : "",
    tour_description: tour_description ? tour_description : "",
    activity_point: activity_point ? activity_point : "",
    daily_required_count: min_count ? min_count : 0,
    tour_building: building_id ? building_id : "",
    tag: tag?.client_name ? tag?.client_name : "",
  };
  const validationSchema = Yup.object().shape({
    tour_name: Yup.string().required("Please enter tour name"),
    tour_description: Yup.string().required("Please enter tour description"),
    tag: Yup.string().required("Tag is required"),
    daily_required_count: Yup.number()
      .min(0, "Value must be non-negative")
      .required("Please enter a value for daily required count"),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    setIsDisabled(true);
    try {
      setLoading(true);
      let payload = {
        tour_name: values.tour_name,
        description: values.tour_description,
        activity_point: activityvalue === 0 ? activity_point : activityvalue,
        min_count: values.daily_required_count,
        building_id: values.tour_building,
        tag: values.tag,
        image: imageData ? imageData : null,
      };
      UpdateTour(id, payload)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getSnackOpen());
            dispatch(getMsg("Tour has been updated successfully"));
            setLoading(false);
            navigate({
              pathname: "/tours/tour-listing",
            });
            setIsDisabled(false);
          } else {
            dispatch(getSnackClose());
            setIsDisabled(false);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsDisabled(false);
          dispatch(getSnackClose());
          setLoading(false);
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  const classes = useStyles();

  const [image, setImage] = useState(null);
  //   const [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState();
  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      childToParent(cropper.getCroppedCanvas().toDataURL());
      //   setCropData(cropper.getCroppedCanvas().toDataURL());
    }
  };

  useEffect(() => {
    if (imageData) {
      setImage(imageData);
    } else {
      setImage(null);
    }
  }, []);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue,
          values,
          status,
        }) => (
          <Card mb={6}>
            <form onSubmit={handleSubmit}>
              <CardContent>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={6}>
                      <TextField
                        id="tour-name"
                        label="Tour name*"
                        name="tour_name"
                        value={values.tour_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.tour_name && errors.tour_name)}
                        helperText={touched.tour_name && errors.tour_name}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Public spaces"
                        // helperText="Choose a concise name to represent the tour. Please refrain from using excessive capitalization or special characters like (/-@) as it make reports difficult to read"
                      />
                      {/* <Typography
                        component="p"
                        sx={{ fontSize: "10px", fontWeight: "300" }}
                        mt={2}
                        mx={3}
                      >
                        Choose a concise name to represent the tour. Please
                        refrain from using excessive capitalization or special
                        characters like (/-@) as it make reports difficult to
                        read
                      </Typography> */}
                    </FormControl>
                    <FormControl mt={6}>
                      <TextField
                        id="outlined-number"
                        label="Daily required count"
                        name="daily_required_count"
                        value={values.daily_required_count}
                        error={Boolean(
                          touched.daily_required_count &&
                            errors.daily_required_count
                        )}
                        helperText={
                          touched.daily_required_count &&
                          errors.daily_required_count
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // inputProps={{
                        //   min: 0,
                        // }}
                      />
                      <Typography
                        component="p"
                        sx={{ fontSize: "10px", fontWeight: "300" }}
                        mt={2}
                        mx={3}
                      >
                        Set a minimum count of this tour that are required of
                        your team to submit daily. If these minimums are met,
                        the tour will no longer be available for selection in a
                        mobile device until the following day. If the minimums
                        are not met, you can configure a list of recipients in
                        the Communication list that will be notified daily via
                        email.
                      </Typography>
                    </FormControl>

                    {/* <FormControl mt={6}>
                      <TextField
                        id="tour-desc"
                        label="Description of tour*"
                        name="tour_description"
                        value={values.tour_description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.tour_description && errors.tour_description
                        )}
                        helperText={
                          touched.tour_description && errors.tour_description
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Include both exterior and interior checkpoints at public spaces around the property. Employee areas are not inclded. Tour should be completed a minimum of 2 times per shift"
                      />
                    </FormControl> */}
                    <TextAreaControl mt={3} className={classes.textAreaControl}>
                      <FormControl mt={1}>
                        <InputLabel id="v-status">
                          Description of tour*
                        </InputLabel>
                        <TextareaAutosize
                          id="tour-desc"
                          label="Description of tour*"
                          name="tour_description"
                          value={values.tour_description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(
                            touched.tour_description && errors.tour_description
                          )}
                          helperText={
                            touched.tour_description && errors.tour_description
                          }
                          placeholder="Include both exterior and interior checkpoints at public spaces around the property. Employee areas are not inclded. Tour should be completed a minimum of 2 times per shift"
                          style={{
                            fontWeight: "400",
                            color: "#000000",
                          }}
                        />
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.6964285714285714rem",
                            marginLeft: "14px",
                            marginTop: "5px",
                          }}
                        >
                          {touched.tour_description && errors.tour_description}
                        </Typography>
                      </FormControl>
                      <Typography
                        sx={{
                          fontSize: "10px",
                          fontWeight: "300",
                          marginLeft: "14px",
                        }}
                        mt={2}
                        mx={3}
                      >
                        Enter a detailed description of this tour to assist your
                        officers in understanding the tour and your exceptions
                      </Typography>
                    </TextAreaControl>
                    <FormControl mt={6}>
                      <InputLabel id="demo-simple-select-label">
                        Tour buildings
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="tour_building"
                        value={values.tour_building}
                        label="Tour buildings"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        {building.map((data) => {
                          return (
                            <MenuItem value={data.id} key={data.id}>
                              {data.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>

                    <FormControl mt={6}>
                      <FormLabel>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "3px",
                          }}
                        >
                          Activity points
                          {
                            <StyledInputSlider>
                              <Paper>
                                <Input
                                  name={activity_point}
                                  value={
                                    activityvalue === 0
                                      ? values.activity_point
                                      : activityvalue
                                  }
                                  sx={{ marginLeft: "5px" }}
                                  size="small"
                                  // name="activity_point"
                                  onChange={handleInputChange}
                                  onBlur={handleBlur}
                                />
                              </Paper>
                            </StyledInputSlider>
                          }
                        </Box>
                      </FormLabel>
                      <Slider
                        size="large"
                        name="activity_point"
                        value={
                          activityvalue === 0
                            ? values.activity_point
                            : activityvalue
                        }
                        onChange={handleActivityPoints}
                        aria-labelledby="input-slider"
                        sx={{ marginLeft: "6px", marginTop: "10px" }}
                        max={20}
                        min={1}
                      />
                      <Typography
                        component="p"
                        sx={{ fontSize: "10px", fontWeight: "300" }}
                        mt={2}
                        mx={3}
                      >
                        Instructions: Set an activity point value for this tour
                        using the slider below. Tours are given 3 activity
                        points by default. A good rule of thumb is to assign 1
                        activity point for each 5 minutes of activity. So if a
                        tour takes on average 1 hour to complete, you would
                        assign 12 activity points.
                      </Typography>
                    </FormControl>
                    <FormControl mt={6}>
                      <TextField
                        id="tag"
                        label="Tag"
                        name="tag"
                        value={values.tag}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.tag && errors.tag)}
                        helperText={touched.tag && errors.tag}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={5}>
                      <FormLabel>Add tour image</FormLabel>
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="raised-button-file"
                        // onChange={handleImage}
                        multiple
                        type="file"
                      />
                      <FormHelperText>
                        Pro tip: Wait until you get checkpoints added then add a
                        screenshot from the "Build visual tour" feature from the
                        tour list to show a satellite map with marker at our
                        checkpoints.
                      </FormHelperText>
                      {/* <BigAvatar
                        alt="Remy Sharp"
                        src={imageData ? imageData : image}
                      /> */}
                      <img
                        src={imageData ? imageData : image}
                        style={{ maxHeight: "250px", maxWidth: "300px" }}
                      />
                      <Grid item xs={12} md={12}>
                        <Button
                          onClick={imageCropperOpen}
                          variant="contained"
                          color="primary"
                          mt={3}
                        >
                          Choose Image
                        </Button>
                        {imageData && (
                          <Button
                            sx={{ marginLeft: "10px" }}
                            onClick={imageCropperClear}
                            variant="contained"
                            color="primary"
                            component="span"
                            mt={3}
                          >
                            Clear
                          </Button>
                        )}
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      mt={6}
                      sx={{ width: "175px", marginLeft: "5px" }}
                      disabled={isDisabled}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </form>
          </Card>
        )}
      </Formik>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
      {/* {imageCropperDialog && (
        <ImageCropper
          imageCropperDialog={imageCropperDialog}
          setImageCropperDialog={setImageCropperDialog}
          imageCropperOpen={imageCropperOpen}
          imageCropperClose={imageCropperClose}
          childToParent={childToParent}
          imageData={imageData}
          image={image}
          
        />
      )} */}

      <Dialog
        aria-labelledby="property-logo-title"
        aria-describedby="property-logo-description"
        open={imageCropperDialog}
        onClose={imageCropperClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-location-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            {dialogHead}
          </Typography>
          <DialogActions>
            <IconButton
              onClick={imageCropperClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Card
            variant="outlined"
            sx={{ height: "auto" }}
            style={{ textAlign: "center" }}
          >
            {image ? (
              <Cropper
                style={{ height: 300, width: "100%" }}
                zoomTo={0.5}
                initialAspectRatio={1}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={true}
              />
            ) : (
              <img
                src="/static/img/map-placeholder.png"
                width="50%"
                height="200px"
              />
            )}
          </Card>
          <FormControl mt={6}>
            <input type="file" onChange={onChange} />
            {/* <TextField label="" type="file" /> */}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              getCropData();
              imageCropperClose();
            }}
          >
            Crop
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function EditTour() {
  return (
    <React.Fragment>
      <WithPermissionFallback controller="Tour" action="updateTour">
        <Helmet title="Edit Tour" />
        <Typography variant="h3" gutterBottom display="inline">
          Edit Tour
        </Typography>

        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/tours/tour-listing">
            Tour List
          </Link>
          <Typography>Add New Tour</Typography>
        </Breadcrumbs>

        <Divider my={6} />
        <Grid container spacing={6}>
          <Grid item xs={12} md={12}>
            <ColumnLeft />
          </Grid>
        </Grid>
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default EditTour;

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import EnhancedTableToolbar from "./inner-component/EnhancedTableToolbar";
import EnhancedTableHead from "./inner-component/EnhancedTableHead";
import CustomPagination from "../../../components/table-components/CustomPagination";
import Types from "./inner-component/Types";
import ListingAction from "./inner-component/ListingAction";
import ListToggle from "./inner-component/ListToggle";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import DialogBox from "../../../components/form-components/DialogBox";
import hasPermission from "../../../utils/hasPermission";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../../redux/slices/mainSlice";
import {
  GetVisitorList,
  VisitorStatusUpdate,
  CompanyList,
  VisitorsList,
  DeleteVisitor,
  InlineUpdateVisitor,
  CheckInVisitor,
  UpdateGroupVisitoreList,
  PrintVehiclePass as PrintVehicle,
  PrintVisitorBadge as PrintVisitor,
} from "../../../api";

import PrintVehiclePass from "../PrintVehiclePass";
import {
  Checkbox,
  Grid,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  ListItemText as MuiListItemText,
  TableCell,
  TableRow,
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Box,
  Button,
  Menu,
  FormControlLabel,
  Switch,
  CircularProgress,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
} from "@mui/material";
import { Cached as CachedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import PrintVisitorBadge from "../PrintVisitorBadge";
import AlertBox from "../AlertBox";
import WithPermissionFallback from "../../../utils/withPermissionFallback";

// Classes Styling
const useStyles = makeStyles((theme) => ({
  alphabets: {
    marginTop: "15px",
    "& .MuiList-root": {
      display: "flex",
      overflowX: "auto",
      background: "#7aa8ff0d",
      padding: "0px",
      "& .MuiListItemButton-root": {
        paddingLeft: "13px",
        paddingRight: "13px",
        textAlign: "center",
      },
    },
    "& .MuiList-root::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "& .MuiList-root::-webkit-scrollbar-track": {
      backgroundColor: "#bed2f561",
    },
    "& .MuiList-root::-webkit-scrollbar-thumb": {
      backgroundColor: "#92a6c96e",
    },
  },
  filterDataByTabs: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "15px",
    "& .MuiToggleButtonGroup-root": {
      justifyContent: "space-between",
      width: "100%",
      overflowX: "auto",
      "& .MuiButtonBase-root": {
        borderRadius: "0",
        width: "100%",
        whiteSpace: "nowrap",
      },
    },
    "& .MuiList-root::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "& .MuiList-root::-webkit-scrollbar-track": {
      backgroundColor: "#bed2f561",
    },
    "& .MuiList-root::-webkit-scrollbar-thumb": {
      backgroundColor: "#92a6c96e",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });

  return stabilizedThis.map((element) => element.el);
}

const EditableTableCell = ({ row, tableCalRedux }) => {
  const [content, setContent] = useState(row.name);
  const [isEditing, setIsEditing] = useState(false);
  const cellRef = useRef(null);

  const handleBlurEdit = () => {
    setIsEditing(false);
    const payload = {
      id: row.id,
      content: content,
    };
    InlineUpdateVisitor(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          console.log("save success");
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleInput = (e) => {
    setContent(e.currentTarget.textContent);
  };

  const handleClick = () => {
    setIsEditing(true);
  };

  useEffect(() => {
    if (isEditing) {
      const cell = cellRef.current;
      const range = document.createRange();
      const sel = window.getSelection();

      if (cell && cell.childNodes.length > 0 && sel) {
        const textNode = cell.childNodes[0];
        range.setStart(textNode, content.length);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
        cell.focus();
      }
    }
  }, [isEditing, content]);

  return (
    <Tooltip title="Click to edit" followCursor>
      <TableCell
        ref={cellRef}
        align="left"
        sx={{
          whiteSpace: "nowrap",
          pointerEvents: !hasPermission("Visitor", "inlineUpdateVisitor")
            ? "none"
            : "auto", // Disable pointer events when the cell is disabled
          opacity: !hasPermission("Visitor", "inlineUpdateVisitor") ? 0.5 : 1, // Optional: Make it look visually disabled
        }}
        className={tableCalRedux.name ? "" : "hide-cell"}
        contentEditable={
          isEditing && hasPermission("Visitor", "inlineUpdateVisitor")
        }
        suppressContentEditableWarning
        onInput={handleInput}
        onBlur={handleBlurEdit}
        onClick={
          !hasPermission("Visitor", "inlineUpdateVisitor")
            ? undefined
            : handleClick
        }
      >
        {content}
      </TableCell>
    </Tooltip>
  );
};

function EnhancedTable(props) {
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [selected, setSelected] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [exportCSVLists, setExportCSVLists] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const classes = useStyles();

  //Tabs
  const [alphabetesbutton, setAlphabetsButton] = useState("");
  const changeAlphabetsTab = (event, newTab) => {
    if (newTab == "refresh") {
      newTab = "";
    }
    setAlphabetsButton(newTab);
    filterByAlphabets(alphabetsFilter, newTab);
  };
  const filterByAlphabets = (obj, value) => {
    let filterObj = {};
    filterObj.name = value;
    filterObj.searchByAlphabet = true;
    setDataToFilter(filterObj);
  };

  // Redux
  const showAlert = useSelector((state) => state.main.showAlert);
  const showAlertText = useSelector((state) => state.main.text);
  const tableCalRedux = useSelector(
    (state) => state.visitorListCal.visitorListing
  );
  const defalutTableCalRedux = useSelector(
    (state) => state.defaultVisitorListCal.visitorListing
  );

  const [tabsbutton, setTabButton] = useState("showall");
  const [lists, setLists] = useState([]);
  const [companyOptions, SetCompanyOptions] = useState([]);
  const [visitorTypeList, setVisitorTypeList] = useState([]);
  const [totalRecordinDb, setTotalRecordinDb] = useState(0);
  const [dataToFilter, setDataToFilter] = useState({});
  const [checkedInOrOut, setCheckedInOrOut] = useState(false);
  const [alphabetsFilter, setAlphabetsFilter] = useState({
    checkbox_desc: true,
  });

  // Badge print Dialog
  const [visitorVehicleBadges, setVisitorVehicleBadges] = useState([]);

  const [printVehicalPassDialog, setPrintVehicalPassDialog] = useState(false);
  const printVehicalPassOpen = () => {
    setPrintVehicalPassDialog(true);
  };
  const printVehicalPassClose = () => {
    setPrintVehicalPassDialog(false);
  };

  const [printVisitorData, setPrintVisitorData] = useState([]);
  const [printVisitorDialog, setPrintVisitorDialog] = useState(false);
  const printVisitorDialogOpen = () => {
    setPrintVisitorDialog(true);
  };
  const printVisitorDialogClose = () => {
    setPrintVisitorDialog(false);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  const sortedData = lists.map((item) => ({
    id: item.id,
    name: item.name,
    type: item.visitor_types,
    host: item.host_name,
    company: item.CompanyName,
    active: item.active,
    recent: item.checked_in_time,
    issued: item.visitor_auth_start_date,
    expiry: item.visitor_auth_end_date,
    status: item.status,
    visitor_mobile: item.visitor_mobile_no,
    // ... map other properties as needed
  }));

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [searchParams] = useSearchParams();

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = lists.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
  };
  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    VisitorList();
  }, [rowsPerPage, currentPage, dataToFilter, tabsbutton]);

  useEffect(() => {
    GetCompanyList();
    GetVisitorTypeList();
  }, []);

  const VisitorList = () => {
    setLoading(true);
    const todayListParam = searchParams.get("todayList");
    let payload = {
      limit: rowsPerPage,
      id: searchParams.get("id") ?? null,
      todayListParam: todayListParam,
      status: searchParams.get("status") ? searchParams.get("status") : "",
      sorting: {
        order: order,
        by: orderBy,
      },
      ...dataToFilter,
    };
    GetVisitorList(currentPage, payload)
      .then((res) => {
        if (res.statusCode == 200) {
          makeDataToExport(res.data);
          setLists(res.data);
          setTotalRecordinDb(res.meta.total);
        } else {
          setLists([]);
          setTotalRecordinDb(0);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const GetCompanyList = () => {
    CompanyList()
      .then((res) => {
        if (res.statusCode == 200) {
          SetCompanyOptions(res.data);
        } else {
          SetCompanyOptions([]);
        }
      })
      .catch((err) => {});
  };
  const GetVisitorTypeList = () => {
    VisitorsList()
      .then((res) => {
        if (res.statusCode == 200) {
          setVisitorTypeList(res.data);
        } else {
          setVisitorTypeList([]);
        }
      })
      .catch((err) => {});
  };

  const filterRecord = (data) => {
    setDataToFilter(data);
  };

  const showAlertModal = (type, message) => {
    dispatch(getMsg(message));
    dispatch(getSnackAlert(type));
    dispatch(getSnackOpen());
    setTimeout(() => {
      dispatch(getSnackClose());
    }, 3000);
  };

  const makeDataToExport = (data) => {
    const expData = data.map((t) => {
      return {
        id: t.id,
        name: t.name,
        visitorTypeName: t.visitor_types_name,
        hostName: t.host_name,
        companyName: t.companyName,
        phone: t.phone,
        arrivalDate: t.visitor_auth_start_date,
        expiryDate: t.visitor_auth_end_date,
        active: t.active === "A" ? "Yes" : "No",
        disposition: t.status,
      };
    });
    setExportCSVLists(expData);
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [type, setTpype] = useState("");
  const removeRecords = (type) => {
    setTpype(type);
    if (selected.length > 0 && type == "Delete") {
      setModalMessage("Are you sure you want to delete?");
    } else if (selected.length > 0 && type == "Active") {
      setModalMessage("Are you sure you want to active?");
    } else if (selected.length > 0 && type == "Inactive") {
      setModalMessage("Are you sure you want to Inactive?");
    } else if (selected.length > 0 && type == "CHECKED-IN") {
      setModalMessage("Are you sure you want to checked-in?");
    } else if (selected.length > 0 && type == "CHECKED-OUT") {
      setModalMessage("Are you sure you want to checked-out?");
    } else if (selected.length > 0 && type == "print-v-pass") {
      setModalMessage("Are you sure you want to print vehicle pass?");
    } else if (selected.length > 0 && type == "print-visitor-badge") {
      setModalMessage("Are you sure you want to print visitor badge?");
    } else {
      setModalMessage("You haven't selected any item!");
    }
    setShowDeleteModal(true);
  };

  const handelDeleteAction = (status) => {
    if (status === true && type == "Delete") {
      setLoader(true);
      DeleteVisitor(selected)
        .then((res) => {
          setShowDeleteModal(false);
          if (res.statusCode === 200) {
            VisitorList();
            setSelected([]);
            showAlertModal(
              "success",
              "Record(s) has been deleted successfully"
            );
            setLoader(false);
          }
        })
        .catch((err) => {
          setLoader(false);
        });
    } else if (status === true && (type == "Active" || type == "Inactive")) {
      setLoader(true);
      let payload = {
        active: type == "Active" ? "A" : "I",
      };
      VisitorStatusUpdate(selected, payload)
        .then((res) => {
          setShowDeleteModal(false);
          if (res.statusCode === 200) {
            VisitorList();
            setSelected([]);
            showAlertModal("success", "Record(s) update successfully");
            setLoader(false);
          }
        })
        .catch((err) => {
          setLoader(false);
        });
    } else if (
      status === true &&
      (type == "CHECKED-IN" || type == "CHECKED-OUT")
    ) {
      setLoader(true);
      let payload = {
        id: selected,
        process: type,
      };
      UpdateGroupVisitoreList(payload)
        .then((res) => {
          setShowDeleteModal(false);
          if (res.statusCode === 200) {
            VisitorList();
            setSelected([]);
            showAlertModal("success", "Record(s) update successfully");
            setLoader(false);
          } else {
            setSelected([]);
            showAlertModal("error", res.message);
            setLoader(false);
          }
        })
        .catch((err) => {
          setLoader(false);
        });
    } else if (status === true && type == "print-v-pass") {
      setLoader(true);
      let payload = {
        id: selected,
      };
      PrintVehicle(payload)
        .then((res) => {
          setShowDeleteModal(false);
          if (res.statusCode === 200) {
            // VisitorList();
            setSelected([]);
            setVisitorVehicleBadges(res.data);
            printVehicalPassOpen();
            // navigate("/visitors/visit-log", { state: { myData: res.data } });
            setLoader(false);
          } else {
            setSelected([]);
            showAlertModal("error", res.message);
            setLoader(false);
          }
        })
        .catch((err) => {
          setLoader(false);
        });
    } else if (status === true && type == "print-visitor-badge") {
      setLoader(true);
      let payload = {
        id: selected,
      };
      PrintVisitor(payload)
        .then((res) => {
          setShowDeleteModal(false);
          if (res.statusCode === 200) {
            setPrintVisitorData(res.data);
            setSelected([]);

            let checkStatus = res.data.filter((element) => {
              const endDate = new Date(element.visitor_auth_end_date)
                .toISOString()
                .split("T")[0];
              return endDate < new Date().toISOString().split("T")[0];
            }).length;
            if (checkStatus > 0) {
              setLoader(false);
              handleClickOpen();
            } else {
              printVisitorDialogOpen();
              // VisitorList();
            }

            // navigate("/visitors/visit-log", { state: { myData: res.data } });
            setLoader(false);
          } else {
            setSelected([]);
            showAlertModal("error", res.message);
            setLoader(false);
          }
        })
        .catch((err) => {
          setLoader(false);
        });
    } else {
      setShowDeleteModal(false);
    }
  };
  return (
    <div>
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={totalRecordinDb}
          filterRecord={(data) => filterRecord(data)}
          companyList={companyOptions}
          visitorTypeList={visitorTypeList}
          showAlertModal={(type, msg) => showAlertModal(type, msg)}
          dataToFilter={dataToFilter}
          checkedInOrOut={checkedInOrOut}
          setCheckedInOrOut={(status) => setCheckedInOrOut(status)}
          tableData={exportCSVLists}
          tableCalRedux={tableCalRedux}
          defalutTableCalRedux={defalutTableCalRedux}
          removeRecords={removeRecords}
        />
        <Box
          className={classNames(classes.alphabets, classes.filterDataByTabs)}
        >
          <ToggleButtonGroup
            color="primary"
            value={alphabetesbutton}
            exclusive
            onChange={changeAlphabetsTab}
          >
            <ToggleButton value="a">A</ToggleButton>
            <ToggleButton value="b">B</ToggleButton>
            <ToggleButton value="c">C</ToggleButton>
            <ToggleButton value="d">D</ToggleButton>
            <ToggleButton value="e">E</ToggleButton>
            <ToggleButton value="f">F</ToggleButton>
            <ToggleButton value="g">G</ToggleButton>
            <ToggleButton value="h">H</ToggleButton>
            <ToggleButton value="i">I</ToggleButton>
            <ToggleButton value="j">J</ToggleButton>
            <ToggleButton value="k">K</ToggleButton>
            <ToggleButton value="l">L</ToggleButton>
            <ToggleButton value="m">M</ToggleButton>
            <ToggleButton value="n">N</ToggleButton>
            <ToggleButton value="o">O</ToggleButton>
            <ToggleButton value="p">P</ToggleButton>
            <ToggleButton value="q">Q</ToggleButton>
            <ToggleButton value="r">R</ToggleButton>
            <ToggleButton value="s">S</ToggleButton>
            <ToggleButton value="t">T</ToggleButton>
            <ToggleButton value="u">U</ToggleButton>
            <ToggleButton value="v">V</ToggleButton>
            <ToggleButton value="w">W</ToggleButton>
            <ToggleButton value="x">X</ToggleButton>
            <ToggleButton value="y">Y</ToggleButton>
            <ToggleButton value="z">Z</ToggleButton>
            <ToggleButton value="refresh" title="Clear Search">
              <CachedIcon fontSize="small" />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={lists.length}
              tableCalRedux={tableCalRedux}
            />
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    <CircularProgress />
                  </TableCell>
                  <TableCell colSpan={10} />
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {stableSort(sortedData, getComparator(order, orderBy)).map(
                  (row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow hover key={row.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            onClick={(event) => handleClick(event, row.id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            selected={isItemSelected}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.id === true ? "" : "hide-cell"
                          }
                        >
                          {row.id}
                        </TableCell>
                        <EditableTableCell
                          row={row}
                          tableCalRedux={tableCalRedux}
                        />

                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.type === true ? "" : "hide-cell"
                          }
                        >
                          <Types data={row.type} />
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.host === true ? "" : "hide-cell"
                          }
                        >
                          {row.host}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.company === true ? "" : "hide-cell"
                          }
                        >
                          {row.company}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.recent === true ? "" : "hide-cell"
                          }
                        >
                          {row.recent}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.issued === true ? "" : "hide-cell"
                          }
                        >
                          {row.issued}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.expiry === true ? "" : "hide-cell"
                          }
                        >
                          {row.expiry}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.active === true ? "" : "hide-cell"
                          }
                        >
                          <ListToggle value={row.active} id={row.id} />
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.status === true ? "" : "hide-cell"
                          }
                        >
                          <Typography
                            style={{
                              textAlign: "center",
                              backgroundColor:
                                row.status === "CHECKED-IN"
                                  ? "#15db81"
                                  : row.status === "CHECKED-OUT"
                                  ? "#ff6b6b"
                                  : row.status === "Pre-book"
                                  ? "#ffa500"
                                  : row.status === "New Visitor"
                                  ? "#b1b1ff"
                                  : "none",
                              color:
                                row.status === "CHECKED-IN"
                                  ? "#ffffff"
                                  : row.status === "CHECKED-OUT" ||
                                    row.status === "Pre-book" ||
                                    row.status === "New Visitor"
                                  ? "#000000"
                                  : "unset",
                              padding:
                                row.status === "CHECKED-IN" ||
                                row.status === "CHECKED-OUT" ||
                                row.status === "Pre-book" ||
                                row.status === "New Visitor"
                                  ? "10px"
                                  : "0px",
                              borderRadius:
                                row.status === "CHECKED-IN" ||
                                row.status === "CHECKED-OUT" ||
                                row.status === "Pre-book" ||
                                row.status === "New Visitor"
                                  ? "5px"
                                  : "0px",
                            }}
                          >
                            {row.status}
                          </Typography>
                        </TableCell>
                        <TableCell
                          className={
                            tableCalRedux.action === true ? "" : "hide-cell"
                          }
                        >
                          <ListingAction
                            data={row}
                            VisitorList={() => VisitorList()}
                            showAlertModal={(type, msg) =>
                              showAlertModal(type, msg)
                            }
                            setLoader={(show) => setLoader(show)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
                {lists.length < 1 && (
                  <TableRow style={{ height: (dense ? 6 : 0) * lists.length }}>
                    <TableCell colSpan={12} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <CustomPagination
          handleChangeLimit={handleChangeLimit}
          handleChangePagination={handleChangePagination}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          totalRecord={totalRecordinDb}
        />
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {loader && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
      <DialogBox
        handleShow={showDeleteModal}
        message={modalMessage}
        conformation={(status) => handelDeleteAction(status)}
      />

      {printVehicalPassDialog && (
        <PrintVehiclePass
          printVehicalPassDialog={printVehicalPassDialog}
          printVehicalPassClose={printVehicalPassClose}
          // barCodeId={barCodeId}
          visitorVehicleBadges={visitorVehicleBadges}
          addVisitorKey={false}
        />
      )}

      {printVisitorDialog && (
        <PrintVisitorBadge
          printVisitorDialog={printVisitorDialog}
          printVisitorDialogClose={printVisitorDialogClose}
          // barCodeId={barCodeId}
          printVisitorData={printVisitorData}
          addVisitorKey={false}
        />
      )}
      {open && (
        <AlertBox
          open={open}
          handleClose={handleClose}
          printVisitorDialogOpen={printVisitorDialogOpen}
        />
      )}
    </div>
  );
}

function VisitorList() {
  return (
    <React.Fragment>
      <Helmet title="Visitor List" />
      <WithPermissionFallback controller="Visitor" action="getVisitorList">
        <Typography variant="h3" gutterBottom display="inline">
          Visitor List
        </Typography>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <EnhancedTable />
          </Grid>
        </Grid>
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default VisitorList;

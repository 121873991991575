import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  DialogTitle,
  MenuItem,
  Button,
  TextField,
  Typography,
  Box,
  FormHelperText,
} from "@mui/material";
import AddVehicleImage from "../AddVehicleImage";
import Toaster from "../../users/components/Toaster";
import dayjs from "dayjs";
import { Formik } from "formik";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// Ensure AddVehicleInventory is imported from its module
import {
  AddVehicleInventory,
  YearList,
  MakeList,
  ModelList,
} from "../../../api";

const AddVehiclesIncentory = ({
  openDialog,
  handleCloseDialog,
  vehicle,
  onUpdateTable,
}) => {
  // choose Image
  const [frontVehicle, setFrontVehicle] = useState(null);
  const [leftSideVehicle, setLeftSideVehicle] = useState(null);
  const [rightSideVehicle, setRightSideVehicle] = useState(null);
  const [rear, setRear] = useState(null);
  const [interior, setInterior] = useState(null);

  // choose image end
  const [yearData, setYearData] = useState([]);
  const [makeData, setMakeData] = useState([]);
  const [modelData, setModelData] = useState([]);
  const [image, setImage] = useState(null);
  const [imageURL, setImageURL] = useState("");
  const [value, setValue] = useState(dayjs().format("YYYY"));
  const [vehicleMake, setVehicleMake] = useState("");
  const [vehicleModal, setVehicleModal] = useState("");
  const [location, setLocation] = useState("");
  const [uid, setUid] = useState("");
  const [insuranceCarrier, setInsuranceCarrier] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  console.log("valueeeeee: ", value);
  const getYearList = () => {
    YearList()
      .then((res) => {
        if (res.statusCode == 200) {
          setYearData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Vehicle Make
  const getMakeList = (v) => {
    let payload = {
      year: v,
    };
    MakeList(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setMakeData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Vehicle Model
  const getModelList = (v) => {
    let payload = {
      make: v,
    };
    ModelList(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setModelData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setImage(file);
      setImageURL(url);
    }
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };
  const handleUidChange = (event) => {
    setUid(event.target.value);
  };
  const handleInsuranceCarrierChange = (event) => {
    setInsuranceCarrier(event.target.value);
  };
  const isFormValid = () => {
    const newErrors = {};
    if (!location) newErrors.location = "Location is required";
    if (!uid) newErrors.uid = "UID is required";
    if (!value) newErrors.value = "Date is required";
    if (!vehicleMake) newErrors.vehicleMake = "Vehicle Make is required";
    if (!vehicleModal) newErrors.vehicleModal = "Vehicle Model is required";
    if (!insuranceCarrier)
      newErrors.insuranceCarrier = "Insurance Carrier is required";
    if (!image) newErrors.image = "Vehicle image is required";
    if (!frontVehicle) newErrors.frontVehicle = "Front image is required";
    if (!leftSideVehicle)
      newErrors.leftSideVehicle = "Left side image is required";
    if (!rightSideVehicle)
      newErrors.rightSideVehicle = "Right side image is required";
    if (!rear) newErrors.rear = "Rear image is required";
    if (!interior) newErrors.interior = "Interior image is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleSave = async () => {
    if (!isFormValid()) {
      // Validation failed, do not proceed
      return;
    }
    const formData = new FormData();
    formData.append("location", location);
    formData.append("vehicle_uid", uid);
    formData.append("vehicle_year", String(value));
    formData.append("vehicle_make", vehicleMake);
    formData.append("vehicle_model", vehicleModal);
    formData.append("vehicle_insurance_carrier", insuranceCarrier);
    if (image) {
      formData.append("vehicle_image", image);
    }
    if (vehicle && vehicle.id) {
      formData.append("id", vehicle.id);
    }

    try {
      setLoading(true);
      const response = await AddVehicleInventory(formData);
      if (response) {
        // Set the appropriate toast message and show toaster
        setToastMessage(
          vehicle && vehicle.id
            ? "Record has been edited successfully"
            : "Record has been added successfully"
        );
        setOpenSnack(true); // Open the toaster
        onUpdateTable(); // Refresh table
        handleCloseDialog(); // Close the dialog
      }
    } catch (error) {
      console.error("Error saving vehicle", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!vehicle) {
      // Reset form fields when there is no vehicle data (i.e., adding a new vehicle)
      setLocation("");
      setUid("");
      setValue(dayjs().format("YYYY"));
      setVehicleMake("default");
      setVehicleModal("default");
      setInsuranceCarrier("");
      setImage(null);
      setImageURL("");
      getMakeList(dayjs().format("YYYY"));
    }
  }, [vehicle]);

  useEffect(() => {
    if (vehicle && vehicle.id) {
      setLocation(vehicle.location_id || "");
      setUid(vehicle.vehicle_uid || "");

      const vehicleYear = dayjs(vehicle.vehicle_year).format("YYYY");
      setValue(String(vehicleYear));
      setVehicleMake(vehicle.vehicle_make || "");
      setVehicleModal(vehicle.vehicle_model || "");
      setInsuranceCarrier(vehicle.vehicle_insurance_carrier || "");
      setImageURL(vehicle.vehicle_image || "");

      if (vehicleYear) {
        getMakeList(vehicleYear);
      }

      if (vehicle.vehicle_make) {
        getModelList(vehicle.vehicle_make);
      }
    }
  }, [vehicle]);
  useEffect(() => {
    if (!openDialog) {
      // Reset form fields when the dialog is closed
      setLocation("");
      setUid("");
      setValue(dayjs().format("YYYY"));
      setVehicleMake("default");
      setVehicleModal("default");
      setInsuranceCarrier("");
      setImage(null);
      setImageURL("");
      setErrors({});
    }
  }, [openDialog]);

  useEffect(() => {
    getYearList();
  }, []);

  const handleCloseSnack = () => {
    setOpenSnack(false); // Close the snackbar
  };

  return (
    <>
      <Toaster
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
        msg={toastMessage}
      />
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px",
            },
          },
        }}
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>
          {vehicle && vehicle.id
            ? "Edit Vehicle Inventory"
            : "Add Vehicle Inventory"}
        </DialogTitle>
        <DialogContent>
          <Formik initialValues={{ model: "" }} onSubmit={handleSave}>
            {({ touched, handleBlur, setFieldValue }) => (
              <form>
                <FormControl sx={{ width: "100%", marginTop: "20px" }}>
                  <TextField
                    id="location"
                    label="Location *"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="Enter your location"
                    variant="outlined"
                    value={location} // Make sure this is correctly connected to your state
                    onChange={handleLocationChange}
                    error={!!errors.location}
                    helperText={errors.location}
                  />
                </FormControl>
                <FormControl sx={{ width: "100%", marginTop: "20px" }}>
                  <TextField
                    id="uid"
                    label="NFC button ID *"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="Scan an NFC button to get the UID and add it here"
                    variant="outlined"
                    value={uid}
                    onChange={handleUidChange}
                    error={!!errors.uid}
                    helperText={errors.uid}
                  />
                </FormControl>
                <FormControl sx={{ width: "100%", marginTop: "20px" }}>
                  <InputLabel shrink id="vehicle_year">
                    Vehicle Year
                  </InputLabel>
                  <Select
                    labelId="vehicle_year"
                    id="vehicle_year-select"
                    name="vehicle_year"
                    label="Vehicle Year*"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300, // Adjust this value as needed
                          background: "#f7f9fc",
                        },
                      },
                    }}
                    value={value}
                    onChange={(e) => {
                      setValue(e.target.value); // update year
                      setFieldValue("vehicle_year", e.target.value);
                      getMakeList(e.target.value);
                    }}
                    onBlur={handleBlur}
                    error={Boolean(touched.vehicle_year && errors.vehicle_year)}
                    fullWidth
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      Select year
                    </MenuItem>
                    {yearData.map((data) => {
                      return (
                        <MenuItem value={data.value} key={data.value}>
                          {data.key}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText error>
                    {touched.vehicle_year && errors.vehicle_year}
                  </FormHelperText>
                </FormControl>
                <FormControl sx={{ width: "100%", marginTop: "20px" }}>
                  <InputLabel shrink id="vehicle_make">
                    Vehicle Make
                  </InputLabel>
                  <Select
                    labelId="vehicle_make"
                    id="vehicle_make-select"
                    name="vehicle_make"
                    label="Vehicle Make*"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300, // Adjust this value as needed
                          background: "#f7f9fc",
                        },
                      },
                    }}
                    value={vehicleMake}
                    onChange={(e) => {
                      setVehicleMake(e.target.value);
                      setFieldValue("vehicle_make", e.target.value);
                      getModelList(e.target.value);
                    }}
                    onBlur={handleBlur}
                    error={Boolean(touched.vehicle_make && errors.vehicle_make)}
                    fullWidth
                    displayEmpty
                  >
                    <MenuItem value="default">Select Make</MenuItem>
                    {makeData.map((data) => {
                      return (
                        <MenuItem value={data.value} key={data.value}>
                          {data.key}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText error>
                    {touched.vehicle_make && errors.vehicle_make}
                  </FormHelperText>
                </FormControl>
                <FormControl sx={{ width: "100%", marginTop: "20px" }}>
                  <InputLabel shrink id="vehicle_model">
                    Vehicle Model
                  </InputLabel>
                  <Select
                    labelId="vehicle_model"
                    id="vehicle_model-select"
                    name="vehicle_model"
                    label="Vehicle Make*"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300, // Adjust this value as needed
                          background: "#f7f9fc",
                        },
                      },
                    }}
                    value={vehicleModal}
                    onChange={(e) => {
                      setVehicleModal(e.target.value);
                    }}
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.vehicle_model && errors.vehicle_model
                    )}
                    fullWidth
                    displayEmpty
                  >
                    <MenuItem value="default">Select Model</MenuItem>
                    {modelData.map((data) => {
                      return (
                        <MenuItem value={data.value} key={data.value}>
                          {data.key}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText error>
                    {touched.vehicle_model && errors.vehicle_model}
                  </FormHelperText>
                </FormControl>

                <FormControl sx={{ width: "100%", marginTop: "20px" }}>
                  <TextField
                    id="insurance-carrier"
                    label="Insurance Carrier*"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="Enter insurance carrier"
                    variant="outlined"
                    value={insuranceCarrier}
                    onChange={handleInsuranceCarrierChange}
                    error={!!errors.insuranceCarrier}
                    helperText={errors.insuranceCarrier}
                  />
                </FormControl>
                {/* <FormControl sx={{ width: "100%", marginTop: "20px" }}>
                  <Typography variant="body2">Add Vehicle image</Typography>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="image-upload"
                    type="file"
                    onChange={handleImageUpload}
                  />
                  {imageURL && (
                    <Box sx={{ marginTop: "20px" }}>
                      <img
                        src={imageURL}
                        alt="Vehicle"
                        style={{ width: "160px", height: "180px" }}
                      />
                    </Box>
                  )}
                  {errors.image && (
                    <Typography color="error" variant="body2">
                      {errors.image}
                    </Typography>
                  )}
                  <label htmlFor="image-upload">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      sx={{ marginTop: "10px" }}
                    >
                      Upload Vehicle Image
                    </Button>
                  </label>
                </FormControl> */}
                <FormControl sx={{ width: "100%", marginTop: "20px" }}>
                  <AddVehicleImage
                    frontVehicle={frontVehicle}
                    leftSideVehicle={leftSideVehicle}
                    rightSideVehicle={rightSideVehicle}
                    rear={rear}
                    interior={interior}
                    setFrontVehicle={(file) => setFrontVehicle(file)}
                    setLeftSideVehicle={(file) => setLeftSideVehicle(file)}
                    setRightSideVehicle={(file) => setRightSideVehicle(file)}
                    setRear={(file) => setRear(file)}
                    setInterior={(file) => setInterior(file)}
                    frontError={errors.frontVehicle}
                    leftError={errors.leftSideVehicle}
                    rightError={errors.rightSideVehicle}
                    rearError={errors.rear}
                    interiorError={errors.interior}
                  />
                </FormControl>
              </form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions sx={{ paddingBottom: "30px", paddingRight: "20px" }}>
          <Button
            onClick={handleCloseDialog}
            color="primary"
            variant="outlined"
          >
            Close
          </Button>
          <Button
            onClick={handleSave}
            disabled={loading}
            color="primary"
            sx={{ marginLeft: "10px" }}
            variant="contained"
          >
            {loading ? "Saving..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddVehiclesIncentory;

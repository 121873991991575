import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Formik } from "formik";
import { Snackbar, Alert } from "@mui/material";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CustomAlert from "../../components/form-components/CustomAlert";
import {
  GetUserList,
  MakeActiveInactive,
  UpdateStatus,
  UpdateLoginAuth,
  DeleteUser,
  UpdateUserPassword,
  GenerateQr,
  Disable2FA,
  UserColumnList,
} from "../../api";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../redux/slices/mainSlice";
import {
  // getSnackClose,
  getChecked,
  // getMsg,
} from "../../redux/slices/userSlice";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  FormControlLabel,
  Switch,
  Dialog,
  Menu,
  MenuItem,
  FormGroup,
  FormControl,
  InputLabel as MuiInputLabel,
  Select,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText,
  ListItemText as MuiListItemText,
  ListItemIcon,
  TextField,
  Card,
  CardContent,
  FormHelperText,
  InputAdornment,
  Pagination,
} from "@mui/material";
import {
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  Settings as SettingsIcon,
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import axios from "axios";
import { NavLink } from "react-router-dom";
import Tablerow from "./components/Tablerow";
import Toaster from "./components/Toaster";
import PasswordStrength from "./components/PasswordStrength";
import hasPermission from "../../utils/hasPermission";
import WithPermissionFallback from "../../utils/withPermissionFallback";

const Paper = styled(MuiPaper)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;

const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
                whiteSpace: "initial",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "User Name",
  },
  {
    id: "Color",
    numeric: false,
    disablePadding: false,
    label: "Color",
  },
  { id: "Email", numeric: false, disablePadding: false, label: "Email" },
  { id: "Type", numeric: false, disablePadding: false, label: "Type" },
  { id: "Web", numeric: false, disablePadding: false, label: "Web" },
  { id: "Mobile", numeric: false, disablePadding: false, label: "Mobile" },
  { id: "MFA", numeric: false, disablePadding: false, label: "MFA" },
  { id: "created", numeric: false, disablePadding: false, label: "Created" },
  { id: "lastseen", numeric: false, disablePadding: false, label: "Last Seen" },
  { id: "Action", numeric: false, disablePadding: false, label: "Action" },
];

const EnhancedTableHead = (props) => {
  const [headColumn, setHeadColumn] = useState([]); //My header...
  const checkedValue = useSelector((state) => state.user.checked);
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    colArray,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  //  Function for preselected options in site.
  const headResult = () => {
    let result = [];
    checkedValue.forEach((obj) => {
      colArray.forEach((result3) => {
        if (result3.key == obj) {
          result.push(result3);
        }
      });
    });
    result.sort((a, b) => {
      return a.sort_id - b.sort_id;
    });

    setHeadColumn(result);
  };

  useEffect(() => {
    headResult();
  }, [checkedValue, colArray]);

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headColumn.map((headCell) => (
          <TableCell
            key={headCell.key}
            align="left"
            padding={headCell.key === "first_name" ? "none" : "normal"}
            sortDirection={orderBy === headCell.key ? order : false}
            sx={{ whiteSpace: "nowrap" }}
          >
            <TableSortLabel
              active={
                headCell.key === "first_name" ? orderBy === headCell.key : false
              }
              direction={orderBy === headCell.key ? order : "asc"}
              onClick={
                headCell.key === "first_name"
                  ? createSortHandler(headCell.key)
                  : null
              }
              hideSortIcon={headCell.key === "first_name" ? false : true}
            >
              {headCell.value}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="left">Action</TableCell>
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const dispatch = useDispatch();
  const [actions, setActions] = useState("");
  const {
    handleDeleteOpen,
    totalRecord,
    colArray,
    getColumnList,
    getColumnChecked,
  } = props;
  const checkedValue = useSelector((state) => state.user.checked);
  const handleChange = (event) => {
    setActions(event.target.value);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };

  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };

  //Handler for checked unchecked
  const handleChecked = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      dispatch(getChecked([...checkedValue, value]));
    } else {
      dispatch(getChecked(checkedValue.filter((e) => e !== value)));
    }
  };
  return (
    <Toolbar>
      <div>
        <Paper mt={3}>
          <FormControl mr={2} sx={{ minWidth: 120 }}>
            <InputLabel id="demo-simple-select-label">Actions</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={actions}
              label="Actions"
              onChange={handleChange}
              MenuProps={{
                PaperProps: {
                  sx: {
                    "& .MuiMenu-list": {
                      boxShadow:
                        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                      margin: "0px 3px 5px 3px",
                      padding: "0",
                    },
                  },
                },
              }}
            >
              <MenuItem
                disabled={!hasPermission("Users", "deleteMultipleUser")}
                value={10}
                onClick={handleDeleteOpen}
              >
                Delete
              </MenuItem>
            </Select>
          </FormControl>
        </Paper>
      </div>
      <div className="totalRecord">
        {totalRecord > 0 ? (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content", marginLeft: "15px" }}
          >
            {totalRecord} records found
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            {/* {numSelected} */}
          </Typography>
        )}
      </div>
      <Spacer />
      <div>
        <Paper mt={3}>
          <IconButton
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </IconButton>
          <StyledFilterMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              aria-label="adduser"
              component={NavLink}
              to="/users/adduser"
              size="large"
            >
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="Add User" pl={0} />
            </MenuItem>
            <MenuItem
              aria-owns={anchorColumn ? "columns-dropdown" : undefined}
              aria-haspopup="true"
              onClick={handleClickColumn}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Columns" pl={0} />
            </MenuItem>
          </StyledFilterMenu>
          <StyledColumnDropdown
            id="columns-dropdown"
            anchorEl={anchorColumn}
            open={Boolean(anchorColumn)}
            onClose={handleCloseColumn}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {checkedValue.length} out of {colArray.length} visible
                  </Typography>
                  <Box>
                    <FormGroup>
                      {colArray.map((col, index) => {
                        return (
                          <FormControlLabel
                            key={index}
                            control={
                              <Checkbox
                                disableRipple
                                checked={checkedValue.includes(col.key)}
                                onChange={(e) => handleChecked(e)}
                                value={col.key}
                              />
                            }
                            label={col.value}
                          />
                        );
                      })}
                    </FormGroup>
                  </Box>
                  <Toolbar>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        getColumnList();
                        getColumnChecked();
                      }}
                    >
                      Reset
                    </Button>
                    <Spacer />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleCloseColumn}
                    >
                      Cancel
                    </Button>
                  </Toolbar>
                </CardContent>
              </Card>
            </MenuItem>
          </StyledColumnDropdown>
        </Paper>
      </div>
    </Toolbar>
  );
};

function EnhancedTable() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [deleteId, setDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [openpwdChange, setpwdChange] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [length, setlength] = useState(null);
  // Alert Message
  const [isDeleting, setIsDeleting] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [showAlert, setShowAlert] = useState(false);

  const dispatch = useDispatch(); //Dispatcher from redux
  const [colArray, setColArray] = useState([]); //State for list of colmn list
  const checkedValue = useSelector((state) => state.user.checked);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const open = Boolean(anchorEl);
  const handleClickM = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelected([]);
    setDeleteId(id);
    setlength(1);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setAnchorEl(null);
    setDeleteId(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
    setSelected([]);
  };

  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
    setSelected([]);
  };
  const getUserData = () => {
    setLoading(true);
    GetUserList(currentPage, {
      limit: rowsPerPage,
      sorting: {
        order: order,
        by: orderBy,
      },
    })
      .then((res) => {
        if (res.statusCode === 200) {
          setLoading(false);
          setUsers(res.data);
          setCurrentPage(res.meta.current_page);
          setTotalRecord(res.meta.total);
        } else {
          setUsers([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const deleteUser = () => {
    setIsDeleting(true);
    setAnchorEl(null);
    let url = selected.length > 0 ? selected : deleteId;
    DeleteUser(url)
      .then((res) => {
        if (res.statusCode === 200) {
          getUserData();
          handleDeleteClose();
          setSelected([]);
          setOpenSnack(true);
          setIsDeleting(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsDeleting(false);
      });
  };

  const webHandleClick = (value, id) => {
    let data = value > 0 ? 0 : 1;
    MakeActiveInactive(id, { active: data })
      .then((response) => {
        showAlertModal("success", response.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const mobileHandleClick = (value, id) => {
    let data = value === true ? "I" : "A";
    UpdateStatus(id, { status: data })
      .then((response) => {
        showAlertModal("success", response.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Verify

  const mfaHandleClick = (isChecked, UserID, id) => {
    const loginAuthData = { login_auth: isChecked ? 1 : 0 };

    if (isChecked) {
      GenerateQr({ user_id: UserID })
        .then((response) => {
          showAlertModal("success", "2FA enabled successfully!");
        })
        .catch((err) => {
          console.error("Error enabling 2FA:", err);
        });
    } else {
      Disable2FA({ user_id: UserID })
        .then((response) => {
          showAlertModal("success", "2FA disabled successfully!");
        })
        .catch((err) => {
          console.error("Error disabling 2FA:", err);
        });
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const initialValues = {
    password: "",
    confirm_password: "",
  };
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Please enter your password")
      .matches(/^\S*$/, "Whitespace is not allowed")
      .test(
        "regex",
        "Password must be min 8 characters, and have 1 Special Character, 1 Uppercase, 1 Number and 1 Lowercase",
        (val) => {
          let regExp = new RegExp(
            "^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
          );
          return regExp.test(val);
        }
      ),

    confirm_password: Yup.string()
      .required("Please enter the same password")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both password need to be the same"
        ),
      }),
  });

  const isUpperCase = (string) => /[a-z]/.test(string) && /[A-Z]/.test(string);
  const hasNumber = (string) => /\d/.test(string);
  const schar = (string) => /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(string);
  const whiteSpaces = (string) => /^\S*$/.test(string);

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      let payload = {
        password: values.password,
        cpassword: values.confirm_password,
      };
      UpdateUserPassword(deleteId, payload)
        .then((res) => {
          if (res.statusCode === 200) {
            setlength(0);
            setOpenSnack(true);
            setAnchorEl(null);
          } else {
            dispatch(getMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setpwdChange(false);
      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const getColumnList = () => {
    UserColumnList()
      .then((res) => {
        setColArray(res.data.all_fields);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getColumnChecked = () => {
    UserColumnList()
      .then((res) => {
        dispatch(getChecked(res.data.default_fields));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUserData();
    getColumnList();
  }, [rowsPerPage, currentPage, order, orderBy]);

  useEffect(() => {
    if (checkedValue.length == 0) {
      getColumnChecked();
    } else {
      console.log("Redux store array is empty");
    }
    setTimeout(() => {
      dispatch(getSnackClose());
    }, 4000);
  }, []);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    setlength(newSelected.length);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setSelected([]);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const clickpwdChangeOpen = () => {
    setpwdChange(true);
  };
  const pwdChangeClose = () => {
    setpwdChange(false);
    setAnchorEl(null);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const showAlertModal = (type, message) => {
    setAlertMessage(message);
    setAlertType(type);
    setShowAlert(true);
  };

  return (
    <div>
      <Toaster
        handleCloseSnack={handleCloseSnack}
        openSnack={openSnack}
        msg={`${
          length > 0
            ? ` A total of ${length} record(s) have been deleted`
            : "Password has been successfully updated"
        }`}
      />
      <Paper mt={3}>
        <div>
          {selected.length > 0 || deleteId ? (
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to delete user?"}
              </DialogTitle>
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDeleteClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(handleDeleteClose, deleteUser)}
                  autoFocus
                >
                  {!isDeleting ? "Ok" : "Deleting..."}
                </Button>
              </DialogActions>
            </Dialog>
          ) : (
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"You haven't selected any item!"}
              </DialogTitle>
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDeleteClose}
                >
                  {" "}
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </div>
        <EnhancedTableToolbar
          numSelected={selected.length}
          deleteUser={deleteUser}
          handleDeleteOpen={handleDeleteOpen}
          totalRecord={totalRecord}
          colArray={colArray}
          setColArray={setColArray}
          getColumnList={getColumnList}
          getColumnChecked={getColumnChecked}
        />
        <TableContainer
          className="tableContainer"
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            {loading ? (
              <TableCell colSpan={13} align="center">
                <CircularProgress />
              </TableCell>
            ) : (
              <>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={users.length}
                  colArray={colArray}
                />
                <TableBody>
                  {stableSort(users, getComparator(order, orderBy)).map(
                    (user) => {
                      const isItemSelected = isSelected(user.id);
                      const labelId = `enhanced-table-checkbox-${user.id}`;
                      return (
                        <Tablerow
                          isItemSelected={isItemSelected}
                          user={user}
                          labelId={labelId}
                          handleClick={handleClick}
                          webHandleClick={webHandleClick}
                          mobileHandleClick={mobileHandleClick}
                          mfaHandleClick={mfaHandleClick}
                          handleClickM={handleClickM}
                          StyledMenu={StyledMenu}
                          anchorEl={anchorEl}
                          handleClose={handleClose}
                          NavLink={NavLink}
                          deleteId={deleteId}
                          ListItemText={ListItemText}
                          handleDeleteOpen={handleDeleteOpen}
                          open={open}
                          webToggle={user.web > 0 ? true : false}
                          mobileToggle={user.mobile === "A" ? true : false}
                          mfaToggle={user.mfa === 0 ? false : true}
                          clickpwdChangeOpen={clickpwdChangeOpen}
                          key={user.UserID}
                        />
                      );
                    }
                  )}
                  {users.length === 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 6 : 0) * users.length,
                      }}
                    >
                      <TableCell colSpan={11} align="center">
                        No records found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={Math.ceil(totalRecord / rowsPerPage)}
            page={currentPage}
            onChange={handleChangePagination}
            color="primary"
            boundaryCount={3}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <Typography sx={{ marginRight: "10px" }}>
                Rows per page :
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>

        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "500px",
              },
            },
          }}
          open={openpwdChange}
          onClose={pwdChangeClose}
          aria-labelledby="pwd-change"
          aria-describedby="pwd-change"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: "0px",
            }}
          >
            <Typography variant="h4" component="div">
              Change Password
            </Typography>
            <DialogActions sx={{ padding: "0px" }}>
              <IconButton
                sx={{ marginRight: "-10px" }}
                onClick={() => setpwdChange(false)}
              >
                <CancelIcon />
              </IconButton>
            </DialogActions>
          </DialogTitle>
          <DialogContent sx={{ padding: "0px" }}>
            <DialogContentText id="pwd-change" sx={{ padding: "30px 20px" }}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  status,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={12}>
                        <FormControl mt={6} sx={{ width: "100%" }}>
                          <TextField
                            label="Password"
                            id="outlined-adornment-password"
                            name="password"
                            type={showPassword ? "text" : "password"}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="Must be over 30% complexity"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <PasswordStrength password={values.password} />
                          <FormHelperText>
                            {values.password.length >= 8 ? (
                              <CheckCircleIcon
                                sx={{
                                  color: "#71BF45",
                                  fontSize: "15px",
                                  verticalAlign: "sub",
                                  marginRight: "10px",
                                }}
                              />
                            ) : (
                              <CancelIcon
                                sx={{
                                  color: "#D65454",
                                  fontSize: "15px",
                                  verticalAlign: "sub",
                                  marginRight: "10px",
                                }}
                              />
                            )}
                            Length of at least 8 characters
                          </FormHelperText>
                          <FormHelperText>
                            {isUpperCase(values.password) ? (
                              <CheckCircleIcon
                                sx={{
                                  color: "#71BF45",
                                  fontSize: "15px",
                                  verticalAlign: "sub",
                                  marginRight: "10px",
                                }}
                              />
                            ) : (
                              <CancelIcon
                                sx={{
                                  color: "#D65454",
                                  fontSize: "15px",
                                  verticalAlign: "sub",
                                  marginRight: "10px",
                                }}
                              />
                            )}
                            Contains uppercase and lowercase letters
                          </FormHelperText>
                          <FormHelperText>
                            {hasNumber(values.password) ? (
                              <CheckCircleIcon
                                sx={{
                                  color: "#71BF45",
                                  fontSize: "15px",
                                  verticalAlign: "sub",
                                  marginRight: "10px",
                                }}
                              />
                            ) : (
                              <CancelIcon
                                sx={{
                                  color: "#D65454",
                                  fontSize: "15px",
                                  verticalAlign: "sub",
                                  marginRight: "10px",
                                }}
                              />
                            )}
                            Contains number
                          </FormHelperText>
                          <FormHelperText>
                            {schar(values.password) ? (
                              <CheckCircleIcon
                                sx={{
                                  color: "#71BF45",
                                  fontSize: "15px",
                                  verticalAlign: "sub",
                                  marginRight: "10px",
                                }}
                              />
                            ) : (
                              <CancelIcon
                                sx={{
                                  color: "#D65454",
                                  fontSize: "15px",
                                  verticalAlign: "sub",
                                  marginRight: "10px",
                                }}
                              />
                            )}
                            Contains special characters
                          </FormHelperText>
                          <FormHelperText>
                            {whiteSpaces(values.password) ? (
                              <CheckCircleIcon
                                sx={{
                                  color: "#71BF45",
                                  fontSize: "15px",
                                  verticalAlign: "sub",
                                  marginRight: "10px",
                                }}
                              />
                            ) : (
                              <CancelIcon
                                sx={{
                                  color: "#D65454",
                                  fontSize: "15px",
                                  verticalAlign: "sub",
                                  marginRight: "10px",
                                }}
                              />
                            )}
                            Doesn’t contain spaces
                          </FormHelperText>
                          <FormHelperText>
                            <CheckCircleIcon
                              sx={{
                                color: "#71BF45",
                                fontSize: "15px",
                                verticalAlign: "sub",
                                marginRight: "10px",
                              }}
                            />
                            Doesn’t contain common password words
                          </FormHelperText>
                          <FormHelperText>
                            {values.password === values.confirm_password ? (
                              <CheckCircleIcon
                                sx={{
                                  color: "#71BF45",
                                  fontSize: "15px",
                                  verticalAlign: "sub",
                                  marginRight: "10px",
                                }}
                              />
                            ) : (
                              <CancelIcon
                                sx={{
                                  color: "#D65454",
                                  fontSize: "15px",
                                  verticalAlign: "sub",
                                  marginRight: "10px",
                                }}
                              />
                            )}
                            Passwords match
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <FormControl mt={12} sx={{ width: "100%" }}>
                          <TextField
                            label="Confirm password"
                            id="confirm-password"
                            name="confirm_password"
                            type={values.showPassword ? "text" : "password"}
                            value={values.confirm_password}
                            error={Boolean(
                              touched.confirm_password &&
                                errors.confirm_password
                            )}
                            helperText={
                              touched.confirm_password &&
                              errors.confirm_password
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="Enter same value as above"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <DialogActions
                      sx={{ paddingBottom: "30px", paddingRight: "20px" }}
                    >
                      <Button
                        onClick={pwdChangeClose}
                        color="primary"
                        variant="outlined"
                      >
                        Close
                      </Button>
                      <Button color="primary" variant="contained" type="submit">
                        Save
                      </Button>
                    </DialogActions>
                  </form>
                )}
              </Formik>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          severity={alertType}
          closeAlert={(show) => setShowAlert(show)}
          showAlert={showAlert}
        />
      )}
    </div>
  );
}

function Users() {
  const [snackopen, setSnackopen] = useState(true);
  const SnackState = useSelector((state) => state.user.snackStatus);
  const messageStatus = useSelector((state) => state.user.msg);
  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackopen(false);
  };
  return (
    <>
      <Helmet title="Users" />
      <WithPermissionFallback controller="Users" action="getUserList">
        <Typography variant="h3" gutterBottom display="inline">
          Users
        </Typography>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <EnhancedTable />
          </Grid>
        </Grid>
        {SnackState && (
          <Snackbar
            open={snackopen}
            autoHideDuration={3000}
            onClose={handleSnackClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Alert
              onClose={handleSnackClose}
              severity="success"
              variant="filled"
              sx={{ maxWidth: 600 }}
            >
              {messageStatus}
            </Alert>
          </Snackbar>
        )}
      </WithPermissionFallback>
    </>
  );
}

export default Users;

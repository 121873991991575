import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import AddInspectionCategory from "./AddInspectionCategory";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  CardContent,
  Card,
  Box,
  Button,
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  Pagination,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  Settings as SettingsIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import {
  AddEditInspectionCategory,
  InspectionCategoryList,
  DeleteInspectionCategory,
} from "../../api";
import { spacing } from "@mui/system";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../redux/slices/mainSlice";
import { useDispatch } from "react-redux";
import { handleApiResonseErrors } from "../../helper/helper";
import hasPermission from "../../utils/hasPermission";
import { makeStyles } from "@mui/styles";

// Classes Styling
const useStyles = makeStyles((theme) => ({
  tabs: {
    "& .MuiTabs-scroller": {
      borderBottom: "1px solid #0000000f",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const Image = styled.img`
  width: 100%;
  max-width: 500px;
  margin: 12px auto 0;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);

const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "150px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(categoryname, description, status, action) {
  return {
    categoryname,
    description,
    status,
    action,
  };
}
const rows = [
  createData(
    "General Work Area",
    "Main reception desk Main reception desk Main reception desk",
    "A",
    0
  ),
  createData(
    "Sanitation",
    "Main reception desk Main reception desk Main reception desk",
    "A",
    0
  ),
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "#",
  },
  {
    id: "categoryname",
    numeric: true,
    disablePadding: true,
    label: "Category Name",
  },
  {
    id: "description",
    numeric: true,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all data" }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.categoryname}
              align="left"
              sx={{ whiteSpace: "nowrap" }}
              // padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.categoryname ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.categoryname}
                direction={orderBy === headCell.categoryname ? order : "asc"}
                // onClick={createSortHandler(headCell.categoryname)}
                hideSortIcon={true}
              >
                {headCell.label}
                {headCell.icon}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const {
    numSelected,
    setCurrentPage,
    totalRecord,
    addInspectionCategoryDialogOpen,
    filterRecord,
    setDataToFilter,
    handleDeleteOpen,
  } = props;
  const [actions, setActions] = React.useState("");
  const [searchInput, setSearchInput] = useState("");
  const [isFilter, setIsFilter] = useState(false);

  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };

  //Method for search filter
  const applySearch = () => {
    const payload = {
      name: searchInput,
    };
    // console.log("Payload console+++++++++++++++", payload);
    filterRecord(payload);
  };

  //function for reseting the filter
  const resetFilter = () => {
    setSearchInput("");
    filterRecord({});
  };

  return (
    <>
      <Toolbar>
        <div>
          <Paper mt={3}>
            <FormControl mr={2} sx={{ minWidth: 120 }}>
              <InputLabel id="demo-simple-select-label">Actions</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={actions}
                label="Actions"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      "& .MuiMenu-list": {
                        boxShadow:
                          "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                        margin: "0px 3px 5px 3px",
                        padding: "0",
                      },
                    },
                  },
                }}
                onChange={handleChange}
              >
                <MenuItem
                  disabled={
                    !hasPermission(
                      "PropertyInspection",
                      "deleteInspectionCategory"
                    )
                  }
                  value={"Delete"}
                  onClick={handleDeleteOpen}
                >
                  Delete
                </MenuItem>
              </Select>
            </FormControl>
          </Paper>
        </div>
        {/* <div className="totalRecord">
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content", marginLeft: "15px" }}
          >
            {numSelected} records found
          </Typography>
        </div> */}
        <>
          {totalRecord > 0 ? (
            <Typography
              color="inherit"
              variant="subtitle1"
              mt={3}
              style={{ whiteSpace: "nowrap" }}
            >
              {totalRecord} records found
            </Typography>
          ) : (
            <Typography
              variant="subtitle1"
              mt={3}
              id="tableTitle"
              style={{ whiteSpace: "nowrap" }}
            >
              {totalRecord} record found
            </Typography>
          )}
        </>
        <Spacer />
        <div className="searchTool">
          <Box
            mt={3}
            sx={{
              display: "flex",
              flexWrap: "nowrap",
            }}
          >
            <Item sx={{ flexGrow: 1, width: "100%" }}>
              <TextField
                id="search"
                type="text"
                autoComplete="off"
                placeholder="Search by category name"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    applySearch();
                    setIsFilter(true);
                    // handleFirstPage();
                    setCurrentPage(1);
                  }
                }}
                value={searchInput}
                onChange={(e) => {
                  setSearchInput(e.target.value);
                }}
              />
            </Item>
            <Item>
              {isFilter ? (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={(e) => {
                    resetFilter();
                    setDataToFilter(null);
                    setIsFilter(false);
                    setSearchInput("");
                  }}
                  sx={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    height: "100%",
                    marginLeft: "-3px",
                  }}
                >
                  Clear
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    height: "100%",
                    marginLeft: "-3px",
                  }}
                  onClick={(e) => {
                    if (searchInput !== "") {
                      applySearch();
                      setIsFilter(true);
                      // handleFirstPage();
                      setCurrentPage(1);
                    }
                  }}
                >
                  Search
                </Button>
              )}
              {/* <Button
                variant="contained"
                color="primary"
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  marginLeft: "-3px",
                }}
              >
                Search
              </Button> */}
            </Item>
          </Box>
        </div>
        <Spacer />
        <div>
          <Paper mt={3}>
            <IconButton
              aria-owns={anchorEl ? "icon-menu" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              variant="contained"
              color="secondary"
            >
              <FilterListIcon />
            </IconButton>
            <StyledFilterMenu
              id="icon-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                onClick={() => {
                  addInspectionCategoryDialogOpen();
                  handleClose();
                }}
                disabled={
                  !hasPermission(
                    "PropertyInspection",
                    "addUpdateInspectionCategory"
                  )
                }
              >
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText
                  size="large"
                  primary="Add Inspection Category"
                  pl={0}
                />
              </MenuItem>
              {/* <MenuItem
                aria-owns={anchorColumn ? "columns-dropdown" : undefined}
                aria-haspopup="true"
                onClick={handleClickColumn}
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Columns" pl={0} />
              </MenuItem> */}
            </StyledFilterMenu>
            <StyledColumnDropdown
              id="columns-dropdown"
              anchorEl={anchorColumn}
              open={Boolean(anchorColumn)}
              onClose={handleCloseColumn}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem>
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="h2">
                      4 out of 4 visible
                    </Typography>
                    <Box>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox disableRipple />}
                          label="Category Name"
                        />
                        <FormControlLabel
                          control={<Checkbox disableRipple />}
                          label="Description"
                        />

                        <FormControlLabel
                          control={<Checkbox disableRipple />}
                          label="Status"
                        />
                        <FormControlLabel
                          control={<Checkbox disableRipple />}
                          label="Action"
                        />
                      </FormGroup>
                    </Box>
                    <Toolbar>
                      <Button color="primary" variant="contained">
                        Reset
                      </Button>
                      <Spacer />
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleCloseColumn}
                      >
                        Cancel
                      </Button>
                    </Toolbar>
                  </CardContent>
                </Card>
              </MenuItem>
            </StyledColumnDropdown>
          </Paper>
        </div>
      </Toolbar>
    </>
  );
};
function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("color");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  //State related to listing
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = React.useState(false); //State for loader
  const [inspectionCategoryListData, setInspectionCategoryListData] = useState(
    []
  );
  const [dataToFilter, setDataToFilter] = useState(null);
  const [recordId, setRecordId] = useState(null);
  const [pageLoad, setPageLoad] = useState(false);
  const [editData, setEditData] = useState({});
  const [openDelete, setOpenDelete] = useState(false); //state for delete dialog prompt
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = inspectionCategoryListData.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, categoryname) => {
    const selectedIndex = selected.indexOf(categoryname);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, categoryname);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (categoryname) => selected.indexOf(categoryname) !== -1;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (event, Id, obj) => {
    setAnchorEl(event.currentTarget);
    setEditData(obj);
    setRecordId(Id);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };

  // Add Inspection Category
  const [addInspectionCategoryDialog, setAddInspectionCategoryDialog] =
    useState(false);
  const addInspectionCategoryDialogOpen = () => {
    setAddInspectionCategoryDialog(true);
  };
  const addInspectionCategoryDialogClose = () => {
    setAddInspectionCategoryDialog(false);
    handleActionClose();
    setEditData({});
  };

  //handler for deleting the tour
  const DeleteInspectionCategoryRecord = () => {
    handleDeleteClose();
    let url = selected.length > 0 ? selected : recordId;
    DeleteInspectionCategory(url)
      .then((res) => {
        if (res.statusCode == 200) {
          getInspectionCategoryList();
          dispatch(getMsg("Record(s) has been deleted successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          setSelected([]);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        // console.log("err");
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };

  //handler for opening the delete dialog box
  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setAnchorEl(null);
    setRecordId(null);
  };

  //Method for calling the listing API
  const getInspectionCategoryList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      //  sorting: {
      //    order: order,
      //    by: orderBy,
      //  },
      ...dataToFilter,
    }; //this will be used for sending filtered data
    InspectionCategoryList(currentPage, payload)
      .then((res) => {
        console.log(res.data);
        if (res.statusCode == 200) {
          setInspectionCategoryListData(res.data);
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
        } else {
          setInspectionCategoryListData(res.data);
          setTotalRecord(0);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setInspectionCategoryListData([]);
        console.log(err);
        setTotalRecord(0);
      });
  };

  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
    setSelected([]);
  };

  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
    setSelected([]);
  };

  const filterRecord = (data) => {
    setDataToFilter(data);
  };

  useEffect(() => {
    getInspectionCategoryList();
  }, [rowsPerPage, currentPage, dataToFilter]);
  return (
    <div>
      {selected.length || recordId > 0 ? (
        <Dialog
          open={openDelete}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete inspection category?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDeleteClose} variant="contained">
              Cancel
            </Button>
            <Button
              onClick={DeleteInspectionCategoryRecord}
              autoFocus
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={openDelete}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"You haven't selected any item!"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDeleteClose}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          setCurrentPage={setCurrentPage}
          totalRecord={totalRecord}
          addInspectionCategoryDialogOpen={addInspectionCategoryDialogOpen}
          filterRecord={(data) => filterRecord(data)}
          dataToFilter={dataToFilter}
          setDataToFilter={setDataToFilter}
          handleDeleteOpen={handleDeleteOpen}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
            padding: "16px",
          }}
        >
          <IconButton
            onClick={addInspectionCategoryDialogOpen}
            disabled={
              !hasPermission(
                "PropertyInspection",
                "addUpdateInspectionCategory"
              )
            }
          >
            <AddBoxIcon fontSize="large" color="primary" />
          </IconButton>
          <Typography>
            Click 'plus icon' to add major categories to the inspection report
            that will apply to all Property Areas. Example could be 'Safe Work
            Practices', 'Safe Questions' or 'General Work Area'. Inspection
            Questions are added to Inspection Categories.
          </Typography>
        </Box>

        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={inspectionCategoryListData.length}
            />
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {stableSort(
                  inspectionCategoryListData,
                  getComparator(order, orderBy)
                )
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow hover key={row.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            onClick={(event) => handleClick(event, row.id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            selected={isItemSelected}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            paddingTop: "15px",
                            paddingBottom: "15px",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            paddingTop: "15px",
                            paddingBottom: "15px",
                          }}
                        >
                          {row.name}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            paddingTop: "15px",
                            paddingBottom: "15px",
                          }}
                        >
                          {row.description}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            paddingTop: "15px",
                            paddingBottom: "15px",
                          }}
                        >
                          {row.status}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            paddingTop: "15px",
                            paddingBottom: "15px",
                          }}
                        >
                          <IconButton
                            aria-label="Settings"
                            size="large"
                            aria-owns={anchorEl ? "icon-menu" : undefined}
                            aria-haspopup="true"
                            onClick={(e) => handleActionClick(e, row.id, row)}
                            style={{ color: "#1374C5" }}
                          >
                            <SettingsIcon />
                          </IconButton>
                          <StyledMenu
                            id="icon-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleActionClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <MenuItem
                              onClick={
                                (handleActionClose,
                                addInspectionCategoryDialogOpen)
                              }
                              disabled={
                                !hasPermission(
                                  "PropertyInspection",
                                  "addUpdateInspectionCategory"
                                )
                              }
                            >
                              <ListItemIcon>
                                <EditIcon />
                              </ListItemIcon>
                              <ListItemText primary="Edit" pl={1} />
                            </MenuItem>
                            <MenuItem
                              onClick={(handleActionClose, handleDeleteOpen)}
                              disabled={
                                !hasPermission(
                                  "PropertyInspection",
                                  "deleteInspectionCategory"
                                )
                              }
                            >
                              <ListItemIcon>
                                <DeleteIcon />
                              </ListItemIcon>
                              <ListItemText primary="Delete" pl={1} />
                            </MenuItem>
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {totalRecord === 0 && (
                  <TableRow style={{ height: (dense ? 6 : 0) * totalRecord }}>
                    <TableCell colSpan={5} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={totalRecord !== 0 ? Math.ceil(totalRecord / rowsPerPage) : 1}
            page={currentPage}
            onChange={handleChangePagination}
            color="primary"
            boundaryCount={3}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <Typography sx={{ marginRight: "10px" }}>
                Rows per page :
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                // label="Age"
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />

      {addInspectionCategoryDialog && (
        <AddInspectionCategory
          addInspectionCategoryDialog={addInspectionCategoryDialog}
          setAddInspectionCategoryDialog={setAddInspectionCategoryDialog}
          addInspectionCategoryDialogOpen={addInspectionCategoryDialogOpen}
          addInspectionCategoryDialogClose={addInspectionCategoryDialogClose}
          setPageLoad={setPageLoad}
          getInspectionCategoryList={getInspectionCategoryList}
          editData={editData}
        />
      )}

      {pageLoad && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </div>
  );
}

function InspectionCategory() {
  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default InspectionCategory;

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import AddNewCompany from "./inner-component/AddNewCompany";
import CustomTextField from "../../components/form-components/CustomTextField";
import { Formik } from "formik";
import * as Yup from "yup";
import { format, addDays } from "date-fns";
import { useDispatch } from "react-redux";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import BadgePrint from "./inner-component/BadgePrint";

import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../redux/slices/mainSlice";
import {
  YearList,
  MakeList,
  ModelList,
  VisitorTypeListDropdown,
  VisitorCompanyListDropdown,
  VisitorDepartmentList,
  AddVisitorData,
  ScanBarcode,
  VisitorEquipment,
  CheckVisitorUser,
} from "../../api";
import {
  Avatar,
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  IconButton,
  FormLabel,
  Select,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormHelperText,
  Box,
  Menu,
  Checkbox,
  FormControlLabel,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  TextareaAutosize as MuiTextareaAutosize,
  FormGroup,
  RadioGroup,
  Radio,
  Paper,
  CircularProgress,
} from "@mui/material";
import { TimePicker, DatePicker } from "@mui/lab";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Error as ErrorIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  ExpandMore as ExpandMoreIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
  Search as SearchIcon,
  SettingsOverscan as SettingsOverscanIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import GoogleAutoCompleteField from "../../components/form-components/GoogleAutoCompleteField";
import CustomSelect from "../../components/form-components/CustomSelect";
import { getFileBase64, handleApiResonseErrors } from "../../helper/helper";
import CustomCheckboxTree from "../../components/form-components/CustomCheckboxTree";
import CameraFeeding from "../../helper/camera-feeding";
import WithPermissionFallback from "../../utils/withPermissionFallback";
import hasPermission from "../../utils/hasPermission";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 20px 0 15px ${(props) => props.theme.spacing(2)};
`;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const Image = styled.img`
  max-width: initial;
  margin: 20px auto 0;
  width: 100%;
`;
const Accordion = styled(MuiAccordion)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
`;
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
// Classes Styling
const useStyles = makeStyles((theme) => ({
  accordion: {
    marginTop: 16,
    borderTop: 0,
    "& .MuiAccordion-root": {
      "& .MuiButtonBase-root": {
        padding: "0 12px",
        opacity: ".5",
      },
      "& .MuiAccordionDetails-root": {
        padding: "8px 12px 12px",
      },
    },
    "& .MuiAccordion-root::before": {
      display: "none",
    },
    "& .Mui-expanded": {
      minHeight: "auto",
      margin: "7px 0 7px",
    },
  },
  textAreaControl: {
    position: "relative",
    "& .MuiFormLabel-root": {
      position: "absolute",
      top: "-24px",
      padding: "0 5px",
      zIndex: "1",
      left: "-5px",
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const TextareaAutosize = styled(MuiTextareaAutosize)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 120px !important;
  background: transparent;
  padding: 20px 12px 15px;
  font-size: 13px;
  opacity: 0.8;
  font-weight: 200;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: normal;
`;
const TextAreaControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    "& textarea:focus-visible": {
      outlineColor: "#2f63be",
      border: "0px",
    },
  })
);
const StyledTooltip = ({ title, children, ...props }) => (
  <Tooltip
    {...props}
    title={title}
    /* arrow */
    componentsProps={{
      tooltip: {
        sx: {
          lineHeight: "1.6",
          fontSize: "10px",
          marginRight: "-15px",
          maxWidth: "235px",
        },
      },
    }}
  >
    {children}
  </Tooltip>
);
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    maxWidth: "460px",
    width: "100%",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      "& .MuiMenuItem-root": {
        padding: "10px",
        display: "block",
        "& .MuiList-root": {
          paddingTop: "0px",
          marginTop: "15px",
          paddingBottom: "0px",
          "& .MuiListItem-root": {
            padding: "0px",
            background: "#7aa8ff0d",
            "& .MuiListItemButton-root": {
              padding: "3px 5px",
            },
          },
        },
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledFormControl = styled((props) => <FormGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      display: "block",
      maxHeight: "250px",
      marginTop: "5px",
      overflowY: "auto",
      "& .MuiFormControlLabel-root": {
        display: "block",
      },
    },
  })
);
const selectSites = [
  { title: "None selected" },
  { title: "One" },
  { title: "Two" },
  { title: "Three" },
  { title: "Fouor" },
  { title: "Five" },
  { title: "Six" },
];
// const hostDepartment = [{ key: "one", value: "one" }];
const hostDepartment = [
  { key: "Executive", value: "executive" },
  { key: "Test updated", value: "testupdated" },
  { key: "Other", value: "other" },
];
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
function AddVisitorForm(props) {
  let navigate = useNavigate();
  const [isTrue, setIsTrue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [barcode, setBarcode] = useState("");
  const dispatch = useDispatch();
  const [showWebCam, setShowWebCam] = React.useState(false);
  const [uploadedImage, setUploadedImage] = React.useState(null);
  const uploadedFileRef = useRef();
  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const [barCodeId, setBarCodeId] = useState("");

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleShowWebCam = () => {
    let changeSetShowWebCam = !showWebCam;
    setShowWebCam(changeSetShowWebCam);
  };
  const handleFileChange = (evt) => {
    let file = evt.target.files[0];
    getFileBase64(file, (result) => {
      setUploadedImage(result);
    });
  };

  const clearSelectedImage = () => {
    setUploadedImage(null);
    uploadedFileRef.current.value = "";
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // Visitor status
  const [visitorstatus, setVisitorStatus] = React.useState("");
  const selectVisitorStatus = (event) => {
    setVisitorStatus(event.target.value);
  };
  // Vehicle Year
  const [vehicleyear, setVehicleYear] = React.useState("");
  const selectVehicleYear = (event) => {
    setVehicleYear(event.target.value);
  };
  // Vehicle Make
  const [vehiclemake, setVehicleMake] = React.useState("");
  const selectVehicleMake = (event) => {
    setVehicleMake(event.target.value);
  };
  // Vehicle Model
  const [vehiclemodel, setVehicleModel] = React.useState("");
  const selectVehicleModel = (event) => {
    setVehicleModel(event.target.value);
  };
  // Visitor Type
  const [visitortype, setVisitorType] = React.useState("");
  const selectVisitorType = (event) => {
    setVisitorType(event.target.value);
  };
  // Visitor Company
  const [visitorcompany, setVisitorCompany] = React.useState("");
  const selectVisitorCompany = (event) => {
    setVisitorCompany(event.target.value);
  };
  // Visitor security level
  const [vsecuritylevel, setVSecurityLevel] = React.useState("");
  const selectVSecurityLevel = (event) => {
    setVSecurityLevel(event.target.value);
  };
  // Visitor escort
  const [escort, setEscort] = React.useState("");
  const selectEscort = (event) => {
    setEscort(event.target.value);
  };
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("sm");

  const [otherinfo, setOtherInfo] = React.useState("");
  const selectOtherInfo = (event) => {
    setOtherInfo(event.target.value);
  };
  // Site Dropdown
  const [siteDropdown, setSiteDropdown] = React.useState(null);
  const siteDropdownOpen = (event) => {
    setSiteDropdown(event.currentTarget);
  };
  const siteDropdownClose = () => {
    setSiteDropdown(null);
  };
  const classes = useStyles();
  // DatePicker
  const [issuedate, setIssueDate] = React.useState(null);
  const [expirydate, setExpiryDate] = React.useState(null);

  //Equipment check in Modal
  const [scanbarcode, setScanBarcode] = React.useState(false);
  const scanBarcodeOpen = () => {
    setScanBarcode(true);
  };
  const scanBarcodeClose = () => {
    setScanBarcode(false);
    setBarcode("");
  };

  // Badge print Dialog
  const [badgePrintDialog, setBadgePrintDialog] = useState(false);
  const badgePrintDialogOpen = () => {
    setBadgePrintDialog(true);
  };
  const badgePrintDialogClose = () => {
    setBadgePrintDialog(false);
  };
  const [viewData, setViewData] = useState({});
  const handleBarcode = () => {
    ScanBarcode({ id: barcode })
      .then((res) => {
        if (res.statusCode === 200) {
          console.log(res.data);
        } else {
          console.log("errr");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Add New Company Dialog
  const [addNewCompanyDialog, setAddNewCompanyDialog] = useState(false);
  const addNewCompanyDialogOpen = () => {
    setAddNewCompanyDialog(true);
  };
  const addNewCompanyDialogClose = () => {
    setAddNewCompanyDialog(false);
  };

  const [storeId, setStoreId] = useState(null);
  const handleName = (v) => {
    CheckVisitorUser({ fname: v })
      .then((res) => {
        if (res.statusCode == 200) {
          console.log(res.data);
          handleClickOpenRestore();
          setStoreId(res.data.id);
        } else {
          console.log("err");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleStore = () => {
    navigate({
      pathname: "/visitors/edit-visitor/" + storeId,
    });
  };
  const [yearData, setYearData] = useState([]);
  //method for getting year dropdown
  const getYearList = () => {
    YearList()
      .then((res) => {
        if (res.statusCode == 200) {
          setYearData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [makeData, setMakeData] = useState([]);
  //Method for getting make from year dropdown
  const getMakeList = (v) => {
    let payload = {
      year: v,
    };
    MakeList(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setMakeData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [modelData, setModelData] = useState([]);
  const getModelList = (v) => {
    let payload = {
      make: v,
    };
    ModelList(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setModelData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [visitorTypeDropDownList, setVisitorTypeDropDownList] = useState([]);
  const visitorTypeDropdown = () => {
    VisitorTypeListDropdown()
      .then((res) => {
        if (res.statusCode === 200) {
          setVisitorTypeDropDownList(res.data);
        } else {
          setVisitorTypeDropDownList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [visitorCompanyDropdownList, setVisitorCompanyDropdownList] = useState(
    []
  );
  const visitorComppanyDropdown = () => {
    VisitorCompanyListDropdown()
      .then((res) => {
        if (res.statusCode === 200) {
          setVisitorCompanyDropdownList(res.data);
        } else {
          setVisitorCompanyDropdownList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let securityLevel = [];
  for (let i = 1; i <= 10; i++) {
    securityLevel.push(i);
  }

  const [visitorDepartmentData, setVisitorDepartmentData] = useState([]);
  const visitorDepartmentList = () => {
    VisitorDepartmentList()
      .then((res) => {
        if (res.statusCode === 200) {
          setVisitorDepartmentData(res.data);
        } else {
          setVisitorDepartmentData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Checkbox Tree
  const [equipTypeTree, setEquipTypeTree] = useState([]);
  const [isCheckedValue, setIsCheckedValue] = useState(0);

  const handleCheckedTree = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setIsCheckedValue(value);
    } else {
      setIsCheckedValue(0);
    }
  };
  const visitorEquip = (val) => {
    VisitorEquipment({ sl: val ? val : "1" })
      .then((res) => {
        if (res.statusCode == 200) {
          setEquipTypeTree(res.data);
        } else if (res.statusCode == 404) {
          dispatch(
            getMsg("No equipment available for checkout at this security level")
          );
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          console.log("err");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [openRestore, setOpenRestore] = useState(false);

  const handleClickOpenRestore = () => {
    setOpenRestore(true);
  };

  const handleCloseRestore = () => {
    setOpenRestore(false);
  };

  useEffect(() => {
    getYearList();
    visitorTypeDropdown();
    visitorComppanyDropdown();
    visitorDepartmentList();
  }, []);

  const initialValues = {
    visitor_status: "",
    visitor_name: "",
    emp_id: "",
    nfc_id: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    phone: "",
    year: "",
    make: "",
    model: "",
    driver_plate_number: "",
    notes: "",
    notify: "",
    visitor_notification: "",
    visitor_email: "",
    host_department: "",
    contact_host_upon_arrival: "",
    host_email: "",
    host_mobile: "",
    host_name: "",
    visit_purpose: "",
    storing_eqipment: "",
    storage_location: "",
    notify_upon_arrival: "",
    visitor_type: "",
    visitor_company: "",
    pass_issue_date: new Date(),
    pass_expiry_date: addDays(new Date(), 1),
    pass_issue_time: new Date(),
    pass_expiry_time: new Date(),
    escort: "",
    visitor_security_level: "",
    print_visitor_pass: "Y",
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object().shape({
    visitor_status: Yup.string().required("Please select visitor status"),
    visitor_name: Yup.string().required("Please enter visitor name"),
    visitor_type: Yup.string().required("Please select visitor type"),
    visitor_security_level: Yup.string().required(
      "Please select security level"
    ),
    visitor_company: Yup.string().required("Please select visitor company"),
    escort: Yup.string().required("Please select escort"),
    visitor_email: Yup.string().nullable().email("Please enter valid email"),
    host_email: Yup.string().nullable().email("Please enter valid email"),
    phone: Yup.string()
      .max(15, "Phone must be at most 15 characters")
      .matches(phoneRegExp, "Phone number is not valid"),
    driver_plate_number: Yup.string()
      .nullable()
      .max(15, "Driver plate number must be at most 15 characters"),
    host_mobile: Yup.string()
      .nullable()
      .max(15, "Phone must be at most 15 characters")
      .matches(phoneRegExp, "Phone number is not valid"),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let payload = {
        create_status: isTrue ? 1 : 0,
        status: values.visitor_status,
        name: values.visitor_name,
        employee_id: values.emp_id,
        nfc_id: values.nfc_id,
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        state: values.state,
        country: values.country,
        zip: values.zip,
        phone: `+1${values.phone}`,
        vehicle_year: values.year,
        vehicle_make: values.make,
        vehicle_model: values.model,
        vehicle_license_number: values.driver_lincense_number,
        email_management: values.notify ? "Yes" : "No",
        note: values.notes,
        image: uploadedImage,
        visitor_auth_start_time:
          values.pass_issue_time !== null
            ? format(values.pass_issue_time, "HH:mm:ss")
            : null,
        visitor_auth_end_time:
          values.pass_expiry_time !== null
            ? format(values.pass_expiry_time, "HH:mm:ss")
            : null,
        visitor_auth_start_date:
          values.pass_issue_date !== null
            ? format(values.pass_issue_date, "yyyy-MM-dd")
            : null,
        visitor_auth_end_date:
          values.pass_expiry_date !== null
            ? format(values.pass_expiry_date, "yyyy-MM-dd")
            : null,
        equipment_permission: isCheckedValue,
        visitor_type_id: values.visitor_type,
        visitor_company_id: values.visitor_company,
        visitor_escort: values.escort,
        security_level: values.visitor_security_level,
        print_pass_status: values.print_visitor_pass ? "Y" : "N",
        send_visitor_notification_of_scheduled_visit:
          values.visitor_notification ? "Yes" : "No",
        email: values.visitor_email,
        contact_host_upon_arrival: values.contact_host_upon_arrival
          ? "Yes"
          : "No",
        purpose_of_visit: values.visit_purpose,
        secured_by: values.storing_eqipment,
        storage_location: values.storage_location,
        instruction_upon_arrival: values.notify_upon_arrival,
        // visitor_detail_id
        host_name: values.host_name,
        department_id: values.host_department,
        host_email: values.host_email,
        mobile_no: `+1${values.host_mobile}`,
      };
      AddVisitorData(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            setBarCodeId(res.data.id);
            setLoading(false);
            dispatch(getMsg("Record saved successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            if (res.data.print_pass_status == "Y") {
              setViewData(res.data);
              badgePrintDialogOpen();
            } else {
              navigate({
                pathname: "/visitors/list-visitors",
              });
            }
          } else {
            dispatch(getMsg("Something went wrong!"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            setLoading(false);
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setLoading(false);
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
        open={openRestore}
        onClose={handleCloseRestore}
      >
        <DialogTitle
          id="peak-dialog"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Oh snap! We found a deleted record!
          </Typography>
          <DialogActions>
            <IconButton sx={{ marginRight: "-10px" }}>
              <CancelRoundedIcon onClick={handleCloseRestore} />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" gutterBottom>
            A record has been found with a matching name. This user was probably
            deleted previously.Would you like to restore this user?"
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleStore}>
            Restore
          </Button>
          <Button variant="outlined" onClick={handleCloseRestore}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          touched,
          setFieldValue,
          values,
          isSubmitting,
        }) => {
          if (isSubmitting) {
            const firstErrorKey = Object.keys(errors)[0];
            if (firstErrorKey) {
              const firstErrorField = document.querySelector(
                `[name="${firstErrorKey}"]`
              );
              if (firstErrorField) {
                firstErrorField.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              }
              firstErrorField.focus();
            }
          }
          return (
            <Card mb={6}>
              <CardContent>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="span"
                          component="p"
                          color="primary"
                        >
                          Visitor Information
                        </Typography>
                        {/* <Button
                          variant="outlined"
                          color="warning"
                          component="span"
                          onClick={scanBarcodeOpen}
                          disabled={!hasPermission("Visitor", "scanBarcode")}
                        >
                          <SettingsOverscanIcon sx={{ marginRight: "7px" }} />{" "}
                          Scan 2D barcode
                        </Button> */}
                      </Box>
                      <Dialog
                        sx={{
                          "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                              width: "100%",
                              maxWidth: "600px",
                            },
                          },
                        }}
                        open={scanbarcode}
                        onClose={scanBarcodeClose}
                        aria-labelledby="scan-barcode-modal"
                        aria-describedby="scan-barcode-modal"
                      >
                        <DialogTitle
                          id="alert-dialog-title"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: "0px",
                          }}
                        >
                          <Typography variant="h4" component="div">
                            Scan 2D Barcode
                          </Typography>
                          <DialogActions sx={{ padding: "0px" }}>
                            <IconButton
                              sx={{ marginRight: "-10px" }}
                              onClick={() => setScanBarcode(false)}
                            >
                              <CancelIcon />
                            </IconButton>
                          </DialogActions>
                        </DialogTitle>
                        <DialogContent sx={{ padding: "0px" }}>
                          <DialogContentText
                            id="search-modal"
                            sx={{ padding: "20px 25px" }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "nowrap",
                              }}
                            >
                              <Item sx={{ flexGrow: 1, width: "100%" }}>
                                <FormControl>
                                  <TextField
                                    id="scan-barcode"
                                    value={barcode}
                                    onChange={(e) => setBarcode(e.target.value)}
                                    placeholder="Scan 2D barcode"
                                  />
                                </FormControl>
                              </Item>
                              <Item>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  sx={{
                                    borderTopLeftRadius: "0px",
                                    borderBottomLeftRadius: "0px",
                                    height: "100%",
                                    marginLeft: "-3px",
                                  }}
                                  onClick={handleBarcode}
                                >
                                  <SearchIcon />
                                </Button>
                              </Item>
                            </Box>
                          </DialogContentText>
                        </DialogContent>
                      </Dialog>
                      <FormControl mt={4}>
                        <InputLabel
                          shrink
                          id="v-status"
                          sx={{
                            paddingLeft: "6px",
                            paddingRight: "6px",
                            marginLeft: "-4px",
                          }}
                        >
                          Visitor status
                        </InputLabel>
                        <Select
                          labelId="v-status"
                          id="v-status"
                          name="visitor_status"
                          value={values.visitor_status}
                          label="Visitor status"
                          onChange={(e) =>
                            setFieldValue("visitor_status", e.target.value)
                          }
                          error={Boolean(
                            touched.visitor_status && errors.visitor_status
                          )}
                          // helperText={touched.visitor_status && errors.visitor_status}
                          fullWidth
                          displayEmpty
                        >
                          <MenuItem value="">Please select</MenuItem>
                          <MenuItem value="New Visitor">New Visitor</MenuItem>
                          <MenuItem value="Pre-book">Pre-book</MenuItem>
                        </Select>
                        <FormHelperText error>
                          {touched.visitor_status && errors.visitor_status}
                        </FormHelperText>
                      </FormControl>

                      <FormControl mt={6}>
                        <CustomTextField
                          fullWidth
                          id="v-name"
                          label="Visitor name"
                          name="visitor_name"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder="John Smith"
                          onBlur={() => handleName(values.visitor_name)}
                        />
                      </FormControl>
                      <Grid container spacing={4}>
                        <Grid item xs={12} lg={6}>
                          <FormControl mt={6}>
                            <CustomTextField
                              fullWidth
                              id="emp-id"
                              label="Employee ID (optional)"
                              name="emp_id"
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Numeric only"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <FormControl mt={6}>
                            <CustomTextField
                              fullWidth
                              id="nfc-id"
                              label="NFC ID (optional)"
                              name="nfc_id"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="From issued NFC sticker"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Box className={classes.accordion}>
                        <Accordion defaultExpanded={false}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="visitor-other-info"
                            id="panel-header"
                          >
                            <Typography>Visitor Other Information</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <FormControl mt={3}>
                              <GoogleAutoCompleteField
                                // disabled={disabledFields}
                                name="address1"
                                label="Address1"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                autoComplete="off"
                                placeholder="3214 N University Ave"
                                getApiResponse={(res) => {
                                  setFieldValue(
                                    "address1",
                                    res.formatted_address
                                  );
                                  setFieldValue("city", res.locality);
                                  setFieldValue(
                                    "state",
                                    res.administrative_area_level_1
                                  );
                                  setFieldValue("zip", res.postal_code);
                                  setFieldValue("country", res.country);
                                }}
                              />
                            </FormControl>
                            <FormControl mt={6}>
                              <CustomTextField
                                fullWidth
                                id="address-2"
                                label="Address2"
                                name="address2"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Ste, unit, etc."
                              />
                            </FormControl>
                            <FormControl mt={6}>
                              <CustomTextField
                                fullWidth
                                id="city"
                                label="City"
                                name="city"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="El Paso"
                              />
                            </FormControl>
                            <FormControl mt={6}>
                              <CustomTextField
                                fullWidth
                                id="state"
                                label="State"
                                name="state"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Texas"
                              />
                            </FormControl>
                            <FormControl mt={6}>
                              <CustomTextField
                                fullWidth
                                id="country"
                                label="Country"
                                name="country"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="USA"
                              />
                            </FormControl>
                            <FormControl mt={6}>
                              <CustomTextField
                                fullWidth
                                id="zip"
                                label="Zip"
                                name="zip"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="XXXXX"
                              />
                            </FormControl>
                            <FormControl mt={6}>
                              <CustomTextField
                                fullWidth
                                id="phone"
                                label="Mobile phone"
                                name="phone"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="XXX-XXX-XXXX"
                                startAdornment={"+1"}
                              />
                            </FormControl>
                            <FormControl mt={6}>
                              <CustomSelect
                                name="year"
                                fieldLabel="Vehicle Year"
                                value={values.year}
                                options={yearData}
                                displayEmpty
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                customCallbackMethod={(evt) => {
                                  if (evt.target.value) {
                                    getMakeList(evt.target.value);
                                  }
                                }}
                              />
                            </FormControl>
                            <FormControl mt={6}>
                              <CustomSelect
                                name="make"
                                fieldLabel="Vehicle Make"
                                value={values.make}
                                options={makeData}
                                displayEmpty
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                customCallbackMethod={(evt) => {
                                  if (evt.target.value) {
                                    getModelList(evt.target.value);
                                  }
                                }}
                              />
                            </FormControl>
                            <FormControl mt={6}>
                              <CustomSelect
                                name="model"
                                fieldLabel="Vehicle Model"
                                value={values.model}
                                options={modelData}
                                displayEmpty
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                              />
                            </FormControl>
                            <FormControl mt={6}>
                              <CustomTextField
                                fullWidth
                                id="dln"
                                name="driver_lincense_number"
                                label="Driver License Number"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="ABC 123"
                              />
                            </FormControl>
                          </AccordionDetails>
                        </Accordion>
                        {values.visitor_status == "Pre-book" && (
                          <Accordion defaultExpanded={false} mt={4}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="pre-book-visitor-info"
                              id="pre-book-visitor-header"
                            >
                              <Typography>
                                Pre-Book Visitor Information
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <FormControl mt={3}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disableRipple
                                      color="primary"
                                      name="visitor_notification"
                                      value={values.visitor_notification}
                                      checked={values.visitor_notification}
                                      onChange={(e) =>
                                        setFieldValue(
                                          "visitor_notification",
                                          e.target.checked
                                        )
                                      }
                                    />
                                  }
                                  label={
                                    <>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography>
                                          Send visitor notification email of
                                          scheduled visit
                                        </Typography>
                                        {/* <Tooltip
                                      placement="top"
                                      title="If checked, visitor email address is
                                      required and visitor will receive an email
                                      reminding them of their visit."
                                    ></Tooltip> */}
                                      </Box>
                                    </>
                                  }
                                />
                              </FormControl>
                              {values.visitor_notification && (
                                <FormControl mt={6}>
                                  <CustomTextField
                                    label="Visitor Email"
                                    id="visitor_email"
                                    name="visitor_email"
                                    InputLabelProps={{ shrink: true }}
                                    placeholder="me@you"
                                  />
                                </FormControl>
                              )}
                              <FormControl mt={6}>
                                <CustomTextField
                                  label="Host Name"
                                  id="host-name"
                                  name="host_name"
                                  InputLabelProps={{ shrink: true }}
                                  placeholder="Employee who will meet with visitor"
                                />
                              </FormControl>
                              <FormControl mt={6}>
                                <CustomSelect
                                  name="host_department"
                                  fieldLabel="Host department"
                                  options={visitorDepartmentData}
                                  displayEmpty
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                />
                              </FormControl>
                              <FormControl mt={6}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disableRipple
                                      color="primary"
                                      name="contact_host_upon_arrival"
                                      value={values.contact_host_upon_arrival}
                                      checked={values.contact_host_upon_arrival}
                                      onChange={(e) =>
                                        setFieldValue(
                                          "contact_host_upon_arrival",
                                          e.target.checked
                                        )
                                      }
                                    />
                                  }
                                  label={
                                    <>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography>
                                          Contact host upon arrival
                                        </Typography>
                                        {/* <Tooltip
                                      placement="top"
                                      title="If checked, host will receive an email
                                      when visitor checks in."
                                    ></Tooltip> */}
                                      </Box>
                                    </>
                                  }
                                />
                              </FormControl>
                              <FormControl mt={6}>
                                <CustomTextField
                                  label="Host email"
                                  id="host-email"
                                  name="host_email"
                                  InputLabelProps={{ shrink: true }}
                                  placeholder="me@you.com"
                                />
                              </FormControl>
                              <FormControl mt={6}>
                                <CustomTextField
                                  label="Host mobile number"
                                  id="host-mobile"
                                  name="host_mobile"
                                  InputLabelProps={{ shrink: true }}
                                  placeholder="xxx-xxx-xxxx"
                                  startAdornment={"+1"}
                                />
                              </FormControl>
                              <FormControl mt={6}>
                                <CustomTextField
                                  label="Purpose of visit"
                                  id="visit-purpose"
                                  name="visit_purpose"
                                  InputLabelProps={{ shrink: true }}
                                  placeholder="Vendor visit, repairs and maintenance, job interview, etc."
                                />
                              </FormControl>
                              <FormControl mt={6}>
                                <FormLabel>Storing Equipment</FormLabel>
                                <RadioGroup
                                  row
                                  aria-labelledby="storing-equipment"
                                  name="storing_eqipment"
                                  value={values.storing_eqipment}
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    value="Visitor"
                                    control={<Radio />}
                                    label="Secured by visitor"
                                  />
                                  <FormControlLabel
                                    value="Host"
                                    control={<Radio />}
                                    label="Secured by host"
                                  />
                                </RadioGroup>
                              </FormControl>
                              {values.storing_eqipment == "Host" && (
                                <FormControl mt={6}>
                                  <CustomTextField
                                    label="Storage Location"
                                    id="notify-upon-arrival"
                                    name="storage_location"
                                    InputLabelProps={{ shrink: true }}
                                    placeholder=""
                                  />
                                </FormControl>
                              )}
                              <FormControl mt={6}>
                                <CustomTextField
                                  label="Notify upon arrival"
                                  id="notify-upon-arrival"
                                  name="notify_upon_arrival"
                                  InputLabelProps={{ shrink: true }}
                                  placeholder="me@you.com"
                                />
                              </FormControl>
                            </AccordionDetails>
                          </Accordion>
                        )}
                      </Box>
                      <TextAreaControl mt={3}>
                        <FormHelperText>
                          Notify if visitor is still checked in after pass
                          expiry date?
                          <StyledTooltip
                            sx={{
                              verticalAlign: "middle",
                              marginLeft: "6px",
                              marginRight: "6px",
                            }}
                            placement="top-end"
                            title={
                              <Typography>
                                Subscribe recipients at, communication - Visitor
                                Overstay
                              </Typography>
                            }
                          >
                            <ErrorIcon color="primary" />
                          </StyledTooltip>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disableRipple
                                color="success"
                                name="notify"
                                value={values.notify}
                                checked={values.notify}
                                onChange={(e) =>
                                  setFieldValue("notify", e.target.checked)
                                }
                              />
                            }
                            label=""
                          />
                        </FormHelperText>
                        <FormControl mt={1}>
                          <CustomTextField
                            fullWidth
                            id="note"
                            label="Notes"
                            name="notes"
                            multiline
                            rows={4}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="Comments about the visit, vendor or company, etc. or any other information can go here"
                          />
                        </FormControl>
                      </TextAreaControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl>
                        {uploadedImage ? (
                          <BigAvatar alt="image name" src={uploadedImage} />
                        ) : (
                          <BigAvatar
                            alt="image name"
                            src="/static/img/avatars/profile-upload.png"
                          />
                        )}

                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            JustifyContent: "space-between",
                            margin: "10px 0 15px",
                            gap: "15px",
                          }}
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            component="span"
                            onClick={(e) => clearSelectedImage(e)}
                          >
                            Clear
                          </Button>
                          <Button
                            variant="outlined"
                            color="warning"
                            component="span"
                            onClick={() => handleShowWebCam()}
                          >
                            {showWebCam ? "Close WebCam" : "WebCam"}
                          </Button>
                        </Box>
                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="raised-button-file"
                          multiple
                          type="file"
                          ref={uploadedFileRef}
                          onChange={(e) => handleFileChange(e)}
                        />
                        <label htmlFor="raised-button-file">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                          >
                            Choose image
                          </Button>
                        </label>
                      </FormControl>

                      <Grid container spacing={4}>
                        <Grid item xs={12} lg={6}>
                          <FormControl mt={6}>
                            <DatePicker
                              id="pass-issue-date"
                              label="Pass issue date"
                              name="pass_issue_date"
                              value={values.pass_issue_date}
                              onChange={(newValue) => {
                                setFieldValue("pass_issue_date", newValue);
                              }}
                              disablePast={true}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="pass_issue_date"
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "Click to select",
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <FormControl mt={6}>
                            <DatePicker
                              id="pass-expiry-date"
                              label="Pass expiry date"
                              name="pass_expiry_date"
                              value={values.pass_expiry_date}
                              onChange={(newValue) => {
                                setFieldValue("pass_expiry_date", newValue);
                              }}
                              minDate={values.pass_issue_date}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "Click to select",
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item sx={12} lg={6}>
                          <FormControl mt={3}>
                            <TimePicker
                              id="issue-time"
                              label="Pass issue time"
                              name="pass_issue_time"
                              value={values.pass_issue_time}
                              onChange={(newValue) => {
                                setFieldValue("pass_issue_time", newValue);
                              }}
                              ampm={false}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="pass_issue_time"
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "Click to select",
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item sx={12} lg={6}>
                          <FormControl mt={3}>
                            <TimePicker
                              id="expiry-time"
                              label="Pass expiry time"
                              name="pass_expiry_time"
                              value={values.pass_expiry_time}
                              onChange={(newValue) => {
                                setFieldValue("pass_expiry_time", newValue);
                              }}
                              ampm={false}
                              minTime={
                                values.pass_issue_date.getDate() ===
                                values.pass_expiry_date.getDate()
                                  ? values.pass_issue_time
                                  : null
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="pass_expiry_time"
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "Click to select",
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <FormControl mt={6}>
                        <CustomSelect
                          name="visitor_type"
                          fieldLabel="Visitor Type"
                          value={values.visitor_type}
                          options={visitorTypeDropDownList}
                          displayEmpty
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          firstOptionTitle="Please select"
                        />
                        <FormHelperText error>
                          {touched.visitor_type && errors.visitor_type}
                        </FormHelperText>
                      </FormControl>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "stretch",
                        }}
                        mt={6}
                      >
                        <Item sx={{ flexGrow: 1, width: "100%" }}>
                          <FormControl>
                            <CustomSelect
                              name="visitor_company"
                              fieldLabel="Visitor Company"
                              value={values.visitor_company}
                              options={visitorCompanyDropdownList}
                              displayEmpty
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              firstOptionTitle="Please select"
                            />
                            <FormHelperText error>
                              {touched.visitor_company &&
                                errors.visitor_company}
                            </FormHelperText>
                          </FormControl>
                        </Item>

                        <Item>
                          <Button
                            color="warning"
                            variant="outlined"
                            sx={{ height: "100%" }}
                            onClick={addNewCompanyDialogOpen}
                          >
                            <AddIcon />
                          </Button>
                        </Item>
                      </Box>
                      <FormControl mt={6}>
                        <InputLabel
                          shrink
                          id="v-security-level"
                          sx={{
                            paddingLeft: "6px",
                            paddingRight: "6px",
                            marginLeft: "-4px",
                          }}
                        >
                          Visitor security level
                        </InputLabel>
                        <Select
                          labelId="v-security-level"
                          id="v-security-level"
                          name="visitor_security_level"
                          value={values.visitor_security_level}
                          label="Visitor security level"
                          onChange={(e) => {
                            setFieldValue(
                              "visitor_security_level",
                              e.target.value
                            );
                            visitorEquip(e.target.value);
                          }}
                          error={Boolean(
                            touched.visitor_status && errors.visitor_status
                          )}
                          fullWidth
                          displayEmpty
                        >
                          <MenuItem value="">Please select</MenuItem>
                          {securityLevel.map((data) => {
                            return (
                              <MenuItem value={data} key={data}>
                                {data}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText error>
                          {touched.visitor_security_level &&
                            errors.visitor_security_level}
                        </FormHelperText>
                      </FormControl>
                      <FormControl mt={6}>
                        <InputLabel
                          shrink
                          id="escort"
                          sx={{
                            paddingLeft: "6px",
                            paddingRight: "6px",
                            marginLeft: "-4px",
                          }}
                        >
                          Visitor requires escort
                        </InputLabel>
                        <Select
                          labelId="escort"
                          id="escort"
                          name="escort"
                          value={values.escort}
                          label="Visitor requires escort"
                          onChange={(e) =>
                            setFieldValue("escort", e.target.value)
                          }
                          error={Boolean(
                            touched.visitor_status && errors.visitor_status
                          )}
                          fullWidth
                          displayEmpty
                        >
                          <MenuItem value="">Please select</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </Select>
                        <FormHelperText error>
                          {touched.escort && errors.escort}
                        </FormHelperText>
                      </FormControl>
                      <FormControl mt={6}>
                        <StyledFormControl>
                          <FormLabel component="legend">
                            Select equipment permissions
                            <StyledTooltip
                              sx={{
                                verticalAlign: "middle",
                                marginLeft: "6px",
                                marginRight: "6px",
                              }}
                              placement="top-start"
                              title={
                                <Typography>
                                  Equipment will appear below based on the
                                  Security Level selected for the visitor.
                                </Typography>
                              }
                            >
                              <ErrorIcon color="primary" />
                            </StyledTooltip>
                          </FormLabel>
                          <CustomCheckboxTree
                            handleChecked={(evt) => handleCheckedTree(evt)}
                            equipType={equipTypeTree}
                            isChecked={isCheckedValue}
                          />
                        </StyledFormControl>
                      </FormControl>
                      <FormControl mt={3}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="print_visitor_pass"
                                value={values.print_visitor_pass}
                                checked={values.print_visitor_pass}
                                onChange={(e) =>
                                  setFieldValue(
                                    "print_visitor_pass",
                                    e.target.checked
                                  )
                                }
                              />
                            }
                            label="Print visitor pass"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box sx={{ diplay: "flex" }}>
                        <Button
                          variant="outlined"
                          color="primary"
                          mt={6}
                          type="submit"
                        >
                          Create visitor
                        </Button>
                        {values.visitor_status == "New Visitor" && (
                          <Button
                            variant="contained"
                            color="warning"
                            mt={6}
                            sx={{ marginLeft: "10px" }}
                            type="submit"
                            onClick={() => setIsTrue(true)}
                          >
                            Create and check in visitor
                          </Button>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          );
        }}
      </Formik>
      {addNewCompanyDialog && (
        <AddNewCompany
          addNewCompanyDialog={addNewCompanyDialog}
          setAddNewCompanyDialog={setAddNewCompanyDialog}
          addNewCompanyDialogOpen={addNewCompanyDialogOpen}
          addNewCompanyDialogClose={addNewCompanyDialogClose}
          visitorComppanyDropdown={visitorComppanyDropdown}
        />
      )}
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
      {badgePrintDialog && (
        <BadgePrint
          badgePrintDialog={badgePrintDialog}
          setBadgePrintDialog={setBadgePrintDialog}
          badgePrintDialogOpen={badgePrintDialogOpen}
          badgePrintDialogClose={badgePrintDialogClose}
          barCodeId={barCodeId}
          viewData={viewData}
          addVisitorKey={true}
        />
      )}
      {showWebCam && (
        // <CameraFeed
        //   sendFile={uploadImage}
        //   takePhoto={takePhoto}
        // />

        <CameraFeeding
          showWebCam={showWebCam}
          closeWabCam={() => setShowWebCam(false)}
          // value={uploadedImage}
          // onChange={handleFileChange}
          setUploadedImage={setUploadedImage}
          uploadedImage={uploadedImage}
        />
      )}
    </>
  );
}

function AddVisitor() {
  return (
    <React.Fragment>
      <Helmet title="Add Visitor" />
      <WithPermissionFallback controller="Visitor" action="addVisitor">
        <Typography variant="h3" gutterBottom display="inline">
          Add Visitor
        </Typography>

        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/visitors/list-visitors">
            Visitor List
          </Link>
          <Typography>Add Visitor</Typography>
        </Breadcrumbs>

        <Divider my={6} />
        <AddVisitorForm />
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default AddVisitor;

import {
  Box,
  IconButton,
  Typography,
  ListItem,
  Grid,
  CircularProgress,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

const TransparentDialog = ({ open, onClose, activitiesList, loading }) => {
  if (!open) return null; // Prevent rendering when not open

  return (
    <Box
      sx={{
        width: "100%", // Inherit width from parent
        height: "100%", // Fill available height
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Header Section */}
      <Box
        sx={{
          padding: "8px 8px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.6)", // Dark background
          color: "#fff", // White text
        }}
      >
        <Typography variant="subtitle1">Activities List</Typography>
        <IconButton onClick={onClose} sx={{ color: "#fff" }}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>

      {/* Content Section */}
      <Box
        sx={{
          flex: 1, // Take remaining space
          backgroundColor: "rgba(255, 255, 255, 0.6)",
          padding: "10px",
          overflowY: "auto", // Enable scrolling if content overflows
        }}
      >
        {loading ? (
          <Box
            sx={{ display: "flex", justifyContent: "center", padding: "10px" }}
          >
            <CircularProgress size={24} />
          </Box>
        ) : activitiesList.length === 0 ? (
          <Typography variant="body2" sx={{ textAlign: "center" }}>
            No activities found.
          </Typography>
        ) : (
          activitiesList.map((activity, index) => (
            <ListItem
              key={index}
              sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.6) " }}
            >
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <Typography variant="body2">
                    <strong>{activity.user_name}</strong>{" "}
                    {activity.activity_type}
                  </Typography>
                  <Typography variant="body2">
                    {activity.LocationName}
                  </Typography>
                  <Typography variant="body2">{activity.scanTime}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <img
                    src={
                      activity?.profile_image || "/static/img/avatars/user.webp"
                    }
                    style={{
                      borderRadius: "50%",
                      width: "40px",
                      height: "40px",
                      objectFit: "cover",
                    }}
                    onError={(event) =>
                      (event.target.src = "/static/img/avatars/user.webp")
                    }
                  />
                </Grid>
              </Grid>
            </ListItem>
          ))
        )}
      </Box>
    </Box>
  );
};

export default TransparentDialog;

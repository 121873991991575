import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ImageListingModal from "../../components/ImageGallery/ImageListingModal";
import VideoPlayModal from "../../components/ImageGallery/VideoPlayModal";
import AudioPlayModal from "../../components/ImageGallery/AudioPlayModal";
import EditMediaUpload from "./EditMediaUpload";
import DialogBox from "../../../components/form-components/DialogBox";
import { DeleteMediaFile } from "../../../api";

import {
  Table,
  TableContainer,
  IconButton,
  TableCell,
  TableHead,
  TableRow,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TableBody,
  Menu,
  Paper,
  CircularProgress,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Settings as SettingsIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import WithPermissionHide from "../../../utils/withPermissionHide";

// ThumbnailTableWrapper;
const ThumbnailTableWrapper = styled.div`
  height: 100px;
  width: 100px;
`;
// Image
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));

const IncidentMediaList = (props) => {
  const { mediaListing, loading, getMediaListing, setPageLoad } = props;

  // Media Action Dropdown
  const [editMedia, setEditMedia] = useState(false);
  const [mediaAction, setMediaAction] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalMessage, setModalMessage] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const mediaActionClick = (event, item) => {
    setMediaAction(event.currentTarget);
    setSelectedItem(item);
  };
  const mediaActionClose = () => {
    setMediaAction(null);
  };

  const [clickedImg, setClickedImg] = useState(null);
  const [videoLink, setVideoLink] = useState(null);
  const [audioLink, setAudioLink] = useState(null);

  const viewImage = () => {
    if (selectedItem.MediaTypeID === 1) {
      setClickedImg(selectedItem.link);
    } else if (selectedItem.MediaTypeID === 2) {
      setVideoLink(selectedItem.link);
    } else if (selectedItem.MediaTypeID === 3) {
      setAudioLink(selectedItem.link);
    } else {
      var a = document.createElement("a");
      a.href = selectedItem.link;
      a.target = "_blank";
      a.download = "file.fdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const removeRecords = () => {
    if (selectedItem) {
      setModalMessage("Are you sure you want to delete?");
    } else {
      setModalMessage("You haven't selected any item!");
    }
    mediaActionClose();
    setShowDeleteModal(true);
  };

  const handelDeleteAction = (status) => {
    if (status) {
      // setLoading(true);
      DeleteMediaFile(selectedItem.MediaID)
        .then((res) => {
          setShowDeleteModal(false);
          if (res.statusCode === 200) {
            getMediaListing();
          }
          // showAlertModal("success", res.message);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setShowDeleteModal(false);
    }
  };

  return (
    <>
      <TableContainer
        className="tableContainer"
        component={Paper}
        sx={{
          margin: "15px auto auto",
          "&::-webkit-scrollbar": {
            width: 7,
            height: 7,
          },
          "&::-webkit-scrollbar-track": {
            background: "#bed2f5",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#92a6c9",
          },
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Thumbnail</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>File Name</TableCell>
              <TableCell>File Size</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Date Added</TableCell>
              <TableCell>User Name</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {mediaListing.map((item) => {
                return (
                  <TableRow hover>
                    <TableCell>
                      <ThumbnailTableWrapper>
                        <Image
                          sx={{ width: "100%" }}
                          component="img"
                          alt="Guage Chart"
                          src={
                            item.MediaTypeID === 1
                              ? item.link
                              : item.MediaTypeID === 2
                              ? "/static/img/reports/File-Video-icon.png"
                              : item.MediaTypeID === 3
                              ? "/static/img/reports/File-Audio-icon.png"
                              : item.MediaTypeID === 4
                              ? "/static/img/reports/Adobe-PDF-Document-icon.png"
                              : item.MediaTypeID === 5
                              ? "/static/img/reports/Document-Microsoft-Word-icon.png"
                              : ""
                          }
                        />
                      </ThumbnailTableWrapper>
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>
                      {item.MediaTypeID === 1
                        ? "Image"
                        : item.MediaTypeID === 2
                        ? "Vidoe"
                        : item.MediaTypeID === 3
                        ? "Audio"
                        : item.MediaTypeID === 4
                        ? "PDF"
                        : item.MediaTypeID === 5
                        ? "Docs"
                        : ""}
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>
                      {item.MediaName}
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>
                      {item.file_size ? item.file_size : "0MB"}
                    </TableCell>
                    <TableCell sx={{ minWidth: "300px" }}>
                      {item.MediaDescription}
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>
                      {item.created}
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>
                      {item.userName}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="Settings"
                        size="large"
                        aria-owns={mediaAction ? "media-action" : undefined}
                        aria-haspopup="true"
                        onClick={(evt) => mediaActionClick(evt, item)}
                        style={{ color: "#1374C5" }}
                      >
                        <SettingsIcon />
                      </IconButton>
                      <StyledMenu
                        id="media-action"
                        anchorEl={mediaAction}
                        open={Boolean(mediaAction)}
                        onClose={mediaActionClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <WithPermissionHide
                          controller="Reports"
                          action="viewMedia"
                        >
                          <MenuItem onClick={() => viewImage()}>
                            <ListItemIcon>
                              <VisibilityIcon />
                            </ListItemIcon>
                            <ListItemText primary="View" pl={1} />
                          </MenuItem>
                        </WithPermissionHide>
                        <WithPermissionHide
                          controller="Reports"
                          action="updateMediaFile"
                        >
                          <MenuItem
                            onClick={() => {
                              setEditMedia(true);
                              mediaActionClose();
                            }}
                          >
                            <ListItemIcon>
                              <EditIcon />
                            </ListItemIcon>
                            <ListItemText primary="Edit" pl={1} />
                          </MenuItem>
                        </WithPermissionHide>
                        <WithPermissionHide
                          controller="Reports"
                          action="updateMediaFile"
                        >
                          <MenuItem onClick={() => removeRecords()}>
                            <ListItemIcon>
                              <DeleteIcon />
                            </ListItemIcon>
                            <ListItemText primary="Delete" pl={1} />
                          </MenuItem>
                        </WithPermissionHide>
                      </StyledMenu>
                    </TableCell>
                  </TableRow>
                );
              })}
              {mediaListing.length == 0 && (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    No record added
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {clickedImg && (
        <ImageListingModal
          clickedImg={clickedImg}
          handelRotationRight={false}
          setClickedImg={setClickedImg}
          handelRotationLeft={false}
        />
      )}
      {videoLink && (
        <VideoPlayModal videoLink={videoLink} setVideoLink={setVideoLink} />
      )}
      {audioLink && (
        <AudioPlayModal audioLink={audioLink} setAudioLink={setAudioLink} />
      )}

      {editMedia && (
        <EditMediaUpload
          editMedia={editMedia}
          selectedItem={selectedItem}
          setEditMedia={(status) => {
            setEditMedia(status);
          }}
          getMediaListing={() => getMediaListing()}
          setPageLoad={(status) => setPageLoad(status)}
        />
      )}
      <DialogBox
        handleShow={showDeleteModal}
        message={modalMessage}
        conformation={(status) => handelDeleteAction(status)}
      />
    </>
  );
};

export default IncidentMediaList;

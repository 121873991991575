import React from "react";
import { sova } from "../../../config";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Box,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";

const PoiWatch = ({ poiWatch, poiWatchClose }) => {
  // Google Maps State
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: sova.GoogleApiKey,
    libraries: ["geometry"],
  });

  const center = {
    lat: -34.397,
    lng: 150.644,
  };

  return (
    <>
      <Dialog
        open={poiWatch}
        onClose={poiWatchClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "90%",
              height: "90vh",
              maxWidth: "800px",
              maxHeight: "800px",
              display: "flex",
              flexDirection: "column",
            },
          },
        }}
        aria-labelledby="poiWatch-title"
        aria-describedby="poiWatch-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="poiWatch-title"
        >
          <Typography variant="h4" component="div">
            POI Watch
          </Typography>
          <DialogActions>
            <IconButton sx={{ marginRight: "-10px" }} onClick={poiWatchClose}>
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent
          sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
        >
          <DialogContentText>
            Instructions: Select the circle tool below to draw a watch radius.
            After you save, if the POI is encountered at a location using SOVA
            that is inside the circle drawn, you can be set up to receive email
            alerts by adding your email in Communication &lsaquo; POI Close
            Proximity.
          </DialogContentText>
          <Box sx={{ marginTop: "20px", flexGrow: 1 }}>
            {loadError ? (
              "Error loading maps"
            ) : !isLoaded ? (
              "Loading maps..."
            ) : (
              <GoogleMap
                id="map"
                mapContainerStyle={{
                  height: "100%",
                  width: "100%",
                }}
                zoom={16}
                center={center}
                options={{ mapTypeId: "satellite" }}
              >
                {" "}
                {/* {locations.map((location, index) => (
                  <Marker
                    key={index}
                    position={location}
                    icon={{
                      url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                      scaledSize: new window.google.maps.Size(30, 30),
                      origin: new window.google.maps.Point(0, 0),
                      anchor: new window.google.maps.Point(15, 15),
                    }}
                    title={location.title}
                    onClick={() => {
                      setSelectedLocation(location);
                    }}
                  />
                ))}
                {selectedLocation && (
                  <InfoWindow
                    position={selectedLocation}
                    onCloseClick={() => {
                      setSelectedLocation(null);
                    }}
                  >
                    <h3>{selectedLocation.title}</h3>
                    {/* <div
                  style={{
                    minWidth: "320px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ paddingRight: "12px" }}>
                    <p>
                      Name:{selectedLocation.first_name}{" "}
                      {selectedLocation.last_name}
                    </p>
                    <p>Alias: {selectedLocation.poi_alias}</p>
                    <p>Location: {selectedLocation.property}</p>
                    <p>Date: {selectedLocation.encounter_date}</p>
                    <p>Resolution:{selectedLocation.eResolution}</p>
                  </div>
                  <div>
                    <img
                      src={selectedLocation.front_face}
                      alt="Encounter Image"
                    />
                  </div>
                </div> 
                  </InfoWindow>
                )} */}
              </GoogleMap>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={poiWatchClose}
            sx={{ marginRight: "10px" }}
          >
            Close
          </Button>
          <Button color="primary" variant="contained">
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PoiWatch;

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import { sova } from "../../../../config";
import CustomTextField from "../../../../components/form-components/CustomTextField";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { login } from "../../../../redux/slices/loginSlice";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  FormControl as MuiFormControl,
  Grid,
  Typography,
  IconButton,
  Button,
  TextField,
  Card,
  CardContent,
  Box,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  InputLabel as MuiInputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Link,
} from "@mui/material";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
  getSnackAlert,
} from "../../../../redux/slices/mainSlice";
import { DatePicker } from "@mui/lab";
import {
  CancelRounded as CancelRoundedIcon,
  Error as ErrorIcon,
  CloudUpload as CloudUploadIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import * as Yup from "yup";
import {
  DropMakeList,
  DropModelList,
  AddAedInventoryRecord,
  GetModelImageList,
  getLocationLatLong,
  ViewAedsInventoryInfo,
} from "../../../../api";
import moment from "moment";
import {
  getFileBase64,
  handleApiResonseErrors,
} from "../../../../helper/helper";
import { spacing } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import EquipmentImg from "../../../../helper/eqipments";
import GoogleMapReact from "google-map-react";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
// Image
const Image = styled.img`
  width: 100%;
  object-fit: contain;
  height: 100%;
`;
const Input = styled("input")({
  display: "none",
});
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
`;
const makeSelect = [
  { key: "Cardiac Science", value: "option1" },
  { key: "Defibtech", value: "option2" },
  { key: "Heartsine", value: "option3" },
  { key: "Philips", value: "option4" },
  { key: "Medtronic", value: "option5" },
  { key: "ZOLL", value: "option6" },
];
const modelSelect = [
  { key: "Option one", value: "option1" },
  { key: "Option two", value: "option2" },
  { key: "option three", value: "option3" },
  { key: "Option four", value: "option4" },
  { key: "Option five", value: "option5" },
];
const AddEditAed = ({
  addEditAedManagement,
  addEditAedManagementClose,
  setPageLoad,
  getAedsInventoryList,
  // editAedData,
  isEdit,
  aedsInventId,
}) => {
  const location = useSelector((state) => state.login.Location);

  const dispatch = useDispatch();
  const [uploadMedia, setUploadMedia] = useState(null);
  const [uploadPrescribe, setUploadPrescribe] = useState(null);
  const [presFileName, setPresFileName] = useState(null);
  const [presFileType, setPresFileType] = useState(null);
  const [uploadReg, setUploadReg] = useState(null);
  const [regFileName, setRegFileName] = useState(null);
  const [regFileType, setRegFileType] = useState(null);
  const [uploadPrompt, setUploadPrompt] = useState(false);
  const [promptReg, setPromptReg] = useState(false);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [editAedData, setEditAedData] = useState({});
  const handleOpenPrompt = () => {
    setUploadPrompt(true);
  };

  const handleClosePrompt = () => {
    setUploadPrompt(false);
  };

  const handleOpenRegPrompt = () => {
    setPromptReg(true);
  };

  const handleCloseRegPrompt = () => {
    setPromptReg(false);
  };

  let initialValues = {
    uid: editAedData ? (editAedData.uid ? editAedData.uid : "") : "",
    make: editAedData ? (editAedData.make ? editAedData.make : "") : "",
    model: editAedData ? (editAedData.model ? editAedData.model : "") : "",
    serial: editAedData ? (editAedData.serial ? editAedData.serial : "") : "",
    put_in_dervice_date: editAedData
      ? editAedData.put_in_dervice_date
        ? editAedData.put_in_dervice_date
        : null
      : null,
    pad_expiry: editAedData
      ? editAedData.pad_expiry
        ? editAedData.pad_expiry
        : null
      : null,
    battery_expiry: editAedData
      ? editAedData.battery_expiry
        ? editAedData.battery_expiry
        : null
      : null,
    location: editAedData
      ? editAedData.location
        ? editAedData.location
        : ""
      : "",
    location_lat: editAedData
      ? editAedData.location_lat
        ? editAedData.location_lat
        : ""
      : "",
    // lat,
    location_lon: editAedData
      ? editAedData.location_lon
        ? editAedData.location_lon
        : ""
      : "",
    // lng,
  };

  const validationSchema = Yup.object().shape({
    uid: Yup.string().required("Please enter uid"),
    make: Yup.string().required("Please select make"),
    model: Yup.string().required("Please select model"),
    serial: Yup.string().required("Please enter serial"),
    put_in_dervice_date: Yup.string()
      .nullable()
      .required("Please select date put in service date"),
    pad_expiry: Yup.string()
      .nullable()
      .required("Please select pad expiry date"),
    battery_expiry: Yup.string()
      .nullable()
      .required("Please select battery expiry date"),
    location: Yup.string().required("Please enter location"),
  });

  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      console.log(values);
      setPageLoad(true);
      let formattedServiceDate = values.put_in_dervice_date
        ? moment(values.put_in_dervice_date).format("YYYY-MM-DD")
        : "";
      let formattedPadDate = values.pad_expiry
        ? moment(values.pad_expiry).format("YYYY-MM-DD")
        : "";
      let formattedBatteryDate = values.battery_expiry
        ? moment(values.battery_expiry).format("YYYY-MM-DD")
        : "";
      let formData = {
        id: editAedData ? (editAedData.id ? editAedData.id : null) : null,
        uid: values.uid,
        make: values.make,
        model: values.model,
        serial: values.serial,
        put_in_dervice_date: formattedServiceDate,
        pad_expiry: formattedPadDate,
        battery_expiry: formattedBatteryDate,
        location: values.location,
        aed_image: uploadMedia,
        aed_prescription: uploadPrescribe,
        aed_registration: uploadReg,
        location_lat: lat,
        location_lon: lng,
        pres_type: presFileType,
        reg_type: regFileType,
      };
      AddAedInventoryRecord(formData)
        .then((res) => {
          setPageLoad(false);
          if (res.statusCode === 200) {
            getAedsInventoryList();
            dispatch(getMsg("AEDs inventory added successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            addEditAedManagementClose();
            setEditAedData({});
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            dispatch(getMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {
          setPageLoad(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        });
    } catch (err) {
      setPageLoad(false);
      let errors = err.data.errors;
      let error = handleApiResonseErrors(errors);
      dispatch(getMsg(error));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
    }
  };

  const uploadMediaFiles = (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      getFileBase64(file, (result) => {
        setUploadMedia(result);
      });
    }
  };

  const uploadPresFile = (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      console.log(file);
      setPresFileName(file.name);
      setPresFileType(file.type);
      getFileBase64(file, (result) => {
        setUploadPrescribe(result);
        console.log(result);
      });
    }
  };

  const uploadRegFile = (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      console.log(file);
      setRegFileName(file.name);
      setRegFileType(file.type);
      getFileBase64(file, (result) => {
        setUploadReg(result);
      });
    }
  };

  const [makeData, setMakeData] = useState([]);
  //method for getting year dropdown
  const getMakeList = () => {
    setPageLoad(true);
    DropMakeList()
      .then((res) => {
        setPageLoad(false);
        if (res.statusCode == 200) {
          setMakeData(res.data);
        }
      })
      .catch((err) => {
        setPageLoad(false);
        console.log(err);
      });
  };

  const [modelData, setModelData] = useState([]);
  const getModelList = (v) => {
    let makeId = makeData?.find((data) => data.key == v);
    setPageLoad(true);
    let payload = {
      make_id: makeId?.value,
    };
    DropModelList({ params: payload })
      .then((res) => {
        setPageLoad(false);
        if (res.statusCode == 200) {
          setModelData(res.data);
        }
      })
      .catch((err) => {
        setPageLoad(false);
        console.log(err);
      });
  };

  //Method for getting data for involved person
  const getAedInventoryRecord = () => {
    setPageLoad(true);
    ViewAedsInventoryInfo(aedsInventId)
      .then((res) => {
        setPageLoad(false);
        if (res.statusCode === 200) {
          setEditAedData(res.data);
          setLat(res.data.location_lat);
          setLng(res.data.location_lon);
        }
      })
      .catch((err) => {
        setPageLoad(false);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };

  const getLocationLatLng = () => {
    let id = location ? location.LocationID : "";
    getLocationLatLong(id).then((res) => {
      if (res.statusCode == 200) {
        setLat(res.data.lat);
        setLng(res.data.long);
      }
    });
  };

  const [modelImgData, setModelImgData] = useState(null);
  const getMImgList = (v) => {
    let imgs = EquipmentImg.find((data) => data.id == v);
    setModelImgData(imgs?.path);
  };

  // const isEmpty = (val) => {
  //   if (val === undefined || val == null || val.length === 0) {
  //     return true;
  //   }
  //   return false;
  // };

  useEffect(() => {
    // if (
    //   !isEmpty(editAedData.location_lat) &&
    //   !isEmpty(editAedData.location_lon)
    // ) {
    //   getLocationLatLng();
    // }
    // console.log(editAedData);
    // console.log(editAedData);
    if (isEdit) {
      getAedInventoryRecord();
      // setLat(editAedData?.location_lat);
      // setLng(editAedData?.location_lon);
    } else {
      getLocationLatLng();
    }
    // getLocationLatLng();
    getMakeList();
    // FindImg("LIFEPAK 500");
  }, []);

  useEffect(() => {
    if (editAedData && editAedData.make) {
      if (makeData.length > 0) {
        getModelList(editAedData.make);
      }
      getMImgList(editAedData.model);
    }
  }, [editAedData, makeData]);

  const onMarkerDragEnd = (e) => {
    setLat(e.latLng.lat());
    setLng(e.latLng.lng());
  };

  return (
    <>
      <Dialog
        aria-labelledby="addedit-aed-modal"
        aria-describedby="addedit-aed-modal"
        open={addEditAedManagement}
        onClose={() => {
          addEditAedManagementClose();
          setEditAedData({});
        }}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="addedit-aed-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Add/Edit AED
          </Typography>
          <DialogActions>
            <IconButton
              onClick={() => {
                addEditAedManagementClose();
                setEditAedData({});
              }}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
              setFieldValue,
            }) => {
              const areRequiredFieldsFilled = Object.keys(errors).length === 0;
              if (isSubmitting) {
                const firstErrorKey = Object.keys(errors)[0];
                if (firstErrorKey) {
                  const firstErrorField = document.querySelector(
                    `[name="${firstErrorKey}"]`
                  );
                  if (firstErrorField) {
                    firstErrorField.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                  }
                  firstErrorField.focus();
                }
              }
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={6}>
                      <FormControl mt={3}>
                        <CustomTextField
                          name="uid"
                          label="UID *"
                          autoComplete="off"
                          placeholder="NFC button ID (scan from mobile app)"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                      <FormControl mt={6}>
                        <InputLabel
                          shrink
                          id="department"
                          sx={{
                            paddingLeft: "6px",
                            paddingRight: "6px",
                            marginLeft: "-4px",
                          }}
                        >
                          Make *
                        </InputLabel>
                        <Select
                          labelId="make"
                          id="make-select"
                          name="make"
                          label="Make *"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={values.make}
                          onChange={(e) => {
                            setFieldValue("make", e.target.value);
                            setFieldValue("model", "");
                            setModelData([]);
                            setModelImgData(null);
                            getModelList(e.target.value);
                          }}
                          onBlur={handleBlur}
                          error={Boolean(touched.make && errors.make)}
                          helperText={touched.make && errors.make}
                          fullWidth
                          displayEmpty
                        >
                          <MenuItem disabled value="">
                            -Click to Select-
                          </MenuItem>
                          {makeData.map((data) => {
                            return (
                              <MenuItem value={data.key} key={data.key}>
                                {data.key}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {touched.make && errors.make}
                        </FormHelperText>
                        {/* <CustomSelect
                        name="make"
                        fieldLabel="Make*"
                        options={makeSelect}
                        displayEmpty
                      /> */}
                      </FormControl>
                      <FormControl mt={6}>
                        <InputLabel
                          shrink
                          id="department"
                          sx={{
                            paddingLeft: "6px",
                            paddingRight: "6px",
                            marginLeft: "-4px",
                          }}
                        >
                          Model *
                        </InputLabel>
                        <Select
                          labelId="model"
                          id="model-select"
                          name="model"
                          label="Model *"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={values.model}
                          onChange={(e) => {
                            setFieldValue("model", e.target.value);
                            getMImgList(e.target.value);
                          }}
                          onBlur={handleBlur}
                          error={Boolean(touched.model && errors.model)}
                          helperText={touched.model && errors.model}
                          fullWidth
                          displayEmpty
                        >
                          <MenuItem disabled value="">
                            {values.make !== ""
                              ? "Select Model"
                              : "Select make first"}
                            {/* -Click to Select- */}
                          </MenuItem>
                          {modelData.map((data) => {
                            return (
                              <MenuItem value={data.value} key={data.key}>
                                {data.key}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {touched.model && errors.model}
                        </FormHelperText>
                        {/* <CustomSelect
                        name="model"
                        fieldLabel="Model*"
                        options={modelSelect}
                        displayEmpty
                      /> */}
                      </FormControl>
                      <FormControl mt={6}>
                        <CustomTextField
                          name="serial"
                          label="Serial*"
                          autoComplete="off"
                          placeholder="Serial number (from ID tag on equipment)"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                      <FormControl mt={6}>
                        <DatePicker
                          name="put_in_dervice_date"
                          label="Date put in service *"
                          value={values.put_in_dervice_date}
                          onChange={(newValue) => {
                            setFieldValue("put_in_dervice_date", newValue);
                          }}
                          maxDate={new Date()}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              name="put_in_dervice_date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: moment(new Date()).format(
                                  "MM/DD/YYYY"
                                ),
                              }}
                            />
                          )}
                        />
                      </FormControl>
                      <FormControl mt={6}>
                        <DatePicker
                          name="pad_expiry"
                          label="Pad Expiry *"
                          value={values.pad_expiry}
                          onChange={(newValue) => {
                            setFieldValue("pad_expiry", newValue);
                          }}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              name="pad_expiry"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: moment(new Date()).format(
                                  "MM/DD/YYYY"
                                ),
                              }}
                            />
                          )}
                        />
                      </FormControl>
                      <FormControl mt={6}>
                        <DatePicker
                          views={["year", "month"]}
                          name="battery_expiry"
                          label="Battery Expiry *"
                          value={values.battery_expiry}
                          onChange={(newValue) => {
                            setFieldValue("battery_expiry", newValue);
                          }}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              name="battery_expiry"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: moment(new Date()).format(
                                  "MM/YYYY"
                                ),
                              }}
                            />
                          )}
                        />
                      </FormControl>
                      <FormControl mt={6}>
                        <CustomTextField
                          name="location"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Location*"
                          autoComplete="off"
                          placeholder="Fitness center, Pool, etc"
                        />
                      </FormControl>
                      <Card
                        variant="outlined"
                        sx={{ borderBottom: 0, marginTop: "15px" }}
                      >
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell
                                sx={{ border: 0, padding: "5px 16px" }}
                              >
                                AED Prescription
                                <Tooltip
                                  title="Most US States require involvement of a licensed physician or medical authority for compliance. Approval of an AED, usually in the form of a prescription, is required prior to placing an AED in service."
                                  sx={{ mb: "-6px", ml: "4px" }}
                                >
                                  <ErrorIcon color="primary" />
                                </Tooltip>
                              </TableCell>
                              <TableCell
                                textAlign="right"
                                sx={{
                                  width: "80px",
                                  border: 0,
                                  padding: "5px 16px",
                                }}
                              >
                                <input
                                  accept="image/*,application/doc,application/pdf"
                                  style={{ display: "none" }}
                                  id="prescription-button-file"
                                  multiple
                                  type="file"
                                  onChange={(e) => {
                                    uploadPresFile(e);
                                    // handleOpenPrompt(e);
                                    // setUploadStatus("loadPre");
                                  }}
                                />
                                {editAedData?.aed_prescription ? (
                                  <label>
                                    <IconButton
                                      color="primary"
                                      variant="contained"
                                      component="span"
                                    >
                                      <CloudUploadIcon
                                        sx={{ color: "green" }}
                                        onClick={handleOpenPrompt}
                                      />
                                    </IconButton>
                                  </label>
                                ) : (
                                  <label htmlFor="prescription-button-file">
                                    <IconButton
                                      color="primary"
                                      variant="contained"
                                      component="span"
                                    >
                                      <CloudUploadIcon />
                                    </IconButton>
                                  </label>
                                )}
                                {editAedData?.aed_prescription ? (
                                  <a
                                    target="_blank"
                                    href={decodeURI(
                                      editAedData?.aed_prescription
                                    )}
                                  >
                                    <IconButton color="primary">
                                      <VisibilityIcon />
                                    </IconButton>
                                  </a>
                                ) : (
                                  <IconButton>
                                    <VisibilityIcon sx={{ color: "#808080" }} />
                                  </IconButton>
                                )}
                              </TableCell>
                            </TableRow>
                            {presFileName !== null ? (
                              <TableRow>
                                <TableCell
                                  sx={{ padding: "0px 16px 10px" }}
                                  colSpan={2}
                                >
                                  {presFileName}
                                </TableCell>
                              </TableRow>
                            ) : null}

                            <TableRow>
                              <TableCell
                                sx={{ border: 0, padding: "5px 16px" }}
                              >
                                AED Registration
                                <Tooltip
                                  title="AED registration with your local Emergency Medical Services (EMS) or vehicle dispatch center of the existence, location, and type of the AED. Necessary for Good Samaritan Law compliance."
                                  sx={{ mb: "-6px", ml: "4px" }}
                                >
                                  <ErrorIcon color="primary" />
                                </Tooltip>
                              </TableCell>
                              {/* <Typography>My name</Typography> */}
                              <TableCell
                                textAlign="right"
                                sx={{
                                  width: "120px",
                                  border: 0,
                                  padding: "5px 16px",
                                }}
                              >
                                <input
                                  accept="image/*,application/doc,application/pdf"
                                  id="reg-button-file"
                                  style={{ display: "none" }}
                                  multiple
                                  type="file"
                                  onChange={(e) => {
                                    uploadRegFile(e);
                                    // setUploadStatus("loadReg");
                                  }}
                                />
                                {editAedData?.aed_registration ? (
                                  <label>
                                    <IconButton
                                      color="primary"
                                      variant="contained"
                                      component="span"
                                    >
                                      <CloudUploadIcon
                                        sx={{ color: "green" }}
                                        onClick={handleOpenRegPrompt}
                                      />
                                    </IconButton>
                                  </label>
                                ) : (
                                  <label htmlFor="reg-button-file">
                                    <IconButton
                                      color="primary"
                                      variant="contained"
                                      component="span"
                                    >
                                      <CloudUploadIcon />
                                    </IconButton>
                                  </label>
                                )}
                                {editAedData?.aed_registration ? (
                                  <a
                                    target="_blank"
                                    href={decodeURI(
                                      editAedData?.aed_registration
                                    )}
                                  >
                                    <IconButton color="primary">
                                      <VisibilityIcon />
                                    </IconButton>
                                  </a>
                                ) : (
                                  <IconButton>
                                    <VisibilityIcon sx={{ color: "#808080" }} />
                                  </IconButton>
                                )}
                                {/* <IconButton color="primary">
                                <VisibilityIcon />
                              </IconButton> */}
                              </TableCell>
                            </TableRow>
                            {regFileName !== null ? (
                              <TableRow>
                                <TableCell
                                  sx={{ padding: "0px 16px 10px" }}
                                  colSpan={2}
                                >
                                  {regFileName}
                                </TableCell>
                              </TableRow>
                            ) : null}
                          </TableBody>
                        </Table>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={6}>
                        <Grid item xs={12} md={6}>
                          <Card
                            variant="outlined"
                            sx={{
                              height: "180px",
                              mb: "15px",
                            }}
                          >
                            <CardContent
                            // sx={{
                            //   display: uploadMedia === null ? "none" : "block",
                            //   height: "100%",
                            // }}
                            >
                              <Image
                                sx={{
                                  width: "100%",
                                }}
                                src={modelImgData}
                              />
                            </CardContent>
                          </Card>
                          <Typography>(Stock image may vary)</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Card
                            variant="outlined"
                            sx={{
                              height: "180px",
                              mb: "15px",
                            }}
                          >
                            <CardContent
                            // sx={{
                            //   display:
                            //     uploadMedia || editAedData.aed_image
                            //       ? "none"
                            //       : "block",
                            //   height: "100%",
                            // }}
                            >
                              <Image
                                sx={{
                                  width: "100%",
                                }}
                                src={
                                  uploadMedia
                                    ? uploadMedia
                                    : editAedData?.aed_image
                                }
                                // src={uploadMedia}
                              />
                            </CardContent>
                          </Card>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                          >
                            <input
                              accept="image/*"
                              style={{ display: "none" }}
                              id="raised-button-file"
                              multiple
                              type="file"
                              onChange={(e) => {
                                uploadMediaFiles(e);
                              }}
                            />
                            <label htmlFor="raised-button-file">
                              <Button variant="contained" component="span">
                                Upload image
                              </Button>
                            </label>
                          </Box>
                        </Grid>
                      </Grid>

                      <FormControl mt={3}>
                        <FormLabel>
                          AED location (drag markers to correct location)
                        </FormLabel>
                        <Card
                          variant="outlined"
                          sx={{ height: "450px", overflow: "hidden" }}
                        >
                          <LoadScript googleMapsApiKey={sova.GoogleApiKey}>
                            <div style={{ height: "100%", width: "100%" }}>
                              <GoogleMap
                                mapContainerStyle={{
                                  height: "100%",
                                  width: "100%",
                                }}
                                zoom={15}
                                center={{
                                  lat: lat,
                                  lng: lng,
                                }}
                              >
                                <Marker
                                  key={"123"}
                                  position={{
                                    lat: lat,
                                    lng: lng,
                                  }}
                                  onDragEnd={(e) => onMarkerDragEnd(e)}
                                  draggable={true}
                                />
                              </GoogleMap>
                            </div>
                          </LoadScript>
                        </Card>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <DialogActions sx={{ pr: "28px" }}>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={!areRequiredFieldsFilled}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
      {uploadPrompt && (
        <Dialog
          open={uploadPrompt}
          onClose={handleClosePrompt}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm ?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              A file already exists for this category. Uploading a new file will
              permanently delete the existing file. Proceed?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <input
              accept="image/*,application/doc,application/pdf"
              style={{ display: "none" }}
              id="prescription-reload-file"
              multiple
              type="file"
              onChange={(e) => {
                uploadPresFile(e);
                handleClosePrompt();
              }}
              // onClick={handleClosePrompt}
            />
            <label htmlFor="prescription-reload-file">
              <Button
                color="primary"
                variant="contained"
                component="span"
                // onClick={handleClosePrompt}
              >
                Yes
              </Button>
            </label>

            <Button
              color="primary"
              variant="outlined"
              sx={{ marginLeft: "5px" }}
              onClick={handleClosePrompt}
              autoFocus
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {promptReg && (
        <Dialog
          open={promptReg}
          onClose={handleCloseRegPrompt}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm ?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              A file already exists for this category. Uploading a new file will
              permanently delete the existing file. Proceed?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <input
              accept="image/*,application/doc,application/pdf"
              style={{ display: "none" }}
              id="registration-reload-file"
              multiple
              type="file"
              onChange={(e) => {
                uploadRegFile(e);
                handleCloseRegPrompt();
              }}
              // onClick={handleClosePrompt}
            />
            <label htmlFor="registration-reload-file">
              <Button
                color="primary"
                variant="contained"
                component="span"
                // onClick={handleClosePrompt}
              >
                Yes
              </Button>
            </label>

            <Button
              color="primary"
              variant="outlined"
              sx={{ marginLeft: "5px" }}
              onClick={handleCloseRegPrompt}
              autoFocus
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default AddEditAed;

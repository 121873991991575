import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  CircularProgress,
  Card,
  CardContent,
  Avatar,
  Tooltip,
  DialogContent,
} from "@mui/material";
import {
  Add as AddIcon,
  Close as CloseIcon,
  Whatshot as WhatshotIcon,
  Error as ErrorIcon,
  Help as HelpIcon,
} from "@mui/icons-material";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../../redux/slices/mainSlice";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import moment from "moment";

// Replace with your actual API call
import {
  GetNonTourData,
  ChangeTaskStatus,
  FetchNonTourData,
  CompletedTask,
  DeleteTask,
} from "../../../api";
import AddEditActivityDialog from "./AddEditActivityDialog";
import { useDispatch } from "react-redux";
import { useAbly } from "ably/react";
import TaskTimeIssueDialog from "./TaskTimeIssueDialog ";

const NonTourTaskDialog = ({ nonTourTaskDialog, setNonTourTaskDialog }) => {
  const ably = useAbly();
  const dispatch = useDispatch();
  const locationId =
    JSON.parse(localStorage.getItem("location") || "{}")?.LocationID || null;
  const [tasks, setTasks] = useState({
    notStarted: [],
    started: [],
    completed: [],
  });

  const [loader, setLoader] = useState(false);
  const [addActivityDialog, setAddActivityDialog] = useState(false);
  const [editActivityDialog, setEditActivityDialog] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [taskTimeIssueDialog, setTaskTimeIssueDialog] = useState(false);

  const fetchTasks = async () => {
    setLoader(true);
    try {
      const response = await GetNonTourData();
      if (response.statusCode === 200) {
        setTasks({
          notStarted: response.data.notStarted.reverse() || [],
          started: response.data.started.reverse() || [],
          completed: response.data.completed.reverse() || [],
        });
      }
    } catch (error) {
      const errorMessage =
        error?.data?.message || "An error occurred while fetching tasks";
      dispatch(getMsg(errorMessage));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
    }
    setLoader(false);
  };

  const deleteTask = async (taskId) => {
    try {
      setLoader(true);
      await DeleteTask({ nontourdataID: taskId });
      dispatch(getMsg("Task Deleted Successfully!"));
      dispatch(getSnackAlert("success"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
      setTasks((prev) => ({
        ...prev,
        notStarted: prev.notStarted.filter(
          (task) => task.nontourdata_id !== taskId
        ),
      }));
    } catch (error) {
      console.error("Error deleting task:", error);
      const errorMessage = error?.data?.message || "Something went wrong!";
      dispatch(getMsg(errorMessage));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
    } finally {
      setLoader(false);
    }
  };

  const handleDragEnd = async (result) => {
    const { source, destination } = result;

    // If dropped outside a valid column
    if (!destination) return;

    const sourceColumn = source.droppableId;
    const destinationColumn = destination.droppableId;

    // Restrict movement rules
    if (
      (sourceColumn === "notStarted" && destinationColumn === "completed") || // Can't directly move Not Started to Completed
      (sourceColumn === "completed" && destinationColumn !== "completed") // Can't move from Completed to other columns
    ) {
      return;
    }

    const startTasks = [...tasks[sourceColumn]];
    const finishTasks = [...tasks[destinationColumn]];

    // Remove the task from the source column
    const [movedTask] = startTasks.splice(source.index, 1);

    // Clone the task to modify it without mutating the state directly
    let updatedTask = { ...movedTask };
    let adjustedTime = null;
    //**Check if moving from "started" → "completed" within 1 min**
    if (sourceColumn === "started" && destinationColumn === "completed") {
      const scanTime = moment(updatedTask.scan_time, "YYYY-MM-DD HH:mm:ss");
      const now = moment();
      const diffInMinutes = now.diff(scanTime, "minutes");

      if (diffInMinutes < 1) {
        // Open the dialog and wait for the user's response
        const res = await new Promise((resolve) => {
          setTaskTimeIssueDialog({ open: true, resolve });
        });

        if (res === null) {
          return; // User canceled, stop execution
        }

        adjustedTime = res; // Use selected adjusted time
      }
    }

    // Set the correct timestamps based on movement
    if (sourceColumn === "notStarted" && destinationColumn === "started") {
      updatedTask.scan_time = moment().format("YYYY-MM-DD HH:mm:ss"); // Set scan_time to current time
    } else if (
      sourceColumn === "started" &&
      destinationColumn === "completed"
    ) {
      updatedTask.finish_time = moment().format("YYYY-MM-DD HH:mm:ss"); // Set finish_time to current time
    } else if (
      sourceColumn === "started" &&
      destinationColumn === "notStarted"
    ) {
      updatedTask.scan_time = ""; // Reset scan_time when moving back to Not Started
    }

    // Add the updated task to the destination column
    finishTasks.splice(destination.index, 0, updatedTask);

    // // Add the task to the destination column
    // finishTasks.splice(destination.index, 0, movedTask);

    setTasks((prev) => ({
      ...prev,
      [sourceColumn]: startTasks,
      [destinationColumn]: finishTasks,
    }));

    // Call the appropriate API based on the movement
    try {
      if (sourceColumn === "notStarted" && destinationColumn === "started") {
        // Moving from Not Started to Started
        await ChangeTaskStatus({
          typeId: movedTask.nontourdata_id,
          status: "YES",
        });
      } else if (
        sourceColumn === "started" &&
        destinationColumn === "completed"
      ) {
        // Moving from Started to Completed
        const { data } = await FetchNonTourData({
          non_tour_id: movedTask.nontourdata_id,
        });
        const payload = {
          id: data?.id,
          user_activity_id: data?.user_activity_id,
          comment: data?.Comment,
          followup: data?.followup,
          passdown: data?.passdown,
          dispatch_hdn_lon: data?.gps_longitude,
          dispatch_hdn_lat: data?.gps_latitude,
          dispatch_hdn_user_id: data?.UserID,
          dispatch_hdn_user_name: data?.user_name,
          dispatch_hdn_display_name: data?.TaskName,
          start_time: data?.start_time,
          end_time: data?.endTime,
          informed_time: data?.informed_time,
          informant: data?.informant,
          location: data?.task_location,
          timeAdjusted: adjustedTime ? adjustedTime : "",
          save_completed_session: "1",
        };
        await CompletedTask(payload);
      } else if (
        sourceColumn === "started" &&
        destinationColumn === "notStarted"
      ) {
        // Moving from Started to Not Started
        await ChangeTaskStatus({
          typeId: movedTask.nontourdata_id,
          status: "NO",
        });
      }
      dispatch(getMsg("Task Status Updated Successfully!"));
      dispatch(getSnackAlert("success"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
    } catch (error) {
      console.error("Error moving task:", error);
      dispatch(getMsg("Failed to update task status. Reverting changes"));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);

      // Revert the changes in state if API fails
      finishTasks.splice(destination.index, 1); // Remove task from destination
      startTasks.splice(source.index, 0, movedTask); // Add task back to source
      setTasks((prev) => ({
        ...prev,
        [sourceColumn]: startTasks,
        [destinationColumn]: finishTasks,
      }));
    }
  };

  const onMessage = (msg) => {
    let data;
    try {
      data = typeof msg.data === "string" ? JSON.parse(msg.data) : msg.data;
    } catch (error) {
      console.error("Error parsing Ably message:", error);
      return;
    }

    console.log("Updated Task: ", data);

    if (!data?.type || !data?.task) return;

    const updatedTask = data.task;

    setTasks((prev) => {
      const updatedTasks = { ...prev };

      // **Find the existing column of the task**
      let previousColumn = null;
      Object.keys(updatedTasks).forEach((column) => {
        if (
          updatedTasks[column].some(
            (t) => t.nontourdata_id == updatedTask.nontourdata_id
          )
        ) {
          previousColumn = column;
        }
      });

      //  **If task is in "completed" and being edited, ignore it**
      if (data.type === "edited" && previousColumn === "completed") {
        console.error(
          "Wrong response from Ably: Completed task can't be edited"
        );
        return updatedTasks; // Do nothing
      }

      // **For "edited", replace the task within the same column**
      if (data.type === "edited" && previousColumn) {
        updatedTasks[previousColumn] = updatedTasks[previousColumn].map((t) =>
          t.nontourdata_id == updatedTask.nontourdata_id ? updatedTask : t
        );
        return updatedTasks;
      }

      // **For "created", add the task to "notStarted"**
      if (data.type === "created") {
        updatedTasks.notStarted = [updatedTask, ...updatedTasks.notStarted];
        return updatedTasks;
      }

      // **For "deleted", remove from all columns**
      if (data.type === "deleted") {
        Object.keys(updatedTasks).forEach((column) => {
          updatedTasks[column] = updatedTasks[column].filter(
            (t) => t.nontourdata_id != updatedTask.nontourdata_id
          );
        });
        return updatedTasks;
      }

      // **For "moved", remove from old column & add to new column**
      if (data.type === "moved") {
        // To remove from old column
        if (previousColumn) {
          updatedTasks[previousColumn] = updatedTasks[previousColumn].filter(
            (t) => t.nontourdata_id != updatedTask.nontourdata_id
          );
        }

        // Determine the new column based on Ably response
        let newColumn =
          updatedTask.scan_time !== null && updatedTask.finish_time !== null
            ? "completed"
            : updatedTask.scan_time !== null
            ? "started"
            : "notStarted";

        updatedTasks[newColumn] = [updatedTask, ...updatedTasks[newColumn]];
        return updatedTasks;
      }

      console.error("Unhandled task type:", data.type);
      return updatedTasks;
    });
  };

  useEffect(() => {
    const channelName = `nontourtask_channel${locationId}`;
    const channel = ably.channels.get(channelName); // Get the channel instance

    // Subscribe to  channel
    channel.subscribe(onMessage);

    // Cleanup on unmount
    return () => {
      // Unsubscribe from the channel
      channel.unsubscribe(onMessage);

      // Detach the channel before releasing it
      channel.detach((err) => {
        if (err) {
          console.error(`Failed to detach channel ${channel.name}:`, err);
        } else {
          ably.channels.release(channel.name); // Release the channel
        }
      });
    };
  }, [ably]);

  useEffect(() => {
    fetchTasks();
  }, []);

  const TaskTime = ({ task, column }) => {
    const getTime = () =>
      moment(
        column === "notStarted"
          ? task.informed_time
          : column === "started"
          ? task.scan_time
          : task.finish_time
      ).fromNow();

    const [relativeTime, setRelativeTime] = useState(getTime());

    useEffect(() => {
      const interval = setInterval(() => {
        setRelativeTime(getTime());
      }, 60000); // Updates every 60 seconds

      return () => clearInterval(interval); // Cleanup interval on unmount
    }, [task, column]); // Runs whenever task or column changes

    return (
      <Typography variant="caption" sx={{ color: "#555" }}>
        {relativeTime}
      </Typography>
    );
  };

  const TaskCard = ({ task, index, column }) => (
    <Draggable
      key={task.nontourdata_id}
      draggableId={String(task.nontourdata_id)}
      index={index}
    >
      {(provided) => (
        <Card
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{
            mb: 2,
            p: 2,
            backgroundColor: "#fff",
            boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
            position: "relative",
          }}
          onClick={() => {
            if (column !== "completed") {
              setSelectedTaskId(task.nontourdata_id);
              setEditActivityDialog(true);
            }
          }}
        >
          {column === "notStarted" && (
            <IconButton
              sx={{
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
              onClick={(event) => {
                event.stopPropagation();
                deleteTask(task.nontourdata_id);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
          <CardContent>
            <Typography variant="h6">{task.task_name}</Typography>
            <Typography variant="body2" color="textSecondary">
              Location: {task.task_location || "N/A"}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Comment: {task.comment || "N/A"}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              {task.urgent && (
                <Tooltip title="Urgent">
                  <WhatshotIcon fontSize="small" color="error" />
                </Tooltip>
              )}
              {task.problem && (
                <Tooltip title="Problem">
                  <ErrorIcon fontSize="small" color="warning" sx={{ ml: 1 }} />
                </Tooltip>
              )}
              {task.question && (
                <Tooltip title="Question">
                  <HelpIcon fontSize="small" color="primary" sx={{ ml: 1 }} />
                </Tooltip>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: 1,
              }}
            >
              <TaskTime task={task} column={column} />
              <Avatar
                sx={{ width: 24, height: 24 }}
                src={task.profile_image || "/static/images/avatar/1.jpg"}
                alt={task.username}
              />
            </Box>
          </CardContent>
        </Card>
      )}
    </Draggable>
  );

  return (
    <>
      <Dialog
        open={nonTourTaskDialog}
        onClose={() => setNonTourTaskDialog(false)}
        maxWidth="lg"
        PaperProps={{
          sx: {
            margin: "2rem auto",
            width: "80%",
            maxWidth: "1200px",
            height: "80vh",
            borderRadius: 2,
            overflow: "hidden", // Prevent dialog scrolling
          },
        }}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h4">Non-Tour Task Board</Typography>
          <IconButton onClick={() => setNonTourTaskDialog(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: 0, position: "relative" }}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: 2,
                height: "calc(100% - 70px)", // Adjust for header height
                px: 2,
                pb: 2,
              }}
            >
              {["notStarted", "started", "completed"].map((column) => (
                <Box
                  key={column}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    overflowY: "auto", // Allows individual column scrolling
                    background: "rgba(242, 242, 242, 0.8)",
                    p: 2,
                    position: "relative",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      p: 2,
                      background: "aliceblue",
                      borderRadius: "10px",
                      mb: 2,
                    }}
                  >
                    {column === "notStarted"
                      ? `Not Started (${tasks[column].length})`
                      : column === "started"
                      ? `Started (${tasks[column].length})`
                      : `Completed (${tasks[column].length})`}
                  </Typography>
                  <Droppable droppableId={column}>
                    {(provided) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        sx={{ flex: 1 }}
                      >
                        {tasks[column].map((task, index) => (
                          <TaskCard
                            key={task.nontourdata_id}
                            task={task}
                            index={index}
                            column={column}
                          />
                        ))}
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                </Box>
              ))}
            </Box>
          </DragDropContext>
          <Box
            sx={{
              position: "absolute",
              bottom: "10px",
              left: "10px",
              width: "40px",
              height: "40px",
              bgcolor: "#444",
              color: "#fff",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
              transition: "transform 0.1s ease-in-out",
              "&:hover": {
                transform: "scale(1.07)",
              },
            }}
            onClick={() => setAddActivityDialog(true)}
          >
            <AddIcon sx={{ fontSize: "18px" }} />
          </Box>
          {loader && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1000,
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </DialogContent>
      </Dialog>
      <AddEditActivityDialog
        dialogOpen={addActivityDialog}
        setDialogOpen={setAddActivityDialog}
        fetchTasks={fetchTasks}
      />
      <AddEditActivityDialog
        dialogOpen={editActivityDialog}
        setDialogOpen={setEditActivityDialog}
        fetchTasks={fetchTasks}
        taskId={selectedTaskId}
        isEdit={true}
      />
      {taskTimeIssueDialog.open && (
        <TaskTimeIssueDialog
          open={taskTimeIssueDialog.open}
          onClose={() => {
            setTaskTimeIssueDialog({ open: false, resolve: null });
          }}
          onConfirm={(time) => {
            taskTimeIssueDialog.resolve(time); // Resolve the promise with the selected time
            setTaskTimeIssueDialog({ open: false, resolve: null });
          }}
        />
      )}
    </>
  );
};

export default NonTourTaskDialog;

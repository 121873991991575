import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../../redux/slices/mainSlice";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import * as Yup from "yup";

// Replace with your API calls
import {
  AddNewTask,
  TaskActivityList,
  TaskUserList,
  FetchNonTourData,
} from "../../../api";
import { useDispatch } from "react-redux";

const AddEditActivityDialog = ({
  dialogOpen,
  setDialogOpen,
  fetchTasks,
  taskId = null, // Optional taskId for editing
  isEdit = false, // Flag to differentiate between add and edit
}) => {
  const dispatch = useDispatch();
  const [activityList, setActivityList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    activity: "",
    officer: "",
    location: "",
    informant: "",
    comment: "",
    scanTime: "",
  });

  // Fetch activity and user lists
  const fetchLists = async () => {
    try {
      const [activityResponse, userResponse] = await Promise.all([
        TaskActivityList(),
        TaskUserList(),
      ]);

      setActivityList(activityResponse?.data || []);
      setUserList(userResponse?.data || []);
    } catch (error) {
      console.error("Error fetching lists:", error);
      setActivityList([]);
      setUserList([]);
    }
  };

  // Fetch task data for editing
  const fetchTaskData = async () => {
    if (taskId && isEdit) {
      try {
        setLoading(true);
        const response = await FetchNonTourData({ non_tour_id: taskId });
        const data = response?.data || {};

        // Set initial values for editing
        setInitialValues({
          activity: data.TaskID || "",
          officer: data.UserID || "",
          location: data.task_location || "",
          informant: data.informant || "",
          comment: data.Comment || "",
          scanTime: data.ScanTime || null,
        });
      } catch (error) {
        console.error("Error fetching task data:", error);
        const errorMessage = error?.data?.message || "Something went wrong!";
        dispatch(getMsg(errorMessage));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      } finally {
        setLoading(false);
      }
    }
  };

  // useEffect to fetch lists and task data when dialog opens
  useEffect(() => {
    if (dialogOpen) {
      fetchLists();
      if (isEdit) {
        fetchTaskData();
      }
    }
  }, [dialogOpen]);

  // Validation schema for Formik
  const validationSchema = Yup.object({
    activity: Yup.string().required("Activity is required"),
    officer: Yup.string().required("Officer is required"),
    location: Yup.string().required("Location is required"),
    informant: Yup.string(),
    comment: Yup.string(),
  });

  const handleSave = async (values, { resetForm }) => {
    setLoading(true);
    try {
      let payload = {
        Nontourdata: {
          TaskID: values?.activity,
          UserID: values?.officer,
          location: values?.location,
          informant: values?.informant,
          Comment: values?.comment,
          start_time: 0,
          ScanTime: values?.scanTime,
        },
      };
      if (isEdit) {
        payload.Nontourdata = { ...payload.Nontourdata, id: taskId };
      }
      await AddNewTask(payload);
      dispatch(getMsg("Task Added Successfully!"));
      dispatch(getSnackAlert("success"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
      resetForm();
      setDialogOpen(false);
    } catch (error) {
      console.error("Error saving activity:", error);
      dispatch(getMsg("Something went wrong!"));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
    }
    setLoading(false);
  };

  return (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      fullWidth
      maxWidth="sm"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            borderRadius: 2,
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5">
          {isEdit ? "Edit Activity" : "Add Activity"}
        </Typography>
        <IconButton onClick={() => setDialogOpen(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Formik
        enableReinitialize // Enable updating initial values for editing
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSave}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          touched,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={3}>
                {/* Activity Dropdown */}
                <Grid item xs={12} md={6}>
                  <FormControl
                    fullWidth
                    error={touched.activity && Boolean(errors.activity)}
                  >
                    <InputLabel id="activity-select-label">Activity</InputLabel>
                    <Select
                      labelId="activity-select-label"
                      name="activity"
                      label="Activity"
                      value={values.activity}
                      onChange={(e) =>
                        setFieldValue("activity", e.target.value)
                      }
                      displayEmpty
                    >
                      {activityList.map((activity) => (
                        <MenuItem key={activity.id} value={activity.id}>
                          {activity.text}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.activity && errors.activity && (
                      <Typography
                        variant="caption"
                        color="error"
                        sx={{ marginTop: 0.5 }}
                      >
                        {errors.activity}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>

                {/* Officer Dropdown */}
                <Grid item xs={12} md={6}>
                  <FormControl
                    fullWidth
                    error={touched.officer && Boolean(errors.officer)}
                  >
                    <InputLabel id="officer-select-label">Officer</InputLabel>
                    <Select
                      labelId="officer-select-label"
                      name="officer"
                      label="Officer"
                      value={values.officer}
                      onChange={(e) => setFieldValue("officer", e.target.value)}
                      displayEmpty
                    >
                      {userList.map((officer) => (
                        <MenuItem key={officer.id} value={officer.id}>
                          {officer.text}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.officer && errors.officer && (
                      <Typography
                        variant="caption"
                        color="error"
                        sx={{ marginTop: 0.5 }}
                      >
                        {errors.officer}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>

                {/* Location Field */}
                <Grid item xs={12} md={6}>
                  <TextField
                    name="location"
                    label="Location *"
                    fullWidth
                    value={values.location}
                    onChange={handleChange}
                    error={touched.location && Boolean(errors.location)}
                    helperText={touched.location && errors.location}
                  />
                </Grid>

                {/* Informant Field */}
                <Grid item xs={12} md={6}>
                  <TextField
                    name="informant"
                    label="Informant"
                    fullWidth
                    value={values.informant}
                    onChange={handleChange}
                    error={touched.informant && Boolean(errors.informant)}
                    helperText={touched.informant && errors.informant}
                  />
                </Grid>

                {/* Comment Field */}
                <Grid item xs={12}>
                  <TextField
                    name="comment"
                    label="Comment"
                    fullWidth
                    multiline
                    rows={3}
                    value={values.comment}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setDialogOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Save"}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddEditActivityDialog;

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import CustomTextField from "../../components/form-components/CustomTextField";
import CustomSelect from "../../components/form-components/CustomSelect";
import {
  AddUpdateInspectionQuestion,
  InspectionAreaDropdown,
  InspectionCategoryDropdown,
} from "../../api";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../redux/slices/mainSlice";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Typography,
  Grid,
  FormControl as MuiFormControl,
  RadioGroup,
  Radio,
  FormLabel,
  FormControlLabel,
  ListItemText,
  ListItem,
  List,
  FormHelperText,
  Paper,
  CircularProgress,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { handleApiResonseErrors } from "../../helper/helper";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const inspectionArea = [
  { key: "Impaired hearing", value: "hearing" },
  { key: "Impaired mobility", value: "mobility" },
  { key: "Impaired vision", value: "vision" },
  { key: "VIP", value: "vip" },
  { key: "Other", value: "other" },
];
const category = [
  { key: "Impaired hearing", value: "hearing" },
  { key: "Impaired mobility", value: "mobility" },
  { key: "Impaired vision", value: "vision" },
  { key: "VIP", value: "vip" },
  { key: "Other", value: "other" },
];
// Classes Styling
const useStyles = makeStyles((theme) => ({
  BulletList: {
    display: "block",
    padding: "15px 15px",
    "& .MuiListItem-root": {
      padding: "10px 0 0",
      listStyle: "disc",
      display: "list-item",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const EditInspectionQuestion = ({
  editInspectionQuestionDialog,
  editInspectionQuestionDialogClose,
  inspectionQuestionList,
  singleData,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [inspectionArea, setInspectionArea] = useState([]);
  const inspectionAreaDropdown = () => {
    setLoading(true);
    InspectionAreaDropdown()
      .then((res) => {
        if (res.statusCode === 200) {
          setInspectionArea(res.data);
        } else {
          setInspectionArea([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const [inspectionCategory, setInspectionCategory] = useState([]);
  const inspectionCategoryDropdown = () => {
    setLoading(true);
    InspectionCategoryDropdown()
      .then((res) => {
        if (res.statusCode === 200) {
          setInspectionCategory(res.data);
        } else {
          setInspectionCategory([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    inspectionAreaDropdown();
    inspectionCategoryDropdown();
  }, []);

  const {
    id,
    inspection_id,
    inspection_category,
    question,
    option_1_title,
    option_2_title,
    option_3_title,
    option_1_score,
    option_2_score,
    option_3_score,
    option_1_severity,
    option_2_severity,
    option_3_severity,
    is_mandatory,
  } = singleData;
  let initialValues = {
    inspection_area: inspection_id ? inspection_id : "",
    category: inspection_category ? inspection_category : "",
    question: question ? question : "",
    labela: option_1_title ? option_1_title : "",
    scorea: option_1_score ? option_1_score : "",
    severitya: option_1_severity ? option_1_severity : "",
    labelb: option_2_title ? option_2_title : "",
    scoreb: option_2_score ? option_2_score : "",
    severityb: option_2_severity ? option_2_severity : "",
    labelc: option_3_title ? option_3_title : "",
    scorec: option_3_score ? option_3_score : "",
    severityc: option_3_severity ? option_3_severity : "",
    response_is_required: is_mandatory ? is_mandatory : "Y",
  };

  const validationSchema = Yup.object().shape({
    inspection_area: Yup.string().required("Please select the inspection area"),
    category: Yup.string().required("Please select the category"),
    question: Yup.string().required("Please enter question"),
    labela: Yup.string().required("Please enter the label a"),
    scorea: Yup.number().min(-5).max(5).required("Please enter the score a"),
    severitya: Yup.number()
      .min(0)
      .max(10)
      .required("Please enter the severtiy a"),
    labelb: Yup.string().required("Please enter the label b"),
    scoreb: Yup.number().min(-5).max(5).required("Please enter the score b"),
    severityb: Yup.number()
      .min(0)
      .max(10)
      .required("Please enter the severtiy b"),
    labelc: Yup.string().required("Please enter the label c"),
    scorec: Yup.number().min(-5).max(5).required("Please enter the score c"),
    severityc: Yup.number()
      .min(0)
      .max(10)
      .required("Please enter the severtiy c"),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let payload = {
        id: id,
        inspection_id: values.inspection_area,
        inspection_category: values.category,
        question: values.question,
        label_a: values.labela,
        label_b: values.labelb,
        label_c: values.labelc,
        score_a: values.scorea,
        score_b: values.scoreb,
        score_c: values.scorec,
        severity_a: values.severitya,
        severity_b: values.severityb,
        severity_c: values.severityc,
        is_mandatory: values.response_is_required,
      };
      AddUpdateInspectionQuestion(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            setLoading(false);
            editInspectionQuestionDialogClose();
            inspectionQuestionList();
            dispatch(getMsg("Record saved successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            setLoading(false);
            dispatch(getMsg("Something went wrong!"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {
          setLoading(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Dialog
        aria-labelledby="inspection-question-title"
        aria-describedby="inspection-question-desc"
        open={editInspectionQuestionDialog}
        onClose={editInspectionQuestionDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="inspection-question-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            {Object.keys(singleData).length > 0 ? "Edit" : "Add"} Inspection
            Question
          </Typography>
          <DialogActions>
            <IconButton
              onClick={editInspectionQuestionDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              setFieldValue,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={6}>
                      <CustomSelect
                        name="inspection_area"
                        fieldLabel="Inspection area*"
                        options={inspectionArea}
                        displayEmpty
                      />
                      <FormHelperText error>
                        {touched.inspection_area && errors.inspection_area}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={6}>
                      <CustomSelect
                        name="category"
                        fieldLabel="Category*"
                        options={inspectionCategory}
                        displayEmpty
                      />
                      <FormHelperText error>
                        {touched.category && errors.category}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <CustomTextField
                        name="question"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Question*"
                        autoComplete="off"
                        placeholder="i.e. Floors clean and dry"
                        multiline={true}
                        rows={3}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography variant="h5" component="div" gutterBottom>
                      Instructions:
                    </Typography>
                    <List className={classes.BulletList}>
                      <ListItem>
                        <ListItemText primary="Choose option labels for the question reponses such as yes, no, or n/a. You can use any labels you want." />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Choose a score from -5 to 5 to coincide with each label. These will be used to calculate the total score." />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Choose the sevarity from the responses 0 to 10. For example, a 'no' response for a question like 'Floors were dry' might be less severe than a 'no' response to a question 'Cash room is secure'. A higher severity number represents a more significant response (positive or negative)." />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <CustomTextField
                        name="labela"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Label a*"
                        autoComplete="off"
                        placeholder="Positive label like yes"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <CustomTextField
                        name="scorea"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Score a*"
                        autoComplete="off"
                        placeholder="Positive or negative -5 to 5"
                        type="number"
                        InputProps={{ inputProps: { min: -5, max: 5 } }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <CustomTextField
                        name="severitya"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Severity a*"
                        autoComplete="off"
                        placeholder="Range 0 to 10"
                        type="number"
                        InputProps={{ inputProps: { min: 0, max: 10 } }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <CustomTextField
                        name="labelb"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Label b*"
                        autoComplete="off"
                        placeholder="Positive label like yes"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <CustomTextField
                        name="scoreb"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Score b*"
                        autoComplete="off"
                        placeholder="Positive or negative -5 to 5"
                        type="number"
                        InputProps={{ inputProps: { min: -5, max: 5 } }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <CustomTextField
                        name="severityb"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Severity b*"
                        autoComplete="off"
                        placeholder="Range 0 to 10"
                        type="number"
                        InputProps={{ inputProps: { min: 0, max: 10 } }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <CustomTextField
                        name="labelc"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Label c*"
                        autoComplete="off"
                        placeholder="Positive label like yes"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <CustomTextField
                        name="scorec"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Score c*"
                        autoComplete="off"
                        placeholder="Positive or negative -5 to 5"
                        type="number"
                        InputProps={{ inputProps: { min: -5, max: 5 } }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <CustomTextField
                        name="severityc"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Severity c*"
                        autoComplete="off"
                        placeholder="Range 0 to 10"
                        type="number"
                        InputProps={{ inputProps: { min: 0, max: 10 } }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <FormLabel id="response-is-required">
                        Response is required?
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="response-is-required"
                        name="response_is_required"
                        value={values.response_is_required}
                        onChange={(e) =>
                          setFieldValue("response_is_required", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="Y"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="N"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={editInspectionQuestionDialogClose}
                    sx={{ marginRight: "5px" }}
                  >
                    Cancel
                  </Button>
                  <Button color="primary" variant="contained" type="submit">
                    Save
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default EditInspectionQuestion;

import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { CircularProgress } from "@mui/material";
import { GetAcitivityChartData } from "../../../api";
import moment from "moment";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";

const ActivitiesChart = ({ setFilterOptions }) => {
  const chartRef = useRef(null); // Reference to Highcharts instance
  const sliderRef = useRef(null); // Reference to Slider for width adjustment
  const [chartOptions, setChartOptions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sliderValues, setSliderValues] = useState([0, 4]); // Default slider range
  const [xAxisData, setXAxisData] = useState([]); // Store X-axis categories
  const [sliderWidth, setSliderWidth] = useState("100%"); // Sync width with chart

  // Initialize the date range (last 3 days from today)
  const [dateRange, setDateRange] = useState({
    start: moment().subtract(3, "days").format("MM/DD/YYYY"),
    end: moment().subtract(1, "days").format("MM/DD/YYYY"),
  });

  const fetchChartData = async () => {
    try {
      setLoading(true);
      setError(null);

      // Prepare payload for API
      const payload = {
        date_range: `${dateRange.start} - ${dateRange.end}`,
      };

      // Fetch data from API
      const result = await GetAcitivityChartData(payload);

      if (result.statusCode === 200 && result.status === "success") {
        const { categories, chartdata } = result.data;

        console.log("data:", result.data);

        // Check for empty data
        if (
          !categories ||
          categories.length === 0 ||
          !chartdata ||
          chartdata.length === 0
        ) {
          setChartOptions({
            chart: {
              type: "line",
              backgroundColor: "#0b0c10",
              events: {
                load: function () {
                  this.renderer
                    .text(
                      "No Data Available for Selected Range",
                      this.chartWidth / 2,
                      this.chartHeight / 2 - 20
                    )
                    .css({
                      color: "#ffffff",
                      fontSize: "16px",
                      fontWeight: "bold",
                      textAlign: "center",
                    })
                    .attr({
                      align: "center",
                    })
                    .add();

                  this.renderer
                    .text(
                      "Please select a different date range",
                      this.chartWidth / 2,
                      this.chartHeight / 2 + 10
                    )
                    .css({
                      color: "#cccccc",
                      fontSize: "12px",
                      textAlign: "center",
                    })
                    .attr({
                      align: "center",
                    })
                    .add();
                },
              },
            },
            title: {
              text: "",
            },
            xAxis: {
              visible: false,
            },
            yAxis: {
              visible: false,
            },
            series: [],
          });
          return;
        }

        // Prepare categories and plotBands
        const xAxisCategories = [];
        const plotBands = [];
        let currentStart = 0;

        setXAxisData((prevData) => [
          ...categories.flatMap((category) =>
            category.categories.map((hour) => ({
              date: category.name,
              time: hour,
            }))
          ),
        ]);

        categories.forEach((category, index) => {
          category.categories.forEach((hour) => {
            xAxisCategories.push(`${hour}`); // Push hours for X-axis labels
          });

          // Add plotBands for date sections
          plotBands.push({
            color:
              index % 2 === 0 ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0)", // Alternate shading
            from: currentStart,
            to: currentStart + category.categories.length,
            label: {
              text: category.name,
              align: "center",
              style: {
                color: "#ffffff",
                fontSize: "10px",
              },
              y: 20, // Adjust Y-position of the date label
            },
          });

          currentStart += category.categories.length; // Update start index for next section
        });

        // Update chart options
        setChartOptions({
          chart: {
            type: "line",
            backgroundColor: "#0b0c10",
          },
          title: {
            text: "Activity Over Time",
            style: {
              color: "#ffffff",
            },
          },
          xAxis: {
            categories: xAxisCategories,
            plotBands: plotBands,
            labels: {
              style: {
                color: "#ffffff",
                fontStyle: "normal",
                fontSize: "12px",
              },
            },
          },
          yAxis: {
            title: {
              text: "Count",
              style: {
                color: "#ffffff",
              },
            },
            labels: {
              style: {
                color: "#ffffff",
              },
            },
          },
          series: [
            {
              name: "Activity",
              data: chartdata,
              color: "#00ff00", // Line color
            },
          ],
          legend: {
            itemStyle: {
              color: "#ffffff",
            },
          },
        });
      } else {
        throw new Error(result.message || "Failed to fetch data.");
      }
    } catch (err) {
      setError(err.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChartData();
    window.addEventListener("resize", adjustSliderWidth);
    return () => window.removeEventListener("resize", adjustSliderWidth);
  }, [dateRange]);

  useEffect(() => {
    adjustSliderWidth();
  }, [chartOptions]);

  const adjustSliderWidth = () => {
    if (chartRef.current && sliderRef.current) {
      setSliderWidth(chartRef.current.chart.chartWidth + "px");
    }
  };

  const handleSliderChange = (newValues) => {
    setSliderValues(newValues);
    setFilterOptions({
      select_data: getSliderValuesLeft,
    });
  };

  useEffect(() => {
    if (xAxisData.length) {
      setFilterOptions({
        select_date: getSliderValuesLeft().date,
        time_from: `${getSliderValuesLeft().time}:00`,
        time_to: `${getSliderValuesRight().time}:00`,
        dateTo: getSliderValuesRight().date,
      });
    }
  }, [sliderValues]);

  const getSliderValuesLeft = (value) => {
    const data = xAxisData[sliderValues[0]] || {};
    return data;
  };
  const getSliderValuesRight = (value) => {
    const data = xAxisData[sliderValues[1]] || {};
    return data;
  };

  // Handlers for shifting date range
  const handleShiftLeft = () => {
    setDateRange((prev) => ({
      start: moment(prev.start, "MM/DD/YYYY")
        .subtract(3, "days")
        .format("MM/DD/YYYY"),
      end: moment(prev.end, "MM/DD/YYYY")
        .subtract(3, "days")
        .format("MM/DD/YYYY"),
    }));
  };

  const handleShiftRight = () => {
    setDateRange((prev) => ({
      start: moment(prev.start, "MM/DD/YYYY")
        .add(3, "days")
        .format("MM/DD/YYYY"),
      end: moment(prev.end, "MM/DD/YYYY").add(3, "days").format("MM/DD/YYYY"),
    }));
  };

  // Show loader or error message
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ color: "red", textAlign: "center", marginTop: "20px" }}>
        Error: {error}
      </div>
    );
  }

  return (
    <div style={{ position: "relative", height: "100%" }}>
      <div style={{ position: "absolute", top: 10, right: 10, zIndex: 10 }}>
        <button
          onClick={handleShiftLeft}
          style={{
            backgroundColor: "#0b0c10",
            color: "#ffffff",
            border: "1px solid #ffffff",
            padding: "5px 10px",
            marginRight: "10px",
            cursor: "pointer",
          }}
        >
          ←
        </button>
        <button
          onClick={handleShiftRight}
          style={{
            backgroundColor: "#0b0c10",
            color: "#ffffff",
            border: "1px solid #ffffff",
            padding: "5px 10px",
            cursor: "pointer",
          }}
        >
          →
        </button>
      </div>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        style={{
          height: "100%", // Ensure Highcharts fits the container
          width: "100%", // Make sure chart occupies full width
        }}
      />

      {/* Slider Controls */}
      {xAxisData.length != 0 && (
        <div
          style={{
            paddingTop: "20px",
            paddingBottom: "20px",
            textAlign: "center",
          }}
        >
          <div
            ref={sliderRef}
            style={{
              width: sliderWidth,
              margin: "auto",
              paddingLeft: 50,
              paddingRight: 10,
              position: "relative",
            }}
          >
            <RangeSlider
              min={0}
              max={xAxisData.length - 1}
              step={1}
              value={sliderValues}
              onInput={handleSliderChange}
              style={{ width: "100%" }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ActivitiesChart;

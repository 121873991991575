import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import axios from "axios";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import WithPermissionFallback from "../../utils/withPermissionFallback";
import {
  GetTaskList,
  TaskColumnList,
  CopyTask,
  UserPropertyList,
} from "../../api";
import {
  Checkbox,
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper as MuiPaper,
  Table,
  TableBody,
  /* FormGroup, */
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  /* Tooltip, */
  Typography,
  InputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Radio,
  CardContent,
  FormLabel,
  RadioGroup,
  Card,
  Box,
  Button,
  /* List,
  ListItem, */
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  CircularProgress,
  Snackbar,
  Alert,
  Pagination,
} from "@mui/material";
import { DateTimePicker, DatePicker } from "@mui/lab";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  Create as CreateIcon,
  Search as SearchIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Cached as CachedIcon,
  Portrait as PortraitIcon,
  ExpandMore as ExpandMoreIcon,
  LockOpen as LockOpenIcon,
  Cancel as CancelIcon,
  FileCopy as FileCopyIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { tableCellClasses } from "@mui/material/TableCell";
import TableRows from "./TableRows";
import { useDispatch, useSelector } from "react-redux"; //Imports related to redux
import {
  getChecked,
  getSnackOpen,
  getSnackClose,
  getMsg,
} from "../../redux/slices/taskSlice"; //import related to redux store.
import WithPermissionHide from "../../utils/withPermissionHide";
/* const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing); */

// Swtich
const label = { inputProps: { "aria-label": "Switches" } };
const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledRadio = styled((props) => <RadioGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      flexDirection: "row",
      flexWrap: "nowrap",
    },
  })
);
const ShowInactiveLabel = styled((props) => <FormLabel {...props} />)(
  ({ theme }) => ({
    "& .MuiFormLabel-root": {
      whiteSpace: "nowrap",
      marginRight: "10px",
      fontWeight: "500",
    },
  })
);
const StyledSwitchControl = styled((props) => <FormGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormControlLabel-root": {
      position: "relative",
      marginLeft: "0px",
      marginRight: "0px",
      paddingRight: "60px",
      paddingTop: "8px",
      paddingBottom: "8px",
      "& .MuiSwitch-root": {
        position: "absolute",
        right: "-10px",
        top: "0px",
      },
    },
  })
);
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
                whiteSpace: "initial",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "435px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
const rows = [];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}
const headCells = [
  {
    id: "TaskDescription",
    numeric: true,
    disablePadding: false,
    label: "Task Name",
  },
  { id: "active", numeric: true, disablePadding: false, label: "Active" },
  {
    id: "critical_task",
    numeric: true,
    disablePadding: false,
    label: "Critical",
  },
  { id: "passdown", numeric: true, disablePadding: false, label: "Passdown" },
  {
    id: "comment",
    numeric: true,
    disablePadding: false,
    label: "Comment",
  },
  { id: "web", numeric: true, disablePadding: false, label: "Web" },
  {
    id: "team",
    numeric: true,
    disablePadding: false,
    label: "Team",
  },
  {
    id: "shift",
    numeric: true,
    disablePadding: false,
    label: "Shift",
  },
  {
    id: "sentiment",
    numeric: true,
    disablePadding: false,
    label: "Sentiment",
  },
  {
    id: "asn",
    numeric: true,
    disablePadding: false,
    label: "Asn",
  },
  {
    id: "sch",
    numeric: true,
    disablePadding: false,
    label: "Sch",
  },
  {
    id: "btn",
    numeric: true,
    disablePadding: false,
    label: "Btn",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];
const EnhancedTableHead = (props) => {
  const [headColumn, setHeadColumn] = useState([]);
  const checkedValue = useSelector((state) => state.task.checked);
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    colArray,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  //  Function for preselected options in site.
  const headResult = () => {
    let result = [];
    checkedValue.forEach((obj) => {
      colArray.forEach((result3) => {
        if (result3.key == obj) {
          result.push(result3);
        }
      });
    });
    result.sort((a, b) => {
      return a.sort_id - b.sort_id;
    });
    setHeadColumn(result);
  };

  useEffect(() => {
    headResult();
  }, [checkedValue, colArray]);

  return (
    <TableHead>
      <TableRow>
        {headColumn.map((headCell) => (
          <TableCell
            key={headCell.key}
            align="left"
            padding="normal"
            sx={{ whiteSpace: "nowrap" }}
          >
            <TableSortLabel
              direction={orderBy === headCell.key ? order : "asc"}
              active={
                headCell.key === "TaskDescription"
                  ? orderBy === headCell.key
                  : false
              }
              onClick={
                headCell.key === "TaskDescription"
                  ? createSortHandler(headCell.key)
                  : null
              }
              hideSortIcon={headCell.key === "TaskDescription" ? false : true}
            >
              {headCell.value}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="left">Action</TableCell>
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const {
    numSelected,
    totalRecord,
    searchInput,
    setSearchInput,
    setInactive,
    inactive,
    colArray,
    getColumnList,
    getColumnChecked,
    handleFirstPage,
  } = props;
  const [actions, setActions] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [radioValue, setRadioValue] = useState("");
  const [propertyList, setPropertyList] = useState([]);
  const checkedValue = useSelector((state) => state.task.checked);
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // show checked only switch toggle
  const [state, setState] = React.useState({
    switchone: true,
  });
  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };
  const enterPress = () => {
    setSearchInput(searchValue);
    handleFirstPage();
  };
  //Scan Visitor ID modal
  const [copytasks, copyTaskModal] = React.useState(false);
  const copyTaskOpen = () => {
    copyTaskModal(true);
  };
  const copyTaskClose = () => {
    copyTaskModal(false);
    setLocation("");
  };
  //Select Location
  const [location, setLocation] = useState("");
  const selectLocation = (event) => {
    setLocation(event.target.value);
  };
  const handleCopyTask = () => {
    CopyTask({ select_location: location })
      .then((response) => {
        if (response.statusCode === 200) {
          handleClose();
          dispatch(getSnackOpen());
          dispatch(getMsg("Tasks have been copied successfully! "));
        } else {
          dispatch(getSnackClose());
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(getSnackClose());
      });
  };
  const property = () => {
    UserPropertyList()
      .then((res) => {
        if (res.statusCode === 200) {
          setPropertyList(res.data);
        } else {
          setPropertyList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const searchHandleClick = () => {
  //   setSearchInput("");
  // };

  //Handler for checked unchecked
  const handleChecked = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      dispatch(getChecked([...checkedValue, value]));
    } else {
      dispatch(getChecked(checkedValue.filter((e) => e !== value)));
    }
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickAway = () => {
    setIsDropdownOpen(false);
  };
  return (
    <Toolbar>
      <div>
        <Paper mt={3}></Paper>
      </div>
      <div>
        {totalRecord > 0 ? (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content" }}
          >
            {totalRecord} records found
          </Typography>
        ) : (
          <Typography
            variant="subtitle1"
            mt={3}
            id="tableTitle"
            style={{ width: "max-content" }}
          >
            {totalRecord} record found
          </Typography>
        )}
      </div>
      <Spacer />
      <div className="searchTool">
        <Box
          mt={3}
          sx={{
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <Item sx={{ flexGrow: 1, width: "472px", position: "relative" }}>
            <TextField
              placeholder="Search"
              type="text"
              variant="outlined"
              fullWidth
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              InputProps={{
                endAdornment: (
                  <>
                    {searchValue && (
                      <IconButton
                        aria-label="clear search"
                        onClick={() => {
                          setSearchValue("");
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    )}
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle dropdown"
                        onClick={handleDropdownClick}
                      >
                        <ArrowDropDownIcon />
                      </IconButton>
                    </InputAdornment>
                  </>
                ),
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  enterPress();
                }
              }}
            />
            {isDropdownOpen && (
              <Box
                sx={{
                  position: "absolute",
                  top: "100%",
                  left: 0,
                  width: "100%", // Same width as the TextField
                  backgroundColor: "white",
                  boxShadow: 1,
                  borderRadius: 1,
                  zIndex: 10,
                  padding: "10px",
                  border: "1px solid #dddddd",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
                    Assignable?(Select first for more information)
                  </Typography>
                  <StyledRadio>
                    <RadioGroup
                      aria-labelledby="showInactive"
                      defaultValue="No"
                      onChange={(e) => setInactive(e.target.value)}
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="Yes"
                        control={
                          <Radio
                            sx={{
                              width: "31px !important",
                              height: "33px !important",
                            }}
                          />
                        }
                        label="Yes"
                        sx={{
                          marginLeft: "0px !important",
                          marginRight: "3px !important",
                        }}
                      />
                      <FormControlLabel
                        value="No"
                        control={
                          <Radio
                            sx={{
                              width: "31px !important",
                              height: "33px !important",
                            }}
                          />
                        }
                        label="No"
                        sx={{ marginLeft: "0px !important" }}
                      />
                    </RadioGroup>
                  </StyledRadio>
                </Box>
                {/* yes */}
                {inactive === "Yes" && (
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                  >
                    <Typography variant="body2" sx={{ whiteSpace: "npwrap" }}>
                      Delay time &#60; 2 minutes?
                    </Typography>
                    <StyledRadio>
                      <RadioGroup
                        aria-labelledby="minutes"
                        defaultValue="No"
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value="Yes"
                          control={
                            <Radio
                              sx={{
                                width: "31px !important",
                                height: "33px !important",
                              }}
                            />
                          }
                          label="Yes"
                          sx={{
                            marginLeft: "0px !important",
                            marginRight: "3px !important",
                          }}
                        />
                        <FormControlLabel
                          value="No"
                          control={
                            <Radio
                              sx={{
                                width: "31px !important",
                                height: "33px !important",
                              }}
                            />
                          }
                          label="No"
                          sx={{ marginLeft: "0px !important" }}
                        />
                      </RadioGroup>
                    </StyledRadio>
                  </Box>
                )}
                {inactive === "No" && (
                  <Box>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <Checkbox
                        sx={{
                          width: "36px !important",
                          height: "33px !important",
                        }}
                      />
                      <Typography variant="body2">IR Required?</Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <Checkbox
                        sx={{
                          width: "36px !important",
                          height: "33px !important",
                        }}
                      />
                      <Typography variant="body2">Has Schedule?</Typography>
                    </div>
                    <Typography
                      variant="body2"
                      sx={{ marginBottom: "8px", marginTop: "8px" }}
                    >
                      Custom Notification?
                    </Typography>
                    {["01", "02", "03", "04", "05"].map((item, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <Checkbox
                          sx={{
                            width: "36px !important",
                            height: "33px !important",
                          }}
                        />
                        <Typography variant="body2">{item}</Typography>
                      </div>
                    ))}
                  </Box>
                )}

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Checkbox
                    sx={{ width: "36px !important", height: "33px !important" }}
                  />
                  <ShowInactiveLabel>
                    <FormLabel>Show Inactive ?</FormLabel>
                  </ShowInactiveLabel>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{ height: "38px !important" }}
                  >
                    Filter
                  </Button>
                </Box>
              </Box>
            )}
          </Item>
          <Item>
            <Button
              id="search"
              variant="contained"
              color="primary"
              onClick={() => {
                setSearchInput(searchValue);
                handleFirstPage();
              }}
              sx={{
                borderTopLeftRadius: "0px",
                borderBottomLeftRadius: "0px",
                height: "100%",
                marginLeft: "-3px",
              }}
            >
              <SearchIcon />
            </Button>
          </Item>
        </Box>
      </div>
      <Spacer />

      <div>
        <Paper mt={3}>
          <IconButton
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </IconButton>
          <StyledFilterMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <WithPermissionHide controller="Task" action="addTask">
              <MenuItem
                aria-label="add-task"
                component={NavLink}
                to="/tasks/add-task"
              >
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText size="large" primary="Add Task" pl={0} />
              </MenuItem>
            </WithPermissionHide>
            <WithPermissionHide controller="Task" action="copyTask">
              <MenuItem
                onClick={() => {
                  copyTaskOpen();
                  property();
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <FileCopyIcon />
                </ListItemIcon>
                <ListItemText size="large" primary="Copy Tasks" pl={0} />
              </MenuItem>
            </WithPermissionHide>
            <MenuItem
              aria-owns={anchorColumn ? "columns-dropdown" : undefined}
              aria-haspopup="true"
              onClick={(e) => {
                handleClickColumn(e);
                handleClose();
              }}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Columns" pl={0} />
            </MenuItem>
          </StyledFilterMenu>
          <StyledColumnDropdown
            id="columns-dropdown"
            anchorEl={anchorColumn}
            open={Boolean(anchorColumn)}
            onClose={handleCloseColumn}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {checkedValue.length} out of {colArray.length} visible
                  </Typography>
                  <Box>
                    <FormGroup>
                      {colArray.map((col, index) => {
                        return (
                          <FormControlLabel
                            key={index}
                            control={
                              <Checkbox
                                disableRipple
                                checked={checkedValue.includes(col.key)}
                                onChange={(e) => handleChecked(e)}
                                value={col.key}
                              />
                            }
                            label={col.value}
                          />
                        );
                      })}
                    </FormGroup>
                  </Box>
                  <Toolbar>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        getColumnList();
                        getColumnChecked();
                      }}
                    >
                      Reset
                    </Button>
                    <Spacer />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleCloseColumn}
                    >
                      Cancel
                    </Button>
                  </Toolbar>
                </CardContent>
              </Card>
            </MenuItem>
          </StyledColumnDropdown>
          <Dialog
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "600px",
                },
              },
            }}
            open={copytasks}
            onClose={copyTaskClose}
            aria-labelledby="visitor-modal"
            aria-describedby="visitor-modal"
          >
            <DialogTitle
              id="visitor-modal"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingBottom: "0px",
              }}
            >
              <Typography variant="h4" component="div">
                Copy Tasks
              </Typography>
              <DialogActions sx={{ padding: "0px" }}>
                <IconButton
                  sx={{ marginRight: "-10px" }}
                  onClick={() => copyTaskClose()}
                >
                  <CancelIcon />
                </IconButton>
              </DialogActions>
            </DialogTitle>
            <DialogContent sx={{ padding: "0px" }}>
              <DialogContentText
                id="visitor-modal"
                sx={{ padding: "20px 20px" }}
              >
                <FormLabel>
                  Select the site from the list below to copy task to. Please
                  note, all active tasks for the current location ID will be
                  copied to the new location ID
                </FormLabel>
                <FormControl mt={6} sx={{ width: "100%" }}>
                  <InputLabel id="copy-task">Select location</InputLabel>
                  <Select
                    fullWidth
                    id="location"
                    name="location"
                    label="Select location"
                    labelId="copy-task"
                    onChange={selectLocation}
                    value={location}
                    variant="outlined"
                    displayEmpty
                  >
                    {propertyList.map((data) => {
                      return (
                        <MenuItem value={data.LocationID} key={data.LocationID}>
                          {data.LocationName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ paddingBottom: "30px", paddingRight: "20px" }}>
              <Button
                onClick={copyTaskClose}
                color="primary"
                variant="outlined"
              >
                Close
              </Button>
              <Button
                color="primary"
                sx={{ marginLeft: "10px" }}
                variant="contained"
                onClick={() => {
                  handleCopyTask();
                  copyTaskClose();
                }}
              >
                Copy
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </div>
    </Toolbar>
  );
};
function EnhancedTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("TaskDescription");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [taskList, setTaskList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [inactive, setInactive] = useState("No");
  const [critical, setCritical] = useState(true);
  const [taskId, setTaskId] = useState(null);
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [assign, setAssign] = useState(null);
  const [sortValue, setSortValue] = useState(null);
  const [taskName, setTaskName] = useState(null);
  const [colArray, setColArray] = useState([]); //State for storing total columns in the DB.
  const dispatch = useDispatch(); //variable used for dispatching all the actions to the redux store.
  const checkedValue = useSelector((state) => state.task.checked);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortValue(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = taskList.map((n) => n.task_id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, tname) => {
    const selectedIndex = selected.indexOf(tname);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, tname);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
    setSelected([]);
  };

  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
    setSelected([]);
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
    setPage(0);
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setSelected([]);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (tname) => selected.indexOf(tname) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (event, task_id, assignable, taskName, id) => {
    setAnchorEl(event.currentTarget);
    setTaskId(task_id);
    setId(id);
    setAssign(assignable);
    setTaskName(taskName);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };
  const task = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      task_description: searchInput,
      inactive: inactive ? inactive : "No",
      sorting: {
        order: order,
        by: orderBy,
      },
    };
    GetTaskList(currentPage, payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setLoading(false);
          setTaskList(res.data);
          setCurrentPage(res.meta.current_page);
          setTotalRecord(res.meta.total);
        } else {
          setLoading(false);
          setTaskList([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setTotalRecord(0);
      });
  };

  //API call for showing list of column checkboxes Items
  const getColumnList = () => {
    TaskColumnList()
      .then((res) => {
        // dispatch(getChecked(res.data.data.default_fields));
        setColArray(res.data.all_fields);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getColumnChecked = () => {
    TaskColumnList()
      .then((res) => {
        dispatch(getChecked(res.data.default_fields));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    task();
    getColumnList();
  }, [rowsPerPage, currentPage, searchInput, order, orderBy]);

  useEffect(() => {
    if (checkedValue.length == 0) {
      getColumnChecked();
    }
  });
  useEffect(() => {
    setTimeout(() => {
      dispatch(getSnackClose());
    }, 4000);
  }, []);
  return (
    <div>
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          totalRecord={totalRecord}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          setInactive={setInactive}
          inactive={inactive}
          colArray={colArray}
          getColumnList={getColumnList}
          getColumnChecked={getColumnChecked}
          handleFirstPage={handleFirstPage}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            {loading ? (
              <TableCell colSpan={13} align="center">
                <CircularProgress />
              </TableCell>
            ) : (
              <>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={taskList.length}
                  colArray={colArray}
                />
                <TableBody>
                  {stableSort(taskList, getComparator(order, orderBy)).map(
                    (task) => {
                      const isItemSelected = isSelected(task.task_id);
                      const labelId = `enhanced-table-checkbox-${task.task_id}`;
                      return (
                        <TableRows
                          task={task}
                          isItemSelected={isItemSelected}
                          labelId={labelId}
                          handleClick={handleClick}
                          anchorEl={anchorEl}
                          handleActionClick={handleActionClick}
                          StyledMenu={StyledMenu}
                          handleActionClose={handleActionClose}
                          ListItemText={ListItemText}
                          taskId={taskId}
                          assign={assign}
                          sortValue={sortValue}
                          taskName={taskName}
                          key={task.task_id}
                          id={id}
                        />
                      );
                    }
                  )}
                  {taskList.length === 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 6 : 0) * taskList.length,
                      }}
                    >
                      <TableCell colSpan={13} align="center">
                        No records found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={Math.ceil(totalRecord / rowsPerPage)}
            page={currentPage}
            onChange={handleChangePagination}
            color="primary"
            boundaryCount={3}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <Typography sx={{ marginRight: "10px" }}>
                Rows per page :
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  );
}

function ListTasks() {
  const [snackopen, setSnackopen] = useState(true);
  const [searchParams] = useSearchParams();
  const loaderStatus = useAppSelector((state) => state.counter.loader);
  const SnackState = useSelector((state) => state.task.snackStatus);
  const messageStatus = useSelector((state) => state.task.msg);
  const dispatch = useAppDispatch();
  //handlers for snackbar...
  const handleSnackClick = () => {
    setSnackopen(true);
  };
  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackopen(false);
  };
  return (
    <React.Fragment>
      <Helmet title="Tasks" />
      <WithPermissionFallback controller="Task" action="getTasksList">
        <Typography variant="h3" gutterBottom display="inline">
          Tasks
        </Typography>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <EnhancedTable />
          </Grid>
        </Grid>
        {SnackState && (
          <Snackbar
            open={snackopen}
            autoHideDuration={3000}
            onClose={handleSnackClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Alert
              onClose={handleSnackClose}
              severity="success"
              variant="filled"
              sx={{ maxWidth: 600 }}
            >
              {messageStatus}
            </Alert>
          </Snackbar>
        )}
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default ListTasks;

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { GetUserView } from "../../api";
import DetailedLog from "../reports/inner-components/DetailedLog";
import {
  CardContent,
  Avatar as MuiAvatar,
  Box as MuiBox,
  Chip as MuiChip,
  Paper as MuiPaper,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Table,
  TableBody,
  TableCell,
  Button as MuiButton,
  TableRow,
  CircularProgress,
} from "@mui/material";
import { green, orange } from "@mui/material/colors";
import { spacing } from "@mui/system";
import { tableCellClasses } from "@mui/material/TableCell";
import WithPermissionFallback from "../../utils/withPermissionFallback";
const Card = styled(MuiCard)(spacing);
const Button = styled(MuiButton)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 120px;
  width: 120px;
`;
const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;
const ImageWrapper = styled.div``;
const Image = styled.img`
  width: 100%;
`;
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    font-size: 14px;
    font-weight: 600;
    background-color: ${(props) =>
      props.theme.palette.mode === "dark" ? "#17212f" : "rgb(229,246,253)"};

    color: ${(props) =>
      props.theme.palette.mode === "dark" ? "#fff" : "#646464"};
    position: sticky;
    top: 0;
    padding: 14px 15px;
  }
  &.${tableCellClasses.body} {
    font-size: 12px;
    font-weight: 500;
    padding: 8px 15px;
    color: ${(props) => props.theme.palette.white};
    background-color: ${(props) =>
      props.theme.palette.mode === "dark" ? "#00000045" : "#fafafa"};
  }
`;
const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.completed && green[500]};
  background: ${(props) => props.processing && orange[700]};
  color: ${(props) => props.theme.palette.common.white};
`;
const Box = styled(MuiBox)`
  ${spacing};
  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#00000045" : "#fafafa"};
  padding: 15px;
  border-radius: 10px;
`;
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function UserViewCard({ profile }) {
  return (
    <Paper mt={3} pl={4}>
      <Typography variant="h6" gutterBottom>
        Profile Picture
      </Typography>
      <Typography variant="body2" gutterBottom mt={10}>
        <Avatar
          sx={{ marginLeft: "15px", marginTop: "10px" }}
          alt="Lucy Lavender"
          src={profile.profile_image}
        />
      </Typography>
    </Paper>
  );
}
function UserInfo({ user }) {
  const [modaldetailedlog, setModalDetailedLog] = useState(false);
  const modalDetailedLogOpen = () => {
    setModalDetailedLog(true);
  };
  const modalDetailedLogClose = () => {
    setModalDetailedLog(false);
  };
  return (
    <Paper mt={3}>
      <TableWrapper>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                style={{
                  borderBottom: "none",
                  padding: "6px 0px 6px",
                  fontWeight: 600,
                  width: "155px",
                }}
              >
                First Name:
              </TableCell>
              <TableCell
                style={{ borderBottom: "none", padding: "6px 0px 6px" }}
              >
                {user.first_name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                style={{
                  borderBottom: "none",
                  fontWeight: 600,
                  padding: "6px 0px 6px",
                  width: "155px",
                }}
              >
                Last Name:
              </TableCell>
              <TableCell
                style={{ borderBottom: "none", padding: "6px 0px 6px" }}
              >
                {user.last_name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                style={{
                  borderBottom: "none",
                  fontWeight: 600,
                  padding: "6px 0px 6px",
                  width: "155px",
                }}
              >
                Email:
              </TableCell>
              <TableCell
                style={{ borderBottom: "none", padding: "6px 0px 6px" }}
              >
                {user.email}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                style={{
                  borderBottom: "none",
                  fontWeight: 600,
                  padding: "6px 0px 6px",
                  width: "155px",
                }}
              >
                Phone:
              </TableCell>
              <TableCell
                style={{ borderBottom: "none", padding: "6px 0px 6px" }}
              >
                {user.phone}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                style={{
                  borderBottom: "none",
                  fontWeight: 600,
                  padding: "6px 0px 6px",
                  width: "155px",
                }}
              >
                User Level:
              </TableCell>
              <TableCell
                style={{ borderBottom: "none", padding: "6px 0px 6px" }}
              >
                {user.user_level}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                style={{
                  borderBottom: "none",
                  fontWeight: 600,
                  padding: "5px 0 5px",
                  width: "155px",
                }}
              >
                Assigned Corporate:
              </TableCell>
              <TableCell
                style={{ borderBottom: "none", padding: "6px 0px 6px" }}
              >
                {user.assigned_corporate}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                style={{
                  borderBottom: "none",
                  fontWeight: 600,
                  padding: "6px 0px 6px",
                  width: "155px",
                }}
              >
                Properties:
              </TableCell>
              <TableCell
                style={{ borderBottom: "none", padding: "6px 0px 6px" }}
              >
                {user.properties}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableWrapper>
      <Button
        variant="contained"
        color="primary"
        component="span"
        sx={{ cursor: "pointer" }}
        mt={6}
        onClick={modalDetailedLogOpen}
      >
        Activity Log
      </Button>
      {/* User Activity Dialog */}
      {modaldetailedlog && (
        <DetailedLog
          modalDetailedLogOpen={modalDetailedLogOpen}
          modalDetailedLogClose={modalDetailedLogClose}
          modaldetailedlog={modaldetailedlog}
          setModalDetailedLog={setModalDetailedLog}
          userIdDialog={user?.UserID}
          calender={true}
        />
      )}
    </Paper>
  );
}
function ViewUser() {
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();
  const handleViewUser = () => {
    setIsLoading(true);
    GetUserView(id)
      .then((res) => {
        setUser(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleViewUser();
  }, []);
  return (
    <React.Fragment>
      <Helmet title="View User" />
      <WithPermissionFallback controller="Users" action="getUserView">
        <Typography variant="h3" gutterBottom display="inline">
          View User
        </Typography>

        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/tables/Users">
            User
          </Link>
          <Typography>View User</Typography>
        </Breadcrumbs>

        <Divider my={6} />

        <Card sx={{ position: "relative" }}>
          {isLoading && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.4)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 9999,
              }}
            >
              <CircularProgress size={50} />
            </Box>
          )}
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12} lg={2} xl={2}>
                <UserViewCard profile={user} />
              </Grid>
              <Grid item xs={12} lg={10} xl={10}>
                <UserInfo user={user} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default ViewUser;

import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Grid,
  Card,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { ArrowForwardIos as ArrowForwardIosIcon } from "@mui/icons-material";
import LiveCastPicsModal from "./inner-components/LiveCastPicsModal";
import { styled } from "@mui/system";
import InfiniteScroll from "react-infinite-scroll-component";
import { GetCastPics } from "../../api";

const LiveCastPics = () => {
  const [castPics, setCastPics] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [castPicsModal, setCastPicsModal] = useState(false);
  const [selectedPic, setSelectedPic] = useState(null);

  const castPicsModalOpen = () => {
    setCastPicsModal(true);
  };
  const castPicsModalClose = () => {
    setCastPicsModal(false);
    setSelectedPic(null);
  };

  const getPics = async (page) => {
    setLoading(true);
    try {
      const payload = { page: page, limit: 20 };
      const res = await GetCastPics(payload);
      console.log("Response: ", res);
      if (res.statusCode === 200) {
        setHasMore(
          res.data.total_count > castPics.length + res.data.cast_pics.length
        );
        setCastPics((prevPics) => [...prevPics, ...res.data.cast_pics]);
      }
    } catch (error) {
      console.log("Error while getting cast pics: ", await error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPics(page);
  }, [page]);

  const HoverCard = styled(Card)(({ theme }) => ({
    position: "relative",
    padding: "5px",
    width: "100%",
    height: "200px", // Fixed height for the image container
    borderRadius: "8px",
    overflow: "hidden", // Ensure the image doesn't overflow the card
    boxShadow:
      theme.palette.mode === "light"
        ? "0px 4px 10px rgba(0, 0, 0, 0.1)"
        : "0px 4px 10px rgba(0, 0, 0, 0.3)",
    "&:hover": {
      cursor: "pointer",
      boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)", // Darker shadow on hover
      transform: "scale(1.05)", // Slight scaling effect on hover
      transition: "all 0.3s ease",
    },
  }));

  console.log("page no:", page, "hasMore: ", hasMore);

  return (
    <>
      <Box>
        <Typography variant="h3" gutterBottom>
          Cast Pics
        </Typography>
        <Box sx={{ display: "flex" }}>
          <Typography variant="h6" gutterBottom mt={2}>
            Dispatch
          </Typography>
          <IconButton>
            <ArrowForwardIosIcon fontSize="small" />
          </IconButton>
          <Typography variant="h6" gutterBottom mt={2}>
            Cast pics
          </Typography>
        </Box>

        {/* Infinite Scroll Container */}
        <Box sx={{ display: "flex", marginTop: "10px" }}>
          <div
            id="scrollableDiv" // Make sure it's scrollable
            style={{
              height: "72vh",
              overflowY: "auto", // Enable vertical scroll
              width: "100%", // Ensure it takes the full width
            }}
          >
            <InfiniteScroll
              dataLength={castPics.length} // Current length of loaded data
              next={() => setPage(page + 1)} // Increment page number for next request
              hasMore={hasMore} // Flag to check if more images are available
              loader={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px",
                    width: "100%", // Center loader inside the scroll
                  }}
                >
                  <CircularProgress color="primary" />
                </Box>
              }
              endMessage={
                <Typography
                  sx={{
                    textAlign: "center",
                    padding: "10px",
                    color: "gray",
                    fontSize: "14px",
                  }}
                >
                  No more images to load
                </Typography>
              }
              scrollableTarget="scrollableDiv"
              style={{ height: "80vh" }}
            >
              <Grid container spacing={3} sx={{ width: "100%" }}>
                {castPics.map((pic, index) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <HoverCard
                      onClick={() => {
                        castPicsModalOpen();
                        setSelectedPic(pic);
                      }}
                    >
                      <img
                        src={
                          pic.CastFile ||
                          "../../../public/static/img/brands/business.jpg"
                        } // Image source
                        alt={`Cast pic ${index + 1}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "8px",
                        }}
                        onError={(event) =>
                          (event.target.src =
                            "/static/img/lost-found/lost_and_found_pic.jpg")
                        }
                      />
                    </HoverCard>
                  </Grid>
                ))}
              </Grid>
            </InfiniteScroll>
          </div>
        </Box>

        {/* Modal Component */}
        {castPicsModal && (
          <LiveCastPicsModal
            castPicsModal={castPicsModal}
            setCastPicsModal={setCastPicsModal}
            castPicsModalOpen={castPicsModalOpen}
            castPicsModalClose={castPicsModalClose}
            selectedPic={selectedPic}
          />
        )}
      </Box>
    </>
  );
};

export default LiveCastPics;

import {
  Grid,
  Box,
  Button,
  Tooltip,
  Typography,
  MenuItem,
  Menu,
  TextField,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Reply as ReplyIcon } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useState, useEffect } from "react";
import Map from "./inner-components/Map";
import { BacktrackHeatMapAjax } from "../../api";
import styled from "styled-components/macro";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    maxWidth: "140px",
    width: "100%",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      "& .MuiMenuItem-root": {
        display: "block",
        "& .MuiList-root": {
          paddingTop: "0px",
          marginTop: "15px",
          paddingBottom: "0px",
          "& .MuiListItem-root": {
            padding: "0px",
            background: "#7aa8ff0d",
            "& .MuiListItemButton-root": {
              padding: "3px 5px",
            },
          },
        },
      },

      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));

const BacktrackHeatMap = () => {
  const navigate = useNavigate();
  const [siteLocation, setSiteLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mapCenter, setMapCenter] = useState({
    lat: 37.784279,
    lng: -122.407234,
  });
  const [filterOptions, setFilterOptions] = useState({
    start_date: moment().subtract(28, "days").format("MM/DD/YYYY"),
    end_date: moment().format("MM/DD/YYYY"),
  });
  const [backtrackData, setBacktrackData] = useState({
    map_data: [],
    map_dir: "0",
  });
  const [isHeatMapVisible, setIsHeatMapVisible] = useState(true);

  // Handle changes in filter options
  const handleFilterChange = (key, value) => {
    setFilterOptions((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSearch = () => {
    getBacktrackData();
  };

  const getBacktrackData = async () => {
    setLoading(true);
    try {
      // Construct the "date-range" string from filterOptions
      const dateRangeString = `${filterOptions.start_date} - ${filterOptions.end_date}`;

      // Build the payload in the required format
      const payload = {
        "date-range": dateRangeString,
      };

      // Pass that payload to your AJAX call
      const res = await BacktrackHeatMapAjax(payload);
      if (res.statusCode == 200) {
        setBacktrackData(res.data);
      } else {
        setBacktrackData({
          map_data: [],
          map_dir: 0,
        });
      }
    } catch (error) {
      console.log("Error while fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBacktrackData();
  }, []);

  return (
    <>
      <Box sx={{ height: "85vh", position: "relative" }}>
        <Grid container sx={{ height: "100%" }}>
          {/* Overlay Loader */}
          {loading && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.4)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 9999,
              }}
            >
              <CircularProgress size={50} />
            </Box>
          )}
          <Grid item xs={12} sx={{ height: "100%" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              {/* Header */}
              <Box
                sx={{
                  backgroundColor: "white",
                  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                  padding: 2,
                  borderRadius: 1,
                  flexShrink: 0,
                  // height: "15%", // Fixed height for header
                }}
              >
                <Typography variant="h3" gutterBottom>
                  HeatMap
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  {/* Start Date Picker */}
                  <Grid item xs={12} sm={3} md={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Start Date"
                        value={moment(filterOptions.start_date, "MM/DD/YYYY")}
                        onChange={(newValue) =>
                          handleFilterChange(
                            "start_date",
                            newValue.format("MM/DD/YYYY")
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            sx={{ minWidth: "150px" }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  {/* End Date Picker */}
                  <Grid item xs={12} sm={3} md={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="End Date"
                        value={moment(filterOptions.end_date, "MM/DD/YYYY")}
                        onChange={(newValue) =>
                          handleFilterChange(
                            "end_date",
                            newValue.format("MM/DD/YYYY")
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            sx={{ minWidth: "150px" }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  {/* Search Button */}
                  <Grid item xs={12} sm={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSearch}
                    >
                      Search Dates
                    </Button>
                  </Grid>

                  {/* Action Buttons */}
                  <Grid item xs={12} sm={3} md={4}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: 3,
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{ borderRadius: "8px" }}
                        onClick={() => navigate("../backtrack")}
                      >
                        <ReplyIcon
                          sx={{ fontSize: "20px", marginRight: "4px" }}
                        />
                        Backtrack
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{ borderRadius: "8px" }}
                        onClick={() => {
                          setIsHeatMapVisible(!isHeatMapVisible);
                        }}
                      >
                        {isHeatMapVisible ? "Hide Heatmap" : "Show Heatmap"}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              {/* Map */}
              <Box
                sx={{
                  flexGrow: 1,
                  borderRadius: 1,
                  overflow: "hidden",
                  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#fff",
                  position: "relative",
                }}
              >
                <Map
                  locations={
                    backtrackData?.map_data ? backtrackData?.map_data : []
                  }
                  isHeatMap={isHeatMapVisible}
                  mapCenter={mapCenter}
                  zoomLevel={2}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default BacktrackHeatMap;

import React from "react";
import { makeStyles } from "@mui/styles";
import BuildIcon from "@mui/icons-material/Build";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import CakeIcon from "@mui/icons-material/Cake";
import ShieldIcon from "@mui/icons-material/Shield";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Tooltip } from "@mui/material";

const Types = ({ data }) => {
  // Classes Styling
  const useStyles = makeStyles((theme) => ({
    statusButton: {
      width: "20px",
      height: "20px",
      display: "block",
      lineHeight: "20px",
      textAlign: "center",
      borderRadius: "6px",
      color: "#fff",
      fontSize: "12px",
      fontWeight: "bolder",
    },
  }));

  const classes = useStyles();

  const TypeStatus = (value) => {
    switch (value) {
      case 2:
        return (
          <React.Fragment>
            <Tooltip title="Contractor">
              <BuildIcon color="warning" />
            </Tooltip>
          </React.Fragment>
        );
        break;
      case 3:
        return (
          <React.Fragment>
            <Tooltip title="Candidate for employment">
              <BuildIcon />
            </Tooltip>
          </React.Fragment>
        );
        break;
      case 4:
        return (
          <React.Fragment>
            <Tooltip title="Corporate Visitor">
              <ApartmentIcon color="success" />
            </Tooltip>
          </React.Fragment>
        );
        break;
      case 5:
        return (
          <React.Fragment>
            <Tooltip title="Employee from another Site">
              <PeopleIcon />
            </Tooltip>
          </React.Fragment>
        );
        break;
      case 7:
        return (
          <React.Fragment>
            <Tooltip title="Employee from another Site">
              <PersonIcon color="primary" />
            </Tooltip>
          </React.Fragment>
        );
        break;
      case 8:
        return (
          <React.Fragment>
            <Tooltip title="Fire">
              <WhatshotIcon color="warning" />
            </Tooltip>
          </React.Fragment>
        );
        break;
      case 9:
        return (
          <React.Fragment>
            <Tooltip title="Medical">
              <MedicalServicesIcon color="error" />
            </Tooltip>
          </React.Fragment>
        );
        break;
      case 10:
        return (
          <React.Fragment>
            <Tooltip title="Event Setup">
              <CakeIcon />
            </Tooltip>
          </React.Fragment>
        );
        break;
      case 12:
        return (
          <React.Fragment>
            <Tooltip title="Employee">
              <PersonIcon color="" />
            </Tooltip>
          </React.Fragment>
        );
        break;
      case 13:
        return (
          <React.Fragment>
            <Tooltip title="Third Party Security">
              <ShieldIcon color="primary" />
            </Tooltip>
          </React.Fragment>
        );
        break;
      case 14:
        return (
          <React.Fragment>
            <Tooltip title="Vendor">
              <TouchAppIcon color="info" />
            </Tooltip>
          </React.Fragment>
        );
        break;
      case 15:
        return (
          <React.Fragment>
            <Tooltip title="Union Rep">
              <NotificationsIcon />
            </Tooltip>
          </React.Fragment>
        );
        break;

      default:
        return <React.Fragment>Other - {value}</React.Fragment>;
        break;
    }
  };

  return TypeStatus(data);
};

export default Types;

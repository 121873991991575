import React, { useState } from "react";
import styled from "styled-components/macro";
import moment from "moment";
import {
  Dialog,
  InputLabel as MuiInputLabel,
  DialogContent,
  Typography,
  DialogTitle,
  Button,
  DialogActions,
  IconButton,
  FormControl as MuiFormControl,
  FormLabel,
  TextField,
  MenuItem,
  Select,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Cancel as CancelIcon } from "@mui/icons-material";
import CustomDateRange from "../../components/form-components/CustomDateRange";
const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const PoiDialog = (props) => {
  const {
    poiModal,
    setPoiModal,
    poiModalClose,
    poiModalOpen,
    startDateToFilter,
    setStartDateToFilter,
    endDateToFilter,
    setEndDateToFilter,
    propertyToFilter,
    setPropertyToFilter,
    alias,
    setAlias,
    threat,
    setThreat,
    applySearch,
  } = props;
  const [fadebox, setFadedBox] = useState(false);
  console.log("sdjudfudf", propertyToFilter);
  return (
    <>
      {" "}
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "300px",
            },
          },
        }}
        open={poiModal}
        onClose={poiModalClose}
        aria-labelledby="viewmap-title"
        aria-describedby="viewmap-description"
      >
        <DialogTitle
          id="viewmap-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
            borderBottom: "1px solid #dadce0",
          }}
        >
          <Typography variant="h6" gutterBottom>
            POI Encounter Filter{" "}
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setPoiModal(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <FormControl
            sx={{
              marginTop: "20px",
            }}
          >
            {/* <TextField
              name="corporateName"
              label="Date Range *"
              placeholder="Click to select"
              InputLabelProps={{
                shrink: true,
              }}
            /> */}
            <TextField
              fullWidth
              id="dateRange"
              label="Date range *"
              value={
                startDateToFilter && endDateToFilter
                  ? moment(startDateToFilter).format("MM/DD/YYYY") +
                    " - " +
                    moment(endDateToFilter).format("MM/DD/YYYY")
                  : ""
              }
              onClick={() => setFadedBox((prev) => !prev)}
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Select date range"
            />
            {fadebox && (
              <CustomDateRange
                handleShow={fadebox}
                conformation={(status) => setFadedBox((prev) => !prev)}
                setDates={(start, end) => {
                  setStartDateToFilter(start);
                  setEndDateToFilter(end);
                }}
              />
            )}
          </FormControl>
          <FormControl
            sx={{
              marginTop: "15px",
            }}
          >
            <TextField
              name="corporateName"
              label="Property *"
              placeholder="Business name"
              InputLabelProps={{
                shrink: true,
              }}
              value={propertyToFilter}
              onChange={(e) => setPropertyToFilter(e.target.value)}
            />
          </FormControl>
          <FormControl
            sx={{
              marginTop: "15px",
            }}
          >
            <TextField
              name="corporateName"
              label="Alias *"
              placeholder="Nickname for POI"
              InputLabelProps={{
                shrink: true,
              }}
              value={alias}
              onChange={(e) => setAlias(e.target.value)}
            />
          </FormControl>
          <FormControl
            sx={{
              marginTop: "15px",
            }}
          >
            <InputLabel shrink id="department">
              Threat level
            </InputLabel>
            <Select
              id="threat"
              value={threat}
              label="Threat Level *"
              onChange={(e) => setThreat(e.target.value)}
              fullWidth
              displayEmpty
            >
              <MenuItem disabled value="">
                Select minimum threat level
              </MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={7}>7</MenuItem>
              <MenuItem value={8}>8</MenuItem>
              <MenuItem value={9}>9</MenuItem>
              <MenuItem value={10}>10</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "20px 24px",
          }}
        >
          <Button variant="outlined" color="primary" onClick={poiModalClose}>
            Close
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              applySearch();
              poiModalClose();
            }}
          >
            Filter
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PoiDialog;

import React, { useState } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { VisitorStatusUpdate } from "../../../../api";
import hasPermission from "../../../../utils/hasPermission";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../../redux/slices/mainSlice";
import { useDispatch } from "react-redux";

const ListToggle = (props) => {
  const dispatch = useDispatch();
  const { value, id } = props;
  const [active, setActive] = useState(value === "A" ? true : false);
  const handleActive = (status, id) => {
    let payload = {
      active: status === true ? "I" : "A",
    };
    VisitorStatusUpdate(id, payload)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(getMsg(res.message || `Record Updated Successfully`));

          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg(res.message || "Something went wrong!"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(getMsg(err.data.message || "Something went wrong!"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={active}
            onClick={() => {
              setActive(!active);
              handleActive(active, id);
            }}
            disabled={!hasPermission("Visitor", "activeInactiveVisitor")}
          />
        }
        label=""
      />
    </>
  );
};

export default ListToggle;
